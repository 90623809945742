import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

// import { Parallax } from "react-scroll-parallax";
// import Image from "../../components/Image";

import { Parallax } from "react-parallax";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

import ResvRodal from "../../components/resvRodal";

const Tbl = styled.table`
  td {
    text-align: center;
    background: #fff;
    &:first-of-type {
      background: #f7fbfd;
    }
  }
`;

function Neck3({ history, props }) {
  const dispatch = useDispatch();
  // 마우스 위치 기반 자동 가로 스크롤
  const horizontalScrollRef = useRef(null);
  const mX = useRef(0);
  const mX2 = useRef(0);
  const posX = useRef(0);
  const mPadd = 60;
  const damp = 20;
  // 요기까지
  const [visibleResv, setVisibleResv] = useState(false);
  const [defaultTreatId, setDefaultTreatId] = useState(0);
  const [defaultTreatNm, setDefaultTreatNm] = useState(0);

  const handleResvDefaultValues = (id, tnm) => {
    if (id > 0 && tnm) {
      setDefaultTreatId(id);
      setDefaultTreatNm(tnm);
      setVisibleResv(true);
      console.log(id);
      console.log(tnm);
    }
  };

  // 예약완료
  const resvComplate = () => {
    setVisibleResv(false);
  };

  const resvClose = () => {
    setVisibleResv(!resvClose);
  };

  // scroll 버튼 처리
  const scrollDown = () => {
    const n = document.documentElement.clientHeight;
    window.scrollBy({ top: n, left: 0, behavior: "smooth" });
  };

  // 자동 스크롤 리스너
  const horizontalScroll = (e) => {
    if (horizontalScrollRef.current) {
      const container = horizontalScrollRef.current;
      const parent = container.parentElement;
      const galW = container.offsetWidth;
      const galSW = container.scrollWidth;
      const wDiff = galSW / galW - 1; // widths difference ratio
      const mmAA = galW - mPadd * 2; // the mousemove available area
      const mmAAr = galW / mmAA;

      if (parent) {
        mX.current =
          e.pageX - parent.getBoundingClientRect().left - container.offsetLeft;

        mX2.current = Math.min(Math.max(0, mX.current - mPadd), mmAA) * mmAAr;
      }

      posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"

      setInterval(function () {
        posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"
        container.scrollLeft = posX.current * wDiff;
      }, 40);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>편도결석 클리닉</title>
      </Helmet>
      {visibleResv ? (
        <ResvRodal
          visibleResv={visibleResv}
          resvClose={resvClose}
          resvComplate={resvComplate}
          defaultTreatId={defaultTreatId}
          defaultTreatNm={defaultTreatNm}
        />
      ) : (
        ""
      )}
      <div className="topBar"></div>
      <div className="sv">
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={"/img/neck/neck3.png"}
          bgImageAlt=""
          strength={-300}
        >
          <div className="container">
            <Slide up>
              <div className="parallaxExplain btm">
                <div className="top">
                  <h1 className="pgTitle">편도결석 클리닉</h1>
                  <p className="pgTitleExp22">
                    구취 유발, 목이물감을 유발하는 편도결석
                  </p>
                  <p className="pgTitleExp22 mt-30">
                    <img src="/img/chk.svg" className="mr-20" alt="" />
                    <strong>
                      당일 입원. 수술 후 당일퇴원{" "}
                      <span className="color-primary">1Day Solution</span>
                    </strong>
                  </p>
                  <p className="pgTitleExp22 mt-10">
                    <img src="/img/chk.svg" className="mr-20" alt="" />
                    <strong>당일 수술 30분 소요</strong>
                  </p>
                  <p className="pgTitleExp22 mt-10">
                    <img src="/img/chk.svg" className="mr-20" alt="" />
                    <strong>빠른 일상생활 복귀</strong>
                  </p>
                </div>
                <div className="mid">
                  <Row noGutters>
                    <Col>
                      <button
                        type="button"
                        className="btnNoStyle"
                        onClick={(e) =>
                          handleResvDefaultValues(13, "편도 결석 클리닉")
                        }
                      >
                        <img src="/img/ico-chart.svg" alt="" />
                        <span>편도 결석 클리닉 예약하기</span>
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Slide>
          </div>
          <div style={{ height: "100vh" }} />
          <button
            type="button"
            className="btnScrollDown"
            onClick={() => scrollDown()}
          >
            <img src="/img/scrollDown.svg" alt="" />
          </button>
        </Parallax>
        <div className="container mt-130 mb-60">
          <Slide up>
            <div className="row">
              <div className="col-md-3">
                <Slide left>
                  <h2 className="color-primary font-30 mb-60 xs-to-center">
                    편도 결석이란:
                  </h2>
                </Slide>
              </div>
              <div className="col-md-9">
                <Slide right>
                  <p className="font-24 mb-50">
                    <strong>
                      편도선의 작의 구명에 편도의 상피세포 탈락물질과 음식물
                      찌꺼기,
                      <br />
                      세균이 뭉쳐서 생기는 쌀알 크기의 작고 노란 알갱이
                    </strong>
                  </p>
                  <div className="gridContainer grid3 w200">
                    <div className="gridCol">
                      <img
                        src="/img/neck/neck3-1.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="gridCol">
                      <img
                        src="/img/neck/neck3-2.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="gridCol">
                      <img
                        src="/img/neck/neck3-3.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <p className="font-24 mt-50">
                    <img src="/img/chk.svg" className="mr-20" alt="" />
                    입냄새가 나는데 치과에서 아무런 문제가 없다고 할 때
                  </p>
                  <p className="font-24">
                    <img src="/img/chk.svg" className="mr-20" alt="" />
                    양치시 구역질 나면서 가끔 냄새나는 작은 알갱이가 튀어나올 때
                  </p>
                  <p className="font-24">
                    <img src="/img/chk.svg" className="mr-20" alt="" />
                    목의 이상한 이물감이 지속 시 이 노란색 알갱이의 이름은
                    <br />
                    편도 결석이지만 결석이라는 이름처럼 딱딱하지는 않습니다.
                  </p>
                </Slide>
              </div>
            </div>
          </Slide>
        </div>
        <div className="blue-row">
          <div className="container">
            <Row noGutters className="mb-50">
              <div className="col-md-4">
                <Slide left>
                  <h1 className="bar-text font-24 mb-30">편도결석의 원인</h1>
                </Slide>
              </div>
              <div className="col-md-8">
                <Slide right>
                  <p className="font-24">
                    편도선염이 가장 흔한 원인이며 이로 인해 편도의 작은 구멍들이
                    확장되고 이 구멍안에 편도 상피 세포탈락 물질과 음식물
                    찌꺼기, 세균이 번식하여 생성됩니다. 구강위생이 불량한
                    경우·비염, 부비동염으로 후비루가 생기는 경우 편도에 세균이
                    잘 증식하여 발생됩니다.
                  </p>
                </Slide>
              </div>
            </Row>
            <Row noGutters className="mt-80">
              <div className="col-md-4">
                <Slide left>
                  <h1 className="bar-text font-24 mb-30">편도결석의 증상</h1>
                </Slide>
              </div>
              <div className="col-md-8">
                <Slide right>
                  <p className="font-24">
                    특이한 증상이 나타나지 않을 수 도 있지만 양치질을 하다 노란
                    알갱이가 나올수 있고 목의 이물감이 지속될 수 있습니다. 또한
                    치과적 문제가 없음에도 지속적 구취가 발생하게됩니다.
                  </p>
                </Slide>
              </div>
            </Row>
            <Row noGutters className="mt-80">
              <div className="col-md-4">
                <Slide left>
                  <h1 className="bar-text font-24 mb-30">편도결석의 진단</h1>
                </Slide>
              </div>
              <div className="col-md-8">
                <Slide right>
                  <p className="font-24">
                    구강 및 편도를 후두내시경으로 검사할 수 있으며, 통증 없이
                    간단한 구강 검진 만으로도 진단이 가능합니다.
                  </p>
                </Slide>
              </div>
            </Row>
            <Row noGutters className="mt-80">
              <div className="col-md-4">
                <Slide left>
                  <h1 className="bar-text font-24 mb-30">편도결석의 치료</h1>
                </Slide>
              </div>
              <div className="col-md-8">
                <Slide right>
                  <p className="font-24">
                    <strong>
                      편도결석은 반드시 치료해야 하는 중증질환은 아니지만 구취로
                      인한 주변 사람에게 불쾌감, 목이물감으로 인한 삶의 질
                      저하를 해결하기 위해서는 치료가 필요합니다.
                    </strong>
                  </p>
                  <ul className="list-unstyled font-24">
                    <li>
                      - 편도결석이 자연스럽게 나오게 되더라고 이미 생긴 구멍에서
                      다시 생기는 경우가 많아 치료 하지 않으면 대개 재발하게
                      됩니다.
                    </li>
                    <li>
                      - 간단한 국소마취 후 결석을 부담감없이 제거 가능합니다.
                    </li>
                    <li>- 당일치료 후 바로 일상생활 복귀가 가능합니다.</li>
                  </ul>
                  <Tbl className="tbl mt-50">
                    <colgroup>
                      <col style={{ width: "190px" }} />
                      <col />
                      <col />
                    </colgroup>
                    <thead>
                      <tr>
                        <th colSpan="3">편도전절제술 VS 편도부분절제술</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>비고</td>
                        <td>편도전절제술</td>
                        <td>편도부분절제술</td>
                      </tr>
                      <tr>
                        <td>마취</td>
                        <td>전신마취</td>
                        <td>부분국소마취</td>
                      </tr>
                      <tr>
                        <td>수술 시간</td>
                        <td>1시간</td>
                        <td>15~30분</td>
                      </tr>
                      <tr>
                        <td>입원</td>
                        <td>3일 이상</td>
                        <td>당일 퇴원</td>
                      </tr>
                      <tr>
                        <td>장단점</td>
                        <td>완치율이 폰으나 전신마취, 편도전체를 절제</td>
                        <td>기존의 장단점을 보완하여 완치율이 지속적 상승</td>
                      </tr>
                      <tr>
                        <td>완치율</td>
                        <td>97%</td>
                        <td>80%</td>
                      </tr>
                    </tbody>
                  </Tbl>
                </Slide>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default Neck3;
