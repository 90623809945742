import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

import ReactCardFlip from "react-card-flip";

const Centered = styled.div`
  padding-top: var(--size-200px);
`;

const Bg = styled.div`
  .col-md-4:before,
  .col-md-8:before {
    content: "";
    width: 0;
  }

  .col-md-4:before,
  .col-md-8:before,
  .ib {
    white-space: normal;
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
  }

  .col-md-4:before {
    padding-top: 100%;
    /* makes expand to a square */
  }

  .col-md-8:before {
    padding-top: 50%;
    /* makes a rectangle half the height of a square */
  }

  [class^="col"] {
    white-space: nowrap;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 5px white;
    padding: 0;
    text-align: center;
  }

  .row {
    color: white;
    text-shadow: 0 0 black, 0 0 2px black;
  }

  .col-md-4 {
  }
`;

const data = [
  {
    id: 1,
    title: "3D CT",
    desc: `<ul class="list-unstyled body-list">
      <li>방사선량이 일반 CT의 10% 이하로 안전합니다.</li>
      <li>영상을 검사 즉시 진료실에서 확인할 수 있습니다.</li>
      <li>기존의 CT에 비해 가격이 저렴합니다.</li>
      <li>코수술에 필요한 정보를 정확하게 제공합니다.</li>
    </ul>`,
    img: "/img/about/c-img-1.jpg",
  },
  {
    id: 2,
    title: "고화질 내시경수술시스템",
    desc: `<ul class="list-unstyled body-list">
      <li>고해상도 화면을 제공하여 미세기구를 이용하여 정밀한 수술이 가능합니다.</li>
      <li>정밀한 수술을 통해 통증과 출혈을 줄일 수 있습니다.</li>
      <li>수술시마다 소독한 내시경기구를 사용하여 감염없이 안전합니다.</li>
    </ul>`,
    img: "/img/about/c-img-2.jpg",
  },
  {
    id: 3,
    title: "미세절삭기",
    desc: `<ul class="list-unstyled body-list">
      <li>Sutter – 고주파를 이용한 수술 기구로 기존의 레이저 보다 저온으로 주변 조직 손상 없이 치료 가능</li>
    </ul>`,
    img: "/img/about/c-img-3.jpg",
  },
  {
    id: 4,
    title: "코블레이터",
    desc: `<ul class="list-unstyled body-list">
      <li>비후성 비염, 알레르기성 비염 등의 수술에 사용되는 장비로, 비후된 조직을 축소시키고 출혈을 줄일 수 있습니다.</li>
    </ul>`,
    img: "/img/about/c-img-5.jpg",
  },
];

const data2 = [
  {
    id: 1,
    title: "경부 초음파",
    desc: `<ul class="list-unstyled body-list">
      <li>방사선 노출 없이 간단하게 진단에 필요한 고해상도 영상을 얻을 수 있는 장점이 있습니다.</li>
      <li>조직 검사 또는 고주파 절제술시 사용 됩니다.</li>
    </ul>`,
    img: "/img/about/n-img-1.jpg",
  },
  {
    id: 2,
    title: "후두미세진동검사",
    desc: `<ul class="list-unstyled body-list">
      <li>음성을 만들어내는 가장 중요한 요소 중의 하나인 성대의 모습을 가장 정확히 관찰할 수 있는 검사법입니다.</li>
      <li>성대진동의 대칭성, 규칙성, 성문폐쇄의 형태 등을 정확히 관찰하여, 다양한 음성장애 및 성대의 미세질환 등을 정확히 진단 및 치료 할 수 있도록 지원합니다. </li>
    </ul>`,
    img: "/img/about/n-img-2.jpg",
  },
];

const data3 = [
  {
    id: 1,
    title: "오디오부스",
    desc: `<ul class="list-unstyled body-list">
      <li>순음청력검사</li>
      <li>뇌간유발반응검사</li>
      <li>유희 청력검사 외 각종검사</li>
      <li>언어훈련</li>
    </ul>`,
    img: "/img/about/e-img-1.jpg",
  },
  {
    id: 2,
    title: "순음청력검사장비",
    desc: `<ul class="list-unstyled body-list">
      <li>가장 기본적인 청력측정 장비로 순음검사 및 언어 청력검사기에 이용됩니다.</li>
    </ul>`,
    img: "/img/about/e-img-2.jpg",
  },
  {
    id: 3,
    title: "임피던스 오디오메트리",
    desc: `<ul class="list-unstyled body-list">
      <li>기본 청력검사 고막운동성 고실측정가능합니다.</li>
    </ul>`,
    img: "/img/about/e-img-3.jpg",
  },
  {
    id: 4,
    title: "이음향방사검사DPOAE",
    desc: `<ul class="list-unstyled body-list">
      <li>신생아를 대상으로 청력에 대한 이상유무를 선별가능합니다.</li>
    </ul>`,
    img: "/img/about/e-img-4.jpg",
  },
  {
    id: 5,
    title: "비디오안진검사기",
    desc: `<ul class="list-unstyled body-list">
      <li>어지러움증 환자에게 다양한 자극을 주고 자극이 유발하는 안구 운동을 관찰, 기록하여 어리러움증의 유형과 정도, 원인이 되는 병변을 진단하기 위한 검사장비입니다.</li>
    </ul>`,
    img: "/img/about/e-img-5.jpg",
  },
  {
    id: 6,
    title: "온도안전검사기",
    desc: `<ul class="list-unstyled body-list">
      <li>양 귀에 냉수와 온수를 교차 주입하여 어지러움을 유발시키는 검사장비로 양쪽 귀의 전정 기능의 차이를 비교 검사할 수 있는 장비입니다.</li>
    </ul>`,
    img: "/img/about/e-img-6.jpg",
  },
  {
    id: 7,
    title: "뇌간유발반응검사장비 (ABR검사)",
    desc: `<ul class="list-unstyled body-list">
      <li>ABR은 소리에 대한 뇌의 반응을 보이는 검사입니다.</li>
      <li>기계를 통해 소리 자극을 들여 줬을 때 피검사자의 반응이 어떻게 작용하는지 확인하는 검사이며, 청력장애의 유무를 판별하는 청력검사가 바로 ABR검사압니다.</li>
    </ul>`,
    img: "/img/about/e-img-7.jpg",
  },
];

const Card = ({ project }) => {
  const [isFlipped, setIsFlipped] = React.useState(false);
  return (
    <div className="col-md-4 mb-20" key={project.id}>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <div
          onMouseEnter={() => setIsFlipped((prev) => !prev)}
          className="CardFront"
          style={{
            backgroundImage: `url(${project.img})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h1 className="font-30">{project.title}</h1>
        </div>
        <div
          onMouseLeave={() => setIsFlipped((prev) => !prev)}
          className="CardBack"
        >
          <div
            className="desc"
            dangerouslySetInnerHTML={{ __html: project.desc }}
          ></div>
        </div>
      </ReactCardFlip>
    </div>
  );
};

function About4({ history, props }) {
  const dispatch = useDispatch();

  const [tabNum, setTabNum] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>첨단장비</title>
      </Helmet>
      <div className="topBar"></div>
      <div className="sv">
        <Centered className=" text-center">
          <h1 className="font-45 color-primary mb-60">첨단장비</h1>
        </Centered>
        <p className="text-center font-24">
          정확한 진단, 안전한 수술, 최소한의 통증
          <br />
          하나이비인후과병원의 최첨단 의료장비
        </p>
        <div className="container">
          <div className="tabHeader mt-100 mb-100">
            <Slide right>
              <ul className="d-flex justify-content-between align-items-center">
                <li>
                  <button
                    type="button"
                    className={tabNum === 0 ? "active" : ""}
                    onClick={() => setTabNum(0)}
                  >
                    코질환 클리닉
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={tabNum === 1 ? "active" : ""}
                    onClick={() => setTabNum(1)}
                  >
                    목질환 클리닉
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={tabNum === 2 ? "active" : ""}
                    onClick={() => setTabNum(2)}
                  >
                    귀질환 클리닉
                  </button>
                </li>
              </ul>
            </Slide>
          </div>
        </div>
        <div className="container mb-100">
          <div className="tabContent mt-100">
            <div className={tabNum === 0 ? "tab active" : "tab"}>
              <Slide bottom>
                <div className="row">
                  {data.map((item, index) => (
                    <Card project={item} key={`card-${index}`} />
                  ))}
                </div>
              </Slide>
            </div>
            <div className={tabNum === 1 ? "tab active" : "tab"}>
              <Slide bottom>
                <div className="row">
                  {data2.map((item, index) => (
                    <Card project={item} key={`card-${index}`} />
                  ))}
                </div>
              </Slide>
            </div>
            <div className={tabNum === 2 ? "tab active" : "tab"}>
              <Slide bottom>
                <div className="row">
                  {data3.map((item, index) => (
                    <Card project={item} key={`card-${index}`} />
                  ))}
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About4;
