import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

const Centered = styled.div`
  padding-top: var(--size-200px);
  padding-bottom: var(--size-100px);
  .d-flex {
  }
  .flexItem {
    padding: var(--size-10px) var(--size-20px);
    width: 50%;
  }
`;

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 332px;
  margin-bottom: 50px;
`;

function About7({ history, props }) {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
    const script = document.createElement("script");
    script.async = true;
    script.src =
      "//dapi.kakao.com/v2/maps/sdk.js?appkey=bf545654f283c69b3aa5eabd9591c406&libraries=services&autoload=false";
    document.head.appendChild(script);

    script.onload = () => {
      kakao.maps.load(() => {
        let container = document.getElementById("map");
        let options = {
          center: new kakao.maps.LatLng(37.506502, 127.053617),
          level: 3,
        };

        const map = new window.kakao.maps.Map(container, options);

        const geocoder = new kakao.maps.services.Geocoder();

        // 주소로 좌표를 검색합니다
        geocoder.addressSearch(
          "서울특별시 서대문구 수색로 56",
          function (result, status) {
            // 정상적으로 검색이 완료됐으면
            if (status === kakao.maps.services.Status.OK) {
              const coords = new kakao.maps.LatLng(result[0].y, result[0].x);

              // 결과값으로 받은 위치를 마커로 표시합니다
              const marker = new kakao.maps.Marker({
                map: map,
                position: coords,
              });

              // 인포윈도우로 장소에 대한 설명을 표시합니다
              const infowindow = new kakao.maps.InfoWindow({
                content: `<div style="width:220px;text-align:center;padding:6px 0;font-size: 20px;">DMC하나이비인후과</div>`,
              });
              infowindow.open(map, marker);

              // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
              map.setCenter(coords);
            }
          }
        );
      });
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>오시는 길</title>
      </Helmet>
      <div className="topBar"></div>
      <div className="sv">
        <Centered className="text-center">
          <h1 className="font-45 color-primary mb-60">오시는 길</h1>
        </Centered>
        <div className="wrapper">
          <div className="row">
            <div className="col-sm-6">
              <h1 className="font-28 mb-50">
                <strong>DMC 하나이비인후과</strong>
                <br />
                <br />
                <span className="font-normal">
                  서울특별시 서대문구 수색로 56, 성공타워1, 212호
                  <br />
                  <br />
                  가좌역 3번 출구 100m
                  <br />
                  <br />
                  네비게이션에 ‘DMC하나이비인후과’를 치고 오세요.
                  <br />
                  <br />
                  전화: 02-373-0075
                </span>
              </h1>
            </div>
            <div className="col-sm-6">
              <MapContainer id="map" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About7;
