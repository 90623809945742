import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

// import { Parallax } from "react-scroll-parallax";
// import Image from "../../components/Image";

import { Parallax } from "react-parallax";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

import ResvRodal from "../../components/resvRodal";

const Contain = styled.div`
  .gridCol {
    padding: var(--size-20px);
  }
  .itemTitle {
    color: #fff;
    background: var(--color-link);
    display: inline-block;
    padding: var(--size-20px);
    margin-bottom: var(--size-20px);
  }
`;

function Clean({ history, props }) {
  const dispatch = useDispatch();
  // 마우스 위치 기반 자동 가로 스크롤
  const horizontalScrollRef = useRef(null);
  const mX = useRef(0);
  const mX2 = useRef(0);
  const posX = useRef(0);
  const mPadd = 60;
  const damp = 20;
  // 요기까지
  const [visibleResv, setVisibleResv] = useState(false);
  const [defaultTreatId, setDefaultTreatId] = useState(1);
  const [tabNum, setTabNum] = useState(0);

  // 예약완료
  const resvComplate = () => {
    setVisibleResv(false);
  };

  const resvClose = () => {
    setVisibleResv(!resvClose);
  };

  // scroll 버튼 처리
  const scrollDown = () => {
    const n = document.documentElement.clientHeight;
    window.scrollBy({ top: n, left: 0, behavior: "smooth" });
  };

  const setTab = (num) => {
    setTabNum(num);
  };

  // 자동 스크롤 리스너
  const horizontalScroll = (e) => {
    if (horizontalScrollRef.current) {
      const container = horizontalScrollRef.current;
      const parent = container.parentElement;
      const galW = container.offsetWidth;
      const galSW = container.scrollWidth;
      const wDiff = galSW / galW - 1; // widths difference ratio
      const mmAA = galW - mPadd * 2; // the mousemove available area
      const mmAAr = galW / mmAA;

      if (parent) {
        mX.current =
          e.pageX - parent.getBoundingClientRect().left - container.offsetLeft;

        mX2.current = Math.min(Math.max(0, mX.current - mPadd), mmAA) * mmAAr;
      }

      posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"

      setInterval(function () {
        posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"
        container.scrollLeft = posX.current * wDiff;
      }, 40);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>감염 청정 병원</title>
      </Helmet>
      <ResvRodal
        visibleResv={visibleResv}
        resvClose={resvClose}
        resvComplate={resvComplate}
        defaultTreatId={defaultTreatId}
      />
      <div className="topBar"></div>
      <div className="sv">
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={"/img/clean/clean1.png"}
          bgImageAlt=""
          strength={-300}
        >
          <div className="container">
            <Slide up>
              <div className="parallaxExplain btm">
                <div className="top">
                  <h1 className="pgTitle">감염 청정 병원</h1>
                  <p className="pgTitleExp22">
                    <strong>DMC하나이비인후과</strong>에서는{" "}
                    <strong>대학병원 수준의 각종 소독기를 구비</strong>하여
                    환자에게 멸균상태의 의료를 제공합니다.
                  </p>
                  <p className="pgTitleExp22 color-primary mt-30">
                    <strong>보다 안전한 수술과 의료서비스를 제공</strong>하기
                    위해 과감히 소독제품에 투자하는 것은 DMC하나이비인후과에게
                    기본입니다.
                  </p>
                </div>
              </div>
            </Slide>
          </div>
          <div style={{ height: "100vh" }} />
          <button
            type="button"
            className="btnScrollDown"
            onClick={() => scrollDown()}
          >
            <img src="/img/scrollDown.svg" alt="" />
          </button>
        </Parallax>
        <div className="blue-row mb-100 text-center">
          <div className="wrapper">
            <Slide up cascade>
              <Row>
                <div className="col-md-12">
                  <img
                    src="/img/clean/clean1-1.png"
                    className="img-fluid mb-50"
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                    alt=""
                  />
                </div>
                <div className="col-md-12">
                  <h1 className="font-30 font-normal mb-20">
                    하나이비인후과에서는{" "}
                    <strong className="color-primary">
                      발열 및 호흡기 환자를 일반환자와 격리
                    </strong>
                    하여 국제 기준 음압실을 운영하여 진료하고 있습니다.
                  </h1>
                  <h2 className="font-24 font-normal">
                    <strong>매시간 마다 버콘</strong>(코로나 19 인증 살균제-
                    대학병원 및 국내 최대 방역회사 납품용) 및{" "}
                    <strong>
                      각종 살균제를 이용하여 안전한 병원공간을 만들고 있습니다.
                    </strong>
                  </h2>
                </div>
              </Row>
            </Slide>
          </div>
        </div>
        <Contain className="wrapper mb-100">
          <Flip left cascade>
            <div className="gridContainer grid3 text-center">
              <div className="gridCol">
                <img
                  src="/img/clean/clean1-2.png"
                  className="img-fluid mb-30"
                  alt=""
                />
                <p className="font-24 itemTitle">고장증기멸균기</p>
                <p className="font-24 wordKeep">
                  <strong>고압의 포화증기를 이용하여 멸균:</strong>선 진공
                  방식으로 증기가 침투하여 모든 의료 장비를 효과적으로 멸균
                </p>
              </div>
              <div className="gridCol">
                <img
                  src="/img/clean/clean1-3.png"
                  className="img-fluid mb-30"
                  alt=""
                />
                <p className="font-24 itemTitle">EO 가스 멸균기</p>
                <p className="font-24 wordKeep">
                  <strong>
                    가스멸균은 고압증기와 상호 보완적인 멸균방법으로 구조적으로
                    고압증기에 변성이 일어나는 의료기자재를 멸균
                  </strong>
                </p>
              </div>
              <div className="gridCol">
                <img
                  src="/img/clean/clean1-4.png"
                  className="img-fluid mb-30"
                  alt=""
                />
                <p className="font-24 itemTitle">자외선소독기</p>
                <p className="font-24 wordKeep">
                  <strong>
                    가시광선 중 가장 파장이 짧은 자색보다 더 짧은 광선을
                    자외선이라고 하고 강력한 살균능력을 가짐
                  </strong>
                </p>
              </div>
            </div>
          </Flip>
        </Contain>
      </div>
    </>
  );
}

export default Clean;
