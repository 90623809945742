import React, { useState, useEffect } from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import DatePicker, { registerLocale } from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Input,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import styled from "styled-components";
import ko from "date-fns/locale/ko";

import Moment from "moment";
import { subDays, lastDayOfMonth, addDays } from "date-fns";

import { API_URL } from "../_constants/defaultValue";
import { instance } from "../_helpers";
import axios from "axios";
import moment from "moment";

const RodalHeader = styled.h1`
  font-size: 24px;
  text-align: center;
  color: var(--color-link);
  margin-bottom: var(--size-30px);
  img {
    margin-right: 10px;
  }
`;
const InfoForm = styled.input`
  height: 40px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid #ccc;
  font-size: 16px;
`;

const BtnSubmit = styled.button`
  color: #fff;
  background: #575c60;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  display: block;
  width: 100%;
  border: 0;
  outline: 0;
  line-height: 60px;
  &.dis {
    background: #ccc;
    color: #111;
  }
`;

const Select = styled.div`
  position: relative;
`;
const SelectToggle = styled.button`
  position: relative;
  color: #fff;
  background: #575c60;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  display: block;
  width: 100%;
  border: 0;
  outline: 0;
  line-height: 40px;
  &:after {
    content: "";
    width: 13px;
    height: 12px;
    background: url("/img/carret.svg") no-repeat center top;
    background-size: 13px auto;
    display: inline-block;
    margin-left: 5px;
  }
  &.light {
    background: #f2f5f7;
    color: #3a3838;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:after {
      content: "";
      width: 13px;
      height: 12px;
      background: url("/img/carret-dark.svg") no-repeat center top;
      background-size: 13px auto;
      display: inline-block;
      margin-left: 5px;
    }
  }
`;
const SelectMenu = styled.ul`
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  list-style: none;
  padding: 0;
  background: #fff;
  z-index: 10;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  &.reverse {
    right: 0;
  }
  &.reverse2 {
    top: auto;
    bottom: 42px;
  }
`;
const SelectItem = styled.li`
  font-size: 14px;
  padding: 10px 10px 10px 20px;
  border-top: 1px solid #ccc;
  transition: all 0.1s ease-in-out;
  &:first-child {
    border-top: 0;
  }
  &:hover {
    background: var(--color-link);
    color: #fff;
    text-indent: 10px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  .ovIn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 250px;
    background: #fff;
    border-radius: 5px;
    padding: 25px 15px 15px 15px;
    text-align: center;
    .ovMsg {
      font-size: 20px;
      margin-bottom: 20px;
    }
    .btnCls {
      border: 0;
      outline: 0;
      display: block;
      text-align: center;
      background: #575c60;
      color: #fff;
      font-size: 20px;
      width: 100%;
      line-height: 40px;
    }
  }
`;

const FinalAlertBox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  .ovIn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 90%;
    background: #fff;
    border-radius: 5px;
    padding: 25px 15px 15px 15px;
    .ovMsg {
      font-size: 20px;
      margin-bottom: 20px;
    }
    .btnCls {
      border: 0;
      outline: 0;
      display: block;
      text-align: center;
      background: #575c60;
      color: #fff;
      font-size: 20px;
      width: 100%;
      line-height: 40px;
    }
    .btnAct {
      border: 0;
      outline: 0;
      display: block;
      text-align: center;
      background: var(--color-link);
      color: #fff;
      font-size: 20px;
      width: 100%;
      line-height: 40px;
    }
  }
`;

const Term = styled.div`
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  min-height: 500px;
  background: #fff;
  padding: 3rem;
  z-index: 10;
  textarea {
    width: 100%;
    height: 100px;
    resize: none;
    overflow-y: auto;
    border: 1px solid #ccc;
    font-size: 14px;
    color: #1a1a1a;
    font-weight: 400;
    margin-bottom: 5px;
    padding: 15px;
  }
  button {
    position: absolute;
    left: 3rem;
    right: 3rem;
    bottom: 3rem;
    display: block;
    border: 0;
    outline: 0;
    width: calc(100% - 6rem);
    font-size: 18px;
    line-height: 2;
    background: #575c60;
    color: #fff;
    &.dis {
      background: #ccc;
      color: #111;
    }
  }
  .tBox {
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 50px;
    }
  }
  label {
    margin-left: 10px;
    font-size: 14px;
  }
`;

registerLocale("ko", ko);

// 1주일 이내 선택 필터
const oneWeeksFromNow = new Date();
oneWeeksFromNow.setDate(oneWeeksFromNow.getDate() + 7);

// 통신사 목록
const phonePreList = [
  {
    id: 1,
    pre: "010",
  },
  {
    id: 2,
    pre: "016",
  },
  {
    id: 3,
    pre: "017",
  },
  {
    id: 4,
    pre: "018",
  },
  {
    id: 5,
    pre: "019",
  },
];

// 진료실 목록
const rooms = [
  {
    id: 1,
    roomNm: "선택하세요.",
  },
  {
    id: 2,
    roomNm: "1진료실",
  },
  {
    id: 3,
    roomNm: "2진료실",
  },
];

// 시간 목록
const timeListArr = () => {
  const date = [];
  const timeArr = [];
  date = new Date();
  while (date.getMinutes() % 15 !== 0) {
    date.setMinutes(date.getMinutes() + 1);
  }
  for (var i = 0; i < 24 * 4; i++) {
    array.push(date.getHours() + ":" + date.getMinutes());
    date.setMinutes(date.getMinutes() + 15);
  }

  console.log(array);
};

function ResvRodal({
  visibleResv,
  resvClose,
  resvComplate,
  defaultTreatId,
  defaultTreatNm,
}) {
  const [submitDisabled, setSubmitDisabled] = useState(false);

  // 의사목록
  const [doctor, setDoctor] = useState([]);

  // 진료과목
  const [treatList, setTreatList] = useState([]);

  // axios로 받은 날짜 목록
  const [schDateList, setSchDateList] = useState([]);
  // 위 날짜 목록의 원본 object
  const [orgSchDateList, setSchOrgDateList] = useState([]);

  // 진료날짜
  const [schDate, setSchDate] = useState("");

  // 진료시간
  const [schTimeList, setSchTimeList] = useState([]);

  // 결과 전송용 값들
  const [selectedDocotrId, setSelectedDoctorId] = useState(0); // 안쓸예정
  const [selectedTreatId, setSelectedTreatId] = useState(0);
  // const [selectedSchId, setSelectedSchId] = useState(0);
  const [resvName, setResvName] = useState("");
  const [phonePre, setPhonePre] = useState("010");
  const [phoneMid, setPhoneMid] = useState("");
  const [phoneEnd, setPhoneEnd] = useState("");
  const [selectedSchDate, setSelectedSchDate] = useState("");
  const [selectedSchTime, setSelectedSchTime] = useState("");
  const [roomNm, setRoomNm] = useState("");

  const [doctorName, setDoctorName] = useState("");
  const [treatNm, setTreatNm] = useState("");

  const [doctorDropdownOpen, setDoctorDropdownOpen] = useState(false);
  const [treatDropdownOepn, setTreatDropdownOpen] = useState(false);
  const [phonePreDropdownOpen, setPhonePreDropdownOpen] = useState(false);
  const [timeListDropdownOepn, setTimeListDropdownOepn] = useState(false);
  const [roomDropdownOpen, setRoomDropdownOpen] = useState(false);

  const [preIsActive, setPreIsActive] = useState(false);
  const [doctorIsActive, setDoctorIsActive] = useState(false);
  const [treatIsActive, setTreatIsActive] = useState(false);
  const [timeIsActive, setTimeIsActive] = useState(false);
  const [roomIsActive, setRoomIsActive] = useState(false);

  const [ovActive, setOvActive] = useState(false);
  const [ovMsg, setOvMsg] = useState("");

  const [ovType, setOvType] = useState(false); // true면 예약완료
  const [ovSuccess, setOvSuccess] = useState(false);

  const [finalAlert, setFinalAlert] = useState(false); // 예약할건지 확인 팝업

  const [defaultId, setDefaultId] = useState(0);

  const [term, setTerm] = useState(true);
  const [t1, setT1] = useState(false);
  const [t2, setT2] = useState(false);
  const [t3, setT3] = useState(false);
  const [termErr, setTermErr] = useState(false);

  const doctorToggle = () => setDoctorDropdownOpen((prevState) => !prevState);
  const treatToggle = () => setTreatDropdownOpen((prevState) => !prevState);
  const phonePreToggle = () =>
    setPhonePreDropdownOpen((prevState) => !prevState);
  const timeListToggle = () =>
    setTimeListDropdownOepn((prevState) => !prevState);
  const roomToggle = () => setRoomDropdownOpen((prevState) => !prevState);

  const [noShowErr, setNoShowErr] = useState(false); // 노쇼
  const [dup, setDup] = useState(false); // 중복 예약
  const [finalActionDisabled, setFinalActionDisabled] = useState(false);

  const [showForm, setShowForm] = useState(false); // 약관 동의 후 예약 폼 출력

  const [noList, setNoList] = useState(false); // 예약 가능시간 없슴 팝업

  /**
   * form submit
   */
  const formSubmit = () => {
    setSubmitDisabled(true);
    let schId = 0;
    orgSchDateList.map((item) => {
      if (item.schDate === selectedSchDate) {
        schId = item.id;
      }
    });
    const request = {
      resvName,
      phonePre,
      phoneMid,
      phoneEnd,
      doctorId: selectedDocotrId,
      treatId: selectedTreatId,
      schId: schId,
      schDate: selectedSchDate,
      schTime: selectedSchTime,
      roomNm,
    };
    if (!resvName) {
      setOvMsg("이름을 입력하세요.");
      setOvActive(true);
      setSubmitDisabled(false);
      return false;
    }
    if (!phonePre) {
      setOvMsg("통신사를 선택하세요.");
      setOvActive(true);
      setSubmitDisabled(false);
      return false;
    }
    if (!phoneMid) {
      setOvMsg("전화번호를 입력하세요.");
      setOvActive(true);
      setSubmitDisabled(false);
      return false;
    }
    if (!phoneEnd) {
      setOvMsg("전화번호를 입력하세요.");
      setOvActive(true);
      setSubmitDisabled(false);
      return false;
    }
    if (!selectedDocotrId) {
      setOvMsg("의사를 선택하세요.");
      setOvActive(true);
      setSubmitDisabled(false);
      return false;
    }
    if (!selectedTreatId) {
      setOvMsg("진료과목을 선택하세요.");
      setOvActive(true);
      setSubmitDisabled(false);
      return false;
    }
    if (!selectedSchDate) {
      setOvMsg("날짜를 선택하세요.");
      setOvActive(true);
      setSubmitDisabled(false);
      return false;
    }
    if (!selectedSchTime) {
      setOvMsg("시간을 선택하세요.");
      setOvActive(true);
      setSubmitDisabled(false);
      return false;
    }
    // if (!roomNm) {
    //   setOvMsg("진료실을 선택하세요.");
    //   setOvActive(true);
    //   setSubmitDisabled(false);
    //   return false;
    // }
    setFinalAlert(true);
  };

  const saveData = () => {
    setFinalActionDisabled(true);
    let schId = 0;
    orgSchDateList.map((item) => {
      if (item.schDate === selectedSchDate) {
        schId = item.id;
      }
    });
    const request = {
      name: resvName,
      pPre: phonePre,
      pMid: phoneMid,
      pEnd: phoneEnd,
      doctorId: selectedDocotrId,
      treatId: selectedTreatId,
      rDt: selectedSchDate,
      rHm: selectedSchTime,
    };
    instance
      .post(`/api/v2/front/saveResvRequest`, request)
      .then((res) => {
        console.log(res.data.data)
        if (res.data.data === 'noShow') {
          setFinalAlert(false);
          setNoShowErr(true);
        } else if (res.data.data === 'dup') {
          setFinalAlert(false);
          setDup(true);
        } else {
          if (res.data.success) {
            // 완료
            setResvName("");
            setPhonePre("");
            setPhoneMid("");
            setPhoneEnd("");
            setSelectedDoctorId(0);
            setSelectedTreatId(0);
            setSelectedSchDate("");
            setSelectedSchTime("");
            setRoomNm("");
            setDoctor([""]);
            setTreatList([]);
            setSchDateList([]);
            setSchOrgDateList([]);
            setSchTimeList([]);
            setOvMsg("예약이 완료되었습니다.");
            setOvActive(true);
            setOvSuccess(true);
            setOvType(true);
            setNoList(false);
          }
        }
        
      })
      .finally(() => {
        setSubmitDisabled(false);
        setFinalActionDisabled(false);
      });
  };

  const preHandler = () => {
    setPreIsActive(!preIsActive);
    setDoctorIsActive(false);
    setTimeIsActive(false);
    setTreatIsActive(false);
    setRoomIsActive(false);
  };
  const doctorHandler = () => {
    setPreIsActive(false);
    setDoctorIsActive(!doctorIsActive);
    setTimeIsActive(false);
    setTreatIsActive(false);
    setRoomIsActive(false);
  };
  const timeHandler = () => {
    setPreIsActive(false);
    setDoctorIsActive(false);
    setTimeIsActive(!timeIsActive);
    setTreatIsActive(false);
    setRoomIsActive(false);
  };
  const treatHandler = () => {
    setPreIsActive(false);
    setDoctorIsActive(false);
    setTimeIsActive(false);
    setTreatIsActive(!treatIsActive);
    setRoomIsActive(false);
    setSelectedSchTime("");
    setSelectedSchDate("");
  };

  const hideDropDown = () => {
    setPreIsActive(false);
    setDoctorIsActive(false);
    setTimeIsActive(false);
    setTreatIsActive(false);
    setRoomIsActive(false);
  };

  const roomHandler = () => {
    setPreIsActive(false);
    setDoctorIsActive(false);
    setTimeIsActive(false);
    setTreatIsActive(false);
    setRoomIsActive(false);
    setRoomIsActive(!roomIsActive);
  };

  /**
   * @param {의사 id} id, name
   */
  const setselectedDoctor = (id, name) => {
    if (id) {
      const request = {
        doctorId: id,
      };
      setSelectedDoctorId(id);
      setDoctorName(name);
      // setSelectedTreatId(0);
      setSelectedSchDate("");
      setSelectedSchTime("");
      setSelectedTreatId(0);
      hideDropDown();
      instance
        .post(`/api/front/doctorDatelist`, request)
        .then((res) => res.data)
        .then((data) => {
          console.log(data);
          setSchOrgDateList(data);
          const arr = [];
          data.map((item) => {
            const tmpDate = item.schDate.split("-");
            arr.push(
              addDays(new Date(tmpDate[0], tmpDate[1] - 1, tmpDate[2]), 0)
            );
          });
          setSchDateList(arr);
        });
    }
  };

  // 진료실 선택
  const setRoomHandler = (name) => {
    setRoomNm(name);
    hideDropDown();
  };

  // 전번 앞자리 선택
  const setPhonePreItem = (item) => {
    setPhonePre(item);
    hideDropDown();
  };

  // 진료과목 선택
  const setTreatItem = (id, treatNm) => {
    setSelectedTreatId(id);
    setTreatNm(treatNm);
    hideDropDown();
  };

  // 시간 선택
  const setTimeItem = (item) => {
    setSelectedSchTime(item);
    hideDropDown();
  };

  /**
   * @param {달력 날짜 선택하면 DB에서 진료예약된 시간을 리턴해서 시간선택목록에 뿌린다} e
   */
  const handleDateRange = async (e) => {
    Moment.locale("ko");
    const selectedDate = Moment(e).format("yyyy-MM-DD");
    setSchDate(selectedDate);
    setSelectedSchDate(selectedDate);

    setSelectedSchTime("");

    hideDropDown();
    // 날짜를 선택하면 orgSchDateList 에서 동일한 날짜를 찾아 id값을 뽑는다.
    let schId = 0;
    orgSchDateList.map((item, index) => {
      if (item.schDate === selectedDate) schId = item.id;
    });
    // 이제 schId와 selectedDate를 /searchTimeListBySchId 에 던진다.
    const timeList = await instance
      .get(
        `/api/v2/front/getTimeList?rDt=${selectedDate}&doctorId=${selectedDocotrId}`
      )
      .then((res) => res.data);

      console.log(timeList.length)
    if (timeList.length === 0) {
      setSchTimeList([]);
      setNoList(true);
    } else {
      const now = Moment().format("HH:mm");
      const resArr = [];
      // 현재시간보다 작은놈은 배열에서 삭제
      // td = 오늘 날짜
      const td = Moment(new Date()).format("yyyy-MM-DD");
      timeList.map((item, index) => {
        if (selectedDate > td) {
          resArr.push(timeList[index]);
        } else {
          if (timeList[index] > now) resArr.push(timeList[index]);
        }
      });
      setSchTimeList(resArr);
    }
    
  };

  /**
   * 주말 체크
   * @param {날짜} date
   */
  const isWeekday = (date) => {
    const day = date.getDay(date);
    return day !== 0;
  };

  /**
   * 의사 목록
   */
  const axiosDoctorList = async () => {
    return await instance.get(`/api/v2/front/doctorList`);
  };

  /**
   * 진료과목 목록
   */
  const axiosTreatList = async () => {
    return await instance.get(`/api/front/treatList`);
  };

  const inputChange = (e) => {
    hideDropDown();
    switch (e.target.id) {
      case "resvName":
        setResvName(e.target.value);
        break;
      case "phonePre":
        setPhonePre(e.target.value);
        break;
      case "phoneMid":
        setPhoneMid(e.target.value);
        break;
      case "phoneEnd":
        setPhoneEnd(e.target.value);
        break;
      default:
        break;
    }
  };

  const chkChange = (e) => {
    if (e.target.id === "t1") {
      setT1(!t1);
    } else if (e.target.id === "t2") {
      setT2(!t2);
    } else if (e.target.id === "t3") {
      setT3(!t3);
    }
  };

  const chkAll = () => {
    setT1(true);
    setT2(true);
    setT3(true);
  };

  const termAccept = () => {
    setTerm(false);
    setShowForm(true);
  };

  useEffect(() => {
    const dl = axiosDoctorList();
    const tl = axiosTreatList();

    Promise.all([dl, tl]).then((value) => {
      setDoctor(value[0].data);
      setTreatList(value[1].data);
      if (defaultTreatId) setSelectedTreatId(defaultTreatId);
      if (defaultTreatNm) setTreatNm(defaultTreatNm);
    });
  }, []);

  return (
    <Rodal
      visible={visibleResv}
      onClose={resvClose}
      animation="slideUp"
      closeOnEsc
      customStyles={{
        borderRadius: "5px",
        height: "auto",
        bottom: "auto",
        top: "50%",
        transform: "translateY(-50%)",
        width: "500px",
        border: "5px solid #0076BE",
      }}
    >
      {term ? (
        <Term>
          <div className="tBox">
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              readOnly
              defaultValue={`[DMC하나이비인후과의원 이용약관]
제 1조 (목적)
본 약관은 DMC하나이비인후과의원(이하 "본원")이 제공하는 인터넷 관련 서비스를 이용함에 있어 본원과 이용자(또는 회원)와의 권리, 이용조건 및 절차, 책임사항 등 기타 기본적인 사항을 구체적으로 규정함을 목적으로 합니다.

제 2 조 (이용약관의 효력 및 변경)
① 이 약관은 본원이 제공하는 서비스에서 이용자들이 쉽게 알 수 있도록 온라인으로 공시함으로써 효력을 발생합니다.
② 본원은 합리적인 사유가 발생할 경우 대한민국 법령과 국제법에 위배되지 않는 범위 안에서 본원은 이 약관을 개정할 수 있습니다.
③ 개정된 약관은 온라인에서 공지함으로써 효력을 발휘하며, 이용자의 권리 또는 의무 등 중요한 규정의 개정은 개정 7일전 온라인을 통하여 공지합니다.
④ 이용자는 변경된 약관에 동의하지 않으면, 언제나 서비스 이용을 중단하고, 이용계약을 해지할 수 있습니다.
단, 약관의 효력발생일 이후에 계속적인 서비스 이용은 약관의 변경사항에 대한 이용자의 동의로 간주됩니다.

제 3조 (약관 외 준칙)
① 이 약관은 본원이 제공하는 개별서비스에 관한 이용안내(이하 서비스 별 안내라 합니다)와 함께 적용합니다.
② 이 약관에 명시되지 아니한 사항에 대해서는 관계법령 및 본원이 정한 세부지침 사항을 적용할 수 있습니다.

제 4 조 (용어의 정의)
① 회원: 서비스를 이용하기 위하여 본원과 이용계약을 체결하고 아이디와 비밀번호를 부여 받은 자를 말합니다.
② 이용고객: 회원 및 회원이 아닌 서비스 이용자를 말합니다.
③ 이용계약: 이용고객이 본원이 정한 약관에 동의하고 온라인 신청양식을 작성하여 서비스 이용을 신청하면 본원이 이용고객에게 이용자 아이디를 부여하여 서비스를 이용할 수 있도록 하는 것을 말합니다.
④ 아이디(ID): 회원의 식별과 회원이 정하고 본원이 승인한 영문 숫자 조합을 말합니다.
⑤ 비밀번호: 회원의 권익보호를 위하여 회원이 선정한 문자 및 숫자의 조합을 말합니다.
⑥ 회원정보: 성명, 연락처 등 본원이 온라인 신청양식에 기재를 요청하는 회원의 개인정보를 말합니다.
⑦ 게시물: 본원이 제공하는 서비스의 게시판에 회원이 게재 또는 등록하는 질문, 답변, 자료, 사진, 영상 댓글 등을 의미합니다.
⑧ 운영자: 서비스의 전반적인 관리 및 원활한 운영을 위하여 본원이 고용한 자를 말합니다.
⑨ 게시판 관리자: 회원 중 본원이 정한 선발 정책을 통과하여 게시판의 관리를 맡아 하는 회원을 말합니다.

제 5 조 (이용 계약의 성립)
① 이용계약은 이용고객이 이용약관 및 개인정보취급방침 내용에 대한 “동의”버튼을 누름으로써 동의한 것으로 간주합니다.
② 이용계약은 이용고객의 이용신청에 대하여 본원이 이를 승낙함으로써 성립한다.

제 6 조 (서비스 이용 신청)
① 회원가입은 실명으로 가입하여야 합니다.
② 회원으로 가입하여 본 서비스를 이용하고자 하는 이용고객은 본원에서 요청하는 제반 정보를 제공하여야 합니다.
1. 이름
2. 전자메일(E-mail)
3. 아이디(ID)
4. 닉네임
5. 비밀번호
6. 주소
7. 휴대전화
8. 성별
9. 주민등록번호
③ 모든 회원은 반드시 회원 본인의 이름과 연락처를 제공하여야만 서비스를 이용할 수 있으며, 실명으로 등록 한 사용자만이 권리를 주장할 수 있습니다.
④ 타인의 명의(개인정보) 도용하여 이용신청을 한 회원의 ID는 삭제되며, 관계법령에 따라 처벌을 받을 수 있습니다.
⑤ 본원은 본 서비스를 이용하는 회원에 대하여 등급별로 구분하여 서비스 이용에 차등을 둘 수 있습니다.

제 7 조 (이용자의 개인 정보 보호)
본원은 관련법령이 정하는 바에 따라서 이용자 등록정보를 포함한 이용자의 개인정보를 보호하기 위하여 노력합니다.
이용자의 개인정보보호에 관해서는 관련법령 및 "DMC하나이비인후과의원"이 정하는 "개인정보 취급방침"에 정한 바에 의합니다.

제 8 조 (이용 신청의 승낙)
① 이용자가 되고자 하는 자는 제 6 조에서 정한 가입 양식에 따라 회원정보를 기입하고 "등록하기" 단추를 누른 회원을 대상으로 서비스 이용 승낙을 합니다.

제 9 조 (이용 신청의 제한)
① 본원은 다음 각 호에 해당하는 경우에 대하여서는 서비스 이용 승낙을 하지 않을 수 있습니다.
1. 타인의 명의를 도용하여 허위로 신청하는 경우
2. 등록 내용에 허위, 기재누락, 오기가 있는 경우
3. 가입신청자가 본 약관 의거하여 이전에 회원 자격을 상실한 적이 있는 경우
4. 사회의 안녕과 질서, 미풍양속을 저해할 목적으로 신청한 경우
5. 버그 및 악성 프로그램을 사용하여 시스템의 취약점을 이용하여 가입 신청을 한 경우
6. 이용자의 귀책사유로 인하여 사이트 운영정책에 따라 회원 자격 상실을 한 적이 있는 경우
7. 본 서비스와 경쟁관계에 있는 이용자가 신청하는 경우
8. 기타 본원이 정한 이용신청 요건이 만족되지 않았을 경우
② 서비스 이용신청이 다음 각 호에 해당하는 경우에는 본원은 그 신청에 대하여 승낙 제한사유가 해소될 때까지 승낙을 유보할 수 있습니다.
1. 설비의 여유가 없는 경우
2. 기술상 지장이 있는 경우
3. 기타 귀책사유로 이용승낙이 곤란한 경우
③ 이용신청고객이 관계법령에서 규정하는 미성년자일 경우에 본원은 서비스 별 안내에서 정하는 바에 따라 승낙을 보류할 수 있습니다.

제 10 조 (이용자ID 부여 및 변경 등)
① 이용고객에 대하여 약관에 정하는 바에 따라 본원은 이용자 ID를 부여합니다.
② 이용자ID는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경 하고자 하는 경우에는 해당 ID를 해지하고 재가입해야 합니다.
③ 서비스의 이용자ID는 이용자 본인의 동의하에 ''가 운영하는 자사 사이트의 회원ID와 연결될 수 있습니다.
④ 이용자ID는 다음 각 호에 해당하는 경우에는 이용고객 또는 본원의 요청으로 변경할 수 있습니다.
1. 이용자ID가 이용자의 전화번호 또는 주민등록번호 등으로 등록되어 심각한 사생활침해가 우려되는 경우 2. 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우 3. 기타 합리적인 사유가 있는 경우 ⑤ 서비스 이용자ID 및 비밀번호의 관리책임은 이용자에게 있습니다.
이를 소홀이 관리하여 발생하는 서비스 이용상의 손해 또는 제3자에 의한 부정이용 등에 대한 책임은 이용자에게 있으며 본원은 그에 대한 책임을 일절 지지 않습니다.
⑥ 기타 이용자 개인정보 관리 및 변경 등에 관한 사항은 서비스 별 안내에 정하는 바에 의합니다.

제 11 조 (서비스 이용 시간 및 중지)
① 서비스 이용은 본원의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 운영을 원칙으로 합니다.
② 본원은 긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유로 인하여 예고 없이 일시적으로 서비스를 중단할 수 있으며, 새로운 서비스로의 교체 등 본원이 적절하다고 판단하는 사유에 의하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다. 이러한 경우 본원은 사전 또는 사후에 이를 이용자들이 쉽게 알 수 있도록 공지합니다.
③ 본원은 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 불가능할 경우, 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 이 경우 그 사유 및 기간 등을 회원에게 사전 또는 사후에 공지합니다.
④ 본원은 본원이 통제 불가능의 사유로 인한 서비스중단의 경우 사전통지가 불가능하며 타인의 고의, 과실로 인한 시스템중단 등의 경우에는 통보하지 아니합니다.
⑤ 본원은 서비스를 특정범위로 분할하여 각 범위 별로 이용가능 시간을 별도로 지정할 수 있습니다.
다만 이 경우 그 내용을 공지합니다.

제 12 조 (이용자ID 관리)
① 이용자 ID와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다.
② 본원은 이용자 ID에 의하여 제반 이용자 관리업무를 수행 하므로 회원이 이용자 ID를 변경하고자 하는 경우 본원이 인정할 만한 사유가 없는 한 이용자 ID의 변경을 제한할 수 있습니다.
③ 이용고객이 등록한 이용자 ID 및 비밀번호에 의하여 발생되는 사용상의 과실 또는 제 3자에 의한 부정사용 등에 대한 모든 책임은 해당 이용고객에게 있습니다.

제 13 조 (게시물의 관리)
본원은 다음 각 호에 해당하는 게시물이나 자료를 사전통지 없이 삭제하거나 이동 또는 등록 거부를 할 수 있습니다.
1. 다른 회원 또는 제 3자에게 심한 모욕을 주거나 명예를 손상시키는 내용인 경우
2. 타인의 초상권, 사생활, 영업상 비밀 등을 유포하는 경우
3. 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우
4. 불법복제 또는 해킹을 조장하는 내용인 경우
5. 영리를 목적으로 하는 광고일 경우
6. 범죄와 결부된다고 객관적으로 인정되는 내용일 경우
7. 다른 이용자 또는 제 3자의 저작권 등 기타 권리를 침해하는 내용인 경우
8. 본원에서 규정한 운영정책에 어긋나거나, 게시판 성격에 부합하지 않는 거나 운영상 악영향을 주거나 혼란을 주는 경우
9. 동일한 내용을 중복하여 다수의 게시판에 작성하여 게시의 목적에 어긋나는 경우
10. 기타 관계법령에 위배된다고 판단되는 경우

제 14 조 (게시물에 대한 저작권)
① 회원이 서비스 화면 내에 게시한 게시물의 저작권은 게시한 회원에게 귀속됩니다. 또한 본원은 게시자의 동의 없이 게시물을 상업적으로 이용할 수 없습니다.
다만 비영리 목적인 경우는 그러하지 아니하며, 또한 서비스 내의 게재권을 갖습니다. ② 회원은 서비스를 이용하여 취득한 정보를 임의 가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.
③ 본원은 회원이 게시하거나 등록하는 서비스 내의 내용물, 게시 내용에 대해 제 13조 각 호에 해당된다고 판단되는 경우 사전통지 없이 삭제하거나 이동 또는 등록 거부할 수 있습니다.

제 15 조 (정보의 제공)
① 본원은 회원에게 서비스 이용에 필요가 있다고 인정되는 각종 정보에 대해서 전자우편이나 서신우편 등의 방법으로 회원에게 제공할 수 있습니다. 다만 동의하지 않은 회원에게는 제공하지 아니 합니다.
② 본원은 서비스 개선 및 회원 대상의 서비스 소개 등의 목적으로 회원의 동의하에 추가적인 개인 정보를 요구할 수 있습니다.

제 16 조 (광고게재 및 광고주와의 거래)
① 본원이 회원에게 서비스를 제공할 수 있는 서비스 투자기반의 일부는 광고게재를 통한 수익으로부터 나옵니다. 회원은 서비스 이용 시 노출되는 광고게재에 대해 동의합니다.
② 본원은 서비스상에 게재되어 있거나 본 서비스를 통한 광고주의 판촉활동에 회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해 책임을 지지 않습니다.

제 17 조 (본원의 의무)
① 본원은 법령과 본 약관이 금지하거나 公序良俗 (공서양속)에 반하는 행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하기 위해서 노력합니다.
② 본원은 회원의 개인정보를 안전하게 보호하며 회원의 승낙 없이 제 3자에게 제공하지 않습니다. 다만, 관계법령 및 수사상의 목적으로 관계기관으로부터 요청을 받았을 경우 이는 예외로 합니다.
③ 본원은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 구축합니다.
④ 본원은 이용고객으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우는 이용자에게 그 사유와 처리일정을 통보하여야 합니다.

제 18 조 (이용자의 의무)
① 이용자는 회원가입 신청 또는 회원정보 변경 시 실명으로 모든 사항을 사실에 근거하여 작성하여야 하며, 허위 또는 타인의 정보를 등록할 경우 일체의 권리를 주장할 수 없습니다.
② 회원은 본 약관에서 규정하는 사항과 기타 본원이 정한 제반 규정, 공지사항 등 본원이 공지하는 사항 및 관계법령을 준수하여야 하며, 기타 본원의 업무에 방해가 되는 행위, 본원의 명예를 손상시키는 행위를 해서는 안 됩니다.
③ 본원이 관계법령 및 '개인정보 취급방침'에 의거하여 그 책임을 지는 경우를 제외하고 회원에게 부여된 ID의 비밀번호 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.
④ 회원은 본원의 사전 승낙 없이 서비스를 이용하여 영업활동을 할 수 없으며, 그 영업활동의 결과에 대해 본원은 책임을 지지 않습니다. 또한 회원은 이와 같은 영업활동으로 본원이 손해를 입은 경우, 회원은 본원에 대해 손해배상의무를 지며, 본원은 해당 회원에 대해 서비스 이용제한 및 적법한 절차를 거쳐 손해배상 등을 청구할 수 있습니다.
⑤ 회원은 본원의 명시적 동의가 없는 한 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.
⑥ 회원은 본원 및 제 3자의 지적 재산권을 침해해서는 안 됩니다.
⑦ 회원은 다음 각 호에 해당하는 행위를 하여서는 안 되며, 해당 행위를 하는 경우에 본원은 회원의 서비스 이용제한 및 적법 조치를 포함한 제재를 가할 수 있습니다.
1. 직원이나 서비스의 관리자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여 글을 게시 하거나 메일을 발송하는 행위
2. 회원가입 신청 또는 회원정보 변경 시 허위내용을 등록하는 행위
3. 다른 이용자의 ID, 비밀번호, 전자메일 등을 도용하는 행위
4. 이용자 ID를 타인과 거래하는 행위
5. 본원의 운영진, 직원 또는 관계자를 사칭하는 행위
6. 본원으로부터 특별한 권리를 부여 받지 않고 본원의 클라이언트 프로그램을 변경하거나, 본원의 서버를 해킹하거나, 웹사이트 또는 게시된 정보의 일부분 또는 전체를 임의로 변경하는 행위
7. 서비스에 위해를 가하거나 고의로 방해하는 행위
8. 본 서비스를 통해 얻은 정보를 본원의 사전 승낙 없이 서비스 이용 외의 목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나, 제 3 자에게 제공하는 행위
9. 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 등)의 전송 또는 게시하는 행위
10. 공공질서 및 미풍양속에 위반되는 저속, 음란한 내용의 정보, 문장, 도형, 음향, 동영상을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위
11. 모욕적이거나 개인신상에 대한 내용이어서 타인의 명예나 프라이버시를 침해할 수 있는 내용을 전송, 게시, 쪽지 또는 기타의 방법으로 타인에게 유포하는 행위
12. 다른 이용자를 희롱 또는 위협하거나, 특정 이용자에게 지속적으로 고통 또는 불편을 주는 행위
13. 본원의 승인을 받지 않고 다른 사용자의 개인정보를 수집 또는 저장하는 행위
14. 범죄와 결부된다고 객관적으로 판단되는 행위
15. 본 약관을 포함하여 기타 본원이 정한 제반 규정 또는 이용 조건을 위반하는 및 기타 대한민국 관계법령 및 국제법에 위배되는 행위 등

제 19 조 (계약 변경 및 해지)
① 회원이 이용계약을 해지하고자 하는 때에는 사이트 상 본원이 정한 별도의 이용방법에 따라 해지를 하여야 합니다.
② 회원이 제 19조 사항을 위반하였을 경우 본원은 사전 통보 없이 즉시 이용관계를 해지하거나 회원의 서비스 이용을 중지 시킬 수 있습니다.
③ 본원은 1년 동안 서비스를 사용하지 않은 회원에 대하여 서비스에 대한 사용여부를 묻고 이에 대한 답변이 없을 경우 본원은 이용계약을 해지 할 수 있습니다.
④ 제 2항 과 3항에 조치에 대하여 이의가 있을 경우 회원은 본원이 정한 이용방법에 따라 이의 신청을 할 수 있습니다. 또한 이의신청이 타당하다고 판단 될 시 본원은 즉시 회원의 서비스 이용을 재개 합니다.

제 20 조 (서비스 이용제한)
① 본원은 회원이 서비스 이용내용에 있어서 본 약관 제 18조 7항의 내용을 위반하거나, 다음 각 호에 해당하는 경우 서비스 이용을 제한할 수 있습니다.
1. 미풍양속을 저해하는 비속한 ID 및 넥네임 사용
2. 타 이용자에게 심한 모욕을 주거나, 서비스 이용을 방해한 경우
3. 저작권이 있는 자료의 글을 무단 게시한 경우
4. 정보통신 윤리위원회 등 관련 공공기관의 시정 요구가 있는 경우
5. 불법 사이트를 홍보 광고한 경우
6. 타인의 허락 없이 사진, 게시물, 개인정보를 유포하는 경우
7. 정보통신윤리위원회의 심의 세칙 제 7조에 어긋나는 음란물을 게재한 경우
8. 반국가적 행위의 수행을 목적으로 하는 내용을 포함한 경우
9. 사이트 운영에 심각한 영향을 끼치는 경우
② 상기 이용제한 규정에 따라 서비스를 이용하는 회원에게 서비스 이용에 대하여 별도 공지 없이 서비스 이용의 일시 정지, 초기화, 이용계약 해지 등을 불량이용자 처리규정에 따라 취할 수 있습니다.

제 21 조 (손해배상)
① 본 약관을 위반하여 본원에 손해가 발생 되었을 때에는 회원은 본원에 발생한 모든 손해를 배상하여야 합니다.
② 본원은 서비스에서 제공하는 서비스의 이용과 관련하여 개인정보 취급방침에서 정하는 내용에 해당하지 않는 사항에 대하여는 어떠한 손해도 책임을 지지 않습니다.
③ 본원은 회원이 DMC하나이비인후과의원 서비스를 이용함으로써 직/간접적으로 행하는 모든 피해와 법적 책임에 대하여 어떠한 손해도 책임 지지 않습니다.

제 22 조 (면책조항)
① 본원은 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.
② 본원은 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.
③ 본원은 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대하여 책임을 지지 않습니다.
④ 본원은 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.
⑤ 본원은 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.
⑥ 본원은 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다.
⑦ 본원은 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다. 또한 본원은 회원이 서비스를 이용하며 타 회원으로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.
⑧ 본원은 회원이 서비스에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여 책임을 지지 않습니다.
⑨ 본원은 이용자 상호간 및 이용자와 제 3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.
⑩ 본원에서 회원에게 무료로 제공하는 서비스의 이용과 관련해서는 어떠한 손해도 책임을 지지 않습니다.
⑪ 본원은 회원의 게시판 사용으로 발생하는 어떠한 손해에도 책임을 지지 않으며 모든 책임은 게시물을 작성한 본인에게 있습니다.
⑫ 본원은 대한민국 법에서 정한 규율을 존중하며 정보통신법과 청소년보호법을 비롯한 모든 법률을 위반하는 게시물을 올리는 것을 절대 허용하지 않으며 이에 관하여 본원은 일체의 책임을 지지 않습니다.
⑬ 본원은 회원이 서비스를 이용함으로써 회원 본인 또는 제3자에게 직/간접적으로 영향을 미치게 되는 모든 피해와 법적 문제에 대하여 책임을 지지 않습니다.

제 23 조 (재판권 및 준거법)
① 이 약관에 명시되지 않은 사항은 전기통신사업법 등 관계법령과 상관습에 따릅니다.
② 본원과 회원 간에 분쟁이 발생한 경우 이를 원만히 해결하기 위하여서 본원과 회원은 긴밀히 협의하여야 합니다.
③ 서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 본원의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.


부 칙(시행일)
1. 본 약관 시행일자 2021년 3월 1일부터 시행한다.`}
            ></textarea>
            <div>
              <input
                type="checkbox"
                id="t1"
                onChange={(e) => chkChange(e)}
                checked={t1}
              />
              <label htmlFor="t1">이용약관</label>
            </div>
          </div>
          <div className="tBox">
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              readOnly
              defaultValue={`[DMC하나이비인후과의원 개인정보취급방침]
■ 수집하는 개인정보 항목

DMC하나이비인후과의원은 온라인 상담을 위해 아래와 같은 개인정보를 수집하고 있습니다.
ο 수집항목 : 이름 , 열람/수정 비밀번호 , 휴대전화번호 , 이메일
ο 본인확인 항목 : 이름, 주민등록번호, 핸드폰번호(인증), 이메일(인증),공인인증서
ο 개인정보 수집방법 : 비회원 글등록 및 회원전용 서비스 제공을 위한 회원가입.

■ 개인정보의 수집 및 이용목적
본원은 수집한 개인정보를 다음의 목적을 위해 활용합니다.
ο 정확한 병명 및 환자 상태 확인 및 상담 답변
■ 개인정보의 보유 및 이용기간
본원은 상담서비스를 이용하는 동안 개인정보를 보유 및 이용하며, 상담이 완료된후 지속적인 관리를 위해 해당 정보를 보관하나 서면,전화,메일 요청시 열람/수정/삭제가 가능합니다.
단, 본원에서 주관하는 이벤트 참여시 이벤트 종료시까지 변동알림 및 통보,통제를 위해 이벤트 진행이 종료될 때 까지
데이타가 보관 될 수 있으며, 수신여부와 상관없이 관련 연락,정보들이 수신될 수 있습니다.
■ 개인정보의 파기절차 및 방법
본원은 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.

ο 파기절차

서면,전화,이메일로 사이트내 정보가 입력된 주소를 알려주시면 지체없이 파기를 진행합니다.
사이트 주소(URL)을 모를경우엔 파기할 개인정보를 알려주시면 해당부분을 찾아 확인후 삭제 해드리겠습니다.
요청시 본원에서 지정한 서류 (주민등록증 사본)을 꼭 제시하셔야 합니다.

ο 파기방법

- 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.

■ 개인정보 제공
본원은 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.

- 이용자들이 사전에 동의한 경우

- 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
■ 이용자 및 법정대리인의 권리와 그 행사방법
이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니 다.
귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까 지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자 에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.
본원은 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "본원이 수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

- 사이트에 기록한 개인정보가 있고 작성한 게시물에 대한 비밀번호를 알고 있다면 해당 게시물을 열람,수정,삭제를 스스로 할 수 있습니다.
- 만약 비밀번호 및 열람정보를 잊어버렸다면 개인정보관리책임자(하단 기재)에게 연락하여 도움을 청할 수 있습니다.
■ 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
본원은 귀하의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)' 등을 사용하지 않습니다.
쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장되는것을 말하며 본원은 본원 자체의 서버세션을 이용하기에 고객님 컴퓨터에 아무런 정보를 남기지 않습니다.
단, 서비스이용의 편리를 위한 로그인유지 목적의 쿠키는 저장될 수 있습니다.
■ 개인정보 관리 책임자
본원은 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.

개인정보관리책임자 성명 : 주식회사 메이드인리얼 신범진 이사
전화번호 : 02-1666-5898 (AM : 10:00 ~ PM : 07:00 ) / 키워드,온라인 광고 관련 문의는 정중히 사절합니다.
이메일 : info@madeinreal.com

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

1.개인분쟁조정위원회 (www.1336.or.kr/1336)
2.정보보호마크인증위원회 (www.eprivacy.or.kr/02-580-0533~4)
3.대검찰청 인터넷범죄수사센터 (http://icic.sppo.go.kr/02-3480-3600)
4.경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)`}
            ></textarea>
            <div>
              <input
                type="checkbox"
                id="t2"
                onChange={(e) => chkChange(e)}
                checked={t2}
              />
              <label htmlFor="t2">개인정보취급방침</label>
            </div>
          </div>
          <div className="tBox">
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              readOnly
              defaultValue={`[DMC하나이비인후과의원 제 3자 위탁운영 및 정보제공 약관동의]
■ 제3자 정보제공 사유
DMC하나이비인후과의원(이하 "본원")의 웹사이트 및 DB는 위탁업체에 위임하여 위탁관리 되고 있습니다.
위탁업체의 책임아래 본원의 데이타는 위탁업체의 서버에 저장됩니다.
또한, 상담시 답변을 원활히 하기 위하여, 고객님의 요청시 SMS발송을 위하여 고객님의 정보를 SMS발송업체에 전송할 수 있습니다.
저장되는 정보는 정보제공 목적의 정보로 활용될 수 있으며 그 범위는 아래와 같습니다.
1. 본원의 이벤트,공지사항 알림.
2. 본원의 답변알림
3. 정책변경, 긴급상황시 비상연락망
4. 고객분포 및 성향 등의 마케팅정보 활용
`}
            ></textarea>
            <div>
              <input
                type="checkbox"
                id="t3"
                onChange={(e) => chkChange(e)}
                checked={t3}
              />
              <label htmlFor="t3">제 3자 위탁운영 및 정보제공 약관동의</label>
            </div>
            <div>
              <input type="checkbox" id="chkAll" onChange={chkAll} />
              <label htmlFor="chkAll">약관 전체 동의</label>
            </div>
          </div>
          <button
            type="button"
            className={t1 && t2 && t3 ? "" : "dis"}
            disabled={t1 && t2 && t3 ? false : true}
            onClick={termAccept}
          >
            동의
          </button>
        </Term>
      ) : null}
      {noShowErr ? (
        <Overlay>
          <div className="ovIn">
            <p className="ovMsg">입력하신 정보로 예약할 수 없는 고객입니다. 전화로 문의해 주세요.</p>
            <button
              type="button"
              className="btnCls"
              onClick={resvClose}
            >
              확인
            </button>
          </div>
        </Overlay>
      ) : null}
      {dup ? (
        <Overlay>
          <div className="ovIn">
            <p className="ovMsg">선택하신 날짜에 예약건이 존재합니다.</p>
            <button
              type="button"
              className="btnCls"
              onClick={() => setDup(false)}
            >
              확인
            </button>
          </div>
        </Overlay>
      ) : null}
      {noList ? (
        <Overlay>
          <div className="ovIn">
            <p className="ovMsg">선택하신 날짜에 예약가능한 시간이 없습니다.<br />
            문의전화 주시면 안내 도와드리겠습니다.<br />
            TEL.02-373-0075</p>
            <button
              type="button"
              className="btnCls"
              onClick={() => setNoList(false)}
            >
              확인
            </button>
          </div>
        </Overlay>
      ) : null}
      {termErr ? (
        <TermAlert>
          <div className="ovIn">
            <p className="ovMsg">{ovMsg}</p>
            <button
              type="button"
              className="btnCls"
              onClick={() => setOvActive(false)}
            >
              확인
            </button>
          </div>
        </TermAlert>
      ) : null}
      {finalAlert ? (
        <FinalAlertBox>
          <div className="ovIn">
            <div className="ovMsg">
              <p className="font-20">예약자 명 : {resvName}님</p>
              <p className="font-20">
                전화번호 : {phonePre}-{phoneMid}-{phoneEnd}
              </p>
              <p className="font-20">
                예약일 : {selectedSchDate} {selectedSchTime}
              </p>
              <p className="font-20">진료과목 : {treatNm}</p>
              <p className="font-20">담당의사 : {doctorName}</p>
              {/* <p className="font-20">진료실 : {roomNm}</p> */}
              <p className="fon-20 color-primary text-center mt-20 mb-20">
                위 정보로 예약하시겠습니까? 예약 결과는 카카오톡 또는
                문자메시지로 안내해 드립니다.
              </p>
            </div>
            <div className="d-flex">
              <button
                type="button"
                className="btnAct"
                disabled={finalActionDisabled}
                onClick={() => saveData(false)}
              >
                확인
              </button>
              <button
                type="button"
                className="btnCls"
                onClick={() => setFinalAlert(false)}
              >
                취소
              </button>
            </div>
          </div>
        </FinalAlertBox>
      ) : (
        ""
      )}
      {ovActive ? (
        <Overlay>
          <div className="ovIn">
            <p className="ovMsg">{ovMsg}</p>
            {ovMsg && !ovType ? (
              <>
                <button
                  type="button"
                  className="btnCls"
                  onClick={() => setOvActive(false)}
                >
                  확인
                </button>
              </>
            ) : (
              ""
            )}
            {ovSuccess && ovType ? (
              <>
                <button
                  type="button"
                  className="btnCls"
                  onClick={() => resvComplate()}
                >
                  확인
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        </Overlay>
      ) : (
        ""
      )}
      {showForm ? (
      <>
      <RodalHeader>
        <img src="/img/resvHeaderIcon.svg" alt="" /> 진료 예약
      </RodalHeader>
      <InfoForm
        type="text"
        id="resvName"
        value={resvName}
        onChange={(e) => inputChange(e)}
        placeholder="이름"
        required
        minLength="2"
      />
      <div className="d-flex mt-3 justify-content-between align-items-center">
        <Select
          // isOpen={phonePreDropdownOpen}
          // toggle={phonePreToggle}
          className="dropdownFullWidth"
        >
          <SelectToggle
            className="light"
            type="button"
            onClick={() => preHandler()}
          >
            {phonePre}
          </SelectToggle>
          {preIsActive ? (
            <SelectMenu>
              {phonePreList.map((item) => (
                <SelectItem
                  onClick={() => setPhonePreItem(item.pre)}
                  key={item.id}
                >
                  {item.pre}
                </SelectItem>
              ))}
            </SelectMenu>
          ) : (
            ""
          )}
        </Select>
        <span style={{ marginLeft: "5px", marginRight: "5px" }}>-</span>
        <InfoForm
          type="number"
          id="phoneMid"
          value={phoneMid}
          onChange={(e) => inputChange(e)}
          placeholder="중간자리"
          minLength="4"
          maxLength="4"
          required
        />
        <span style={{ marginLeft: "5px", marginRight: "5px" }}>-</span>
        <InfoForm
          type="number"
          id="phoneEnd"
          value={phoneEnd}
          onChange={(e) => inputChange(e)}
          placeholder="끝자리"
          minLength="4"
          maxLength="4"
          required
        />
      </div>
      <div className="mt-3 mb-3">
        <div className="row">
          <div className="col-6" style={{ paddingRight: "5px" }}>
            <Select
              isOpen={doctorDropdownOpen}
              toggle={doctorToggle}
              className="dropdownFullWidth"
              style={{ marginRight: "5px" }}
            >
              <SelectToggle
                className="light"
                type="button"
                onClick={() => doctorHandler()}
              >
                {selectedDocotrId ? doctorName : "의사를 선택하세요."}
              </SelectToggle>
              {doctorIsActive ? (
                <SelectMenu>
                  {doctor.map((item) => (
                    <SelectItem
                      onClick={() => setselectedDoctor(item.id, item.name)}
                      key={item.id}
                    >
                      {item.name}
                    </SelectItem>
                  ))}
                </SelectMenu>
              ) : (
                ""
              )}
            </Select>
          </div>
          <div className="col-6" style={{ paddingLeft: "5px" }}>
            <Select
              // isOpen={treatDropdownOepn}
              // toggle={treatToggle}
              className="dropdownFullWidth treatList"
              style={{ marginLeft: "5px" }}
            >
              <SelectToggle
                className="light"
                type="button"
                onClick={() => treatHandler()}
              >
                {selectedTreatId ? treatNm : "진료과목 선택"}
              </SelectToggle>
              {treatIsActive ? (
                <SelectMenu className="reverse">
                  {treatList.map((item) => (
                    <SelectItem
                      onClick={() => setTreatItem(item.id, item.treatNm)}
                      key={item.id}
                    >
                      {item.treatNm}
                    </SelectItem>
                  ))}
                </SelectMenu>
              ) : (
                ""
              )}
            </Select>
          </div>
        </div>
      </div>
      {resvName && phonePre && phoneMid && phoneEnd && selectedDocotrId && selectedTreatId ? (
        <div className="mb-20">
          <DatePicker
            inline
            // includeDates={schDateList}
            minDate={subDays(new Date(), 0)}
            // maxDate={oneWeeksFromNow}
            onChange={handleDateRange}
            filterDate={isWeekday}
            locale="ko"
          />
        </div>
      ) : null}

      {selectedSchDate ? (
            <p className="h3 mt-5 mb-5 text-center">선택하신 날짜는 <span className="color-primary">{moment(new Date(selectedSchDate)).format('yyyy년 MM월 DD일')}</span> 입니다.</p>
      ) : null}
      {resvName && phonePre && phoneMid && phoneEnd && selectedDocotrId && selectedTreatId && selectedSchDate ? (
        <div className="d-flex mt-3 mb-20 justify-content-between">
          <Select
            // isOpen={timeListDropdownOepn}
            // toggle={timeListToggle}
            className="dropdownFullWidth"
          >
            <SelectToggle
              className="light"
              type="button"
              onClick={() => timeHandler()}
            >
              {selectedSchTime ? selectedSchTime : "예약시간 선택"}
            </SelectToggle>
            {timeIsActive ? (
              <SelectMenu className="reverse2">
                {schTimeList.map((item, index) => (
                  <SelectItem onClick={() => setTimeItem(item)} key={index}>
                    {item}
                  </SelectItem>
                ))}
              </SelectMenu>
            ) : (
              ""
            )}
          </Select>
        </div>
      ) : null}
      <BtnSubmit
        type="submit"
        className={
          resvName &&
          phonePre &&
          phoneMid &&
          phoneEnd &&
          selectedDocotrId &&
          schDate &&
          selectedTreatId &&
          selectedSchTime
            ? ""
            : "dis"
        }
        onClick={() => formSubmit()}
        disabled={
          resvName &&
          phonePre &&
          phoneMid &&
          phoneEnd &&
          selectedDocotrId &&
          schDate &&
          selectedTreatId &&
          selectedSchTime
            ? false
            : true
        }
      >
        예약하기
      </BtnSubmit>
      </>
      ) : null}
    </Rodal>
  );
}

export default ResvRodal;
