import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

const Centered = styled.div`
  padding-top: var(--size-200px);
  padding-bottom: var(--size-100px);
  .d-flex {
    overflow-x: auto;
  }
  .flexItem {
    padding: var(--size-10px);
  }
  .colBox {
    border: 2px solid var(--color-link);
    padding: var(--size-30px);
    min-height: 350px;
  }
  @media (max-width: 767px) {
    .imgPt {
      max-width: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .d-flex {
      margin-left: -15px;
      margin-right: -15px;
    }
    .flexItem img {
      width: 200px;
    }
  }
`;

function About1({ history, props }) {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>병원소개</title>
      </Helmet>
      <div className="topBar"></div>
      <div className="sv">
        <Centered className="wrapper">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <Flip left cascade>
                <img
                  src="/img/about/about1.png"
                  alt=""
                  className="img-fluid mb-50 imgPt"
                />
              </Flip>
            </div>
            <div className="col-md-6 col-lg-8">
              <Fade right>
                <h1 className="font-45 color-primary xs-to-center mb-60">
                  병원소개
                </h1>
                <p className="font-24 mb-60 wordKeep">
                  <strong>서울 서북부 최고 시설, 최다 수술, 최대 병원</strong>{" "}
                  규모의 이비인후과로서 풍부한 임상 경험과 수술 경험, 최신 의료
                  장비로 최고의 환자 별 맞춤 의료 서비스를 약속드립니다.
                  <br />
                  <br />
                  여기에 진심을 담아 친절한 치료로 환자분들께 다가 가겠습니다.
                  <br />
                  <br />
                  감사합니다.
                  <br />
                  <br />
                  DMC 하나이비인후과의원 <strong>원장 최 환</strong>
                </p>
              </Fade>
              <div className="row no-gutters text-center">
                <Flip left cascade>
                  <div className="col-md-4" style={{ marginBottom: "20px" }}>
                    <img
                      src="/img/about/about1-1-1.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-md-4" style={{ marginBottom: "20px" }}>
                    <img
                      src="/img/about/about1-1-2.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-md-4" style={{ marginBottom: "20px" }}>
                    <img
                      src="/img/about/about1-1-3.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </Flip>
              </div>
            </div>
          </div>
        </Centered>
      </div>
    </>
  );
}

export default About1;
