/**
 * React 페이지 필수
 * useEffect : 문서 로딩이 완료되면 실행할 내용
 * useState : 상태 변경
 */
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  osName,
  osVersion,
  browserName,
  deviceType,
  isMobile,
  isTablet,
  isWindows,
  isMacOs,
} from "react-device-detect";

// 유틸리티
import { history, instance } from "./_helpers";
import { alertActions } from "./_actions";

// 레이아웃
import NormalLayout from "./layouts/NormalLayout";
import SubLayout from "./layouts/SubLayout";

// 페이지
import Index from "./pages/index";
import About1 from "./pages/about/About1";
import About2 from "./pages/about/About2";
import About3 from "./pages/about/About3";
import About4 from "./pages/about/About4";
import About5 from "./pages/about/About5";
import About6 from "./pages/about/About6";
import About7 from "./pages/about/About7";
import About8 from "./pages/about/About8";
import Nose1 from "./pages/nose/Nose1";
import Nose2 from "./pages/nose/Nose2";
import Nose3 from "./pages/nose/Nose3";
import Ear1 from "./pages/ear/Ear1";
import Ear2 from "./pages/ear/Ear2";
import Ear3 from "./pages/ear/Ear3";
import Ear4 from "./pages/ear/Ear4";
import Neck1 from "./pages/neck/Neck1";
import Neck2 from "./pages/neck/Neck2";
import Neck3 from "./pages/neck/Neck3";
import Clean from "./pages/clean/Clean";
import Clean2 from "./pages/clean/Clean2";
import Vaccination from "./pages/vaccination/Vaccination";
import Event from "./pages/board/Event";
import EventView from "./pages/board/EventView";
import Cancel from "./pages/cancel/cancel";

import Moment from "moment";

function App() {
  // 프로젝트 전체에 사용할 alert 창
  const alert = useSelector((state) => state.alert);
  // alert 메시지 전파
  const dispatch = useDispatch();
  // alert창 열기 (추후 구현)
  const [open, setOpen] = useState(true);

  // alert창 닫기 (추후 구현)
  const handleAlertClose = () => {
    dispatch(alertActions.clear());
  };

  const saveVisitInfo = () => {
    const visitInfoRequest = {
      osName,
      osVersion,
      browserName,
      deviceType,
      isMobile,
      isTablet,
      isWindows,
      isMacos: isMacOs,
    };
    console.log(visitInfoRequest);
    instance.post(`/api/front/saveConnectInfo`, visitInfoRequest);
  };

  useEffect(() => {
    // 오늘 날짜
    Moment.locale("ko");
    const todayStr = Moment(new Date()).format("yyyy-MM-DD");

    //localStorage.removeItem("logInfo");

    const logStorage = localStorage.getItem("logInfo");

    // 정보가 없으면 바로 저장
    if (!logStorage || logStorage !== todayStr) {
      localStorage.setItem("logInfo", todayStr);
      saveVisitInfo();
    }

    history.listen((location, action) => {
      // location 바뀔때마다 alert 초기화
      dispatch(alertActions.clear());
    });
  }, []);

  return (
    <>
      {alert.message && <div>alert 메시지 : {alert.message}</div>}
      <Router>
        <Route
          render={({ location }) => {
            return (
              <Switch location={location}>
                <Route path={["/"]} exact>
                  <NormalLayout>
                    <Route path="/" exact component={Index} />
                  </NormalLayout>
                </Route>
                <Route
                  path={[
                    "/nose",
                    "/ear",
                    "/neck",
                    "/clean",
                    "/about",
                    "/vaccination",
                    "/board/event",
                    "/cancel",
                  ]}
                >
                  <SubLayout path={["/nose/nose1"]}>
                    <Route path="/nose/nose1" exact component={Nose1} />
                    <Route path="/nose/nose2" exact component={Nose2} />
                    <Route path="/nose/nose3" exact component={Nose3} />
                    <Route path="/ear/ear1" exact component={Ear1} />
                    <Route path="/ear/ear2" exact component={Ear2} />
                    <Route path="/ear/ear3" exact component={Ear3} />
                    <Route path="/ear/ear4" exact component={Ear4} />
                    <Route path="/neck/neck1" exact component={Neck1} />
                    <Route path="/neck/neck2" exact component={Neck2} />
                    <Route path="/neck/neck3" exact component={Neck3} />
                    <Route path="/clean/clean1" exact component={Clean} />
                    <Route path="/clean/clean2" exact component={Clean2} />
                    <Route path="/about/about1" exact component={About1} />
                    <Route path="/about/about2" exact component={About2} />
                    <Route path="/about/about3" exact component={About3} />
                    <Route path="/about/about4" exact component={About4} />
                    <Route path="/about/about5" exact component={About5} />
                    <Route path="/about/about6" exact component={About6} />
                    <Route path="/about/about7" exact component={About7} />
                    <Route path="/about/about8" exact component={About8} />
                    <Route
                      path="/vaccination/vaccination"
                      exact
                      component={Vaccination}
                    />
                    <Route path="/board/event" exact component={Event} />
                    <Route
                      path="/board/event/:id"
                      exact
                      component={EventView}
                    />
                    <Route path="/cancel/:token"  component={Cancel} />
                  </SubLayout>
                </Route>
              </Switch>
            );
          }}
        />
      </Router>
    </>
  );
}

export default App;
