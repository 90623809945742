import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

// import { Parallax } from "react-scroll-parallax";
// import Image from "../../components/Image";

import { Parallax } from "react-parallax";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

import ResvRodal from "../../components/resvRodal";

const PrimaryBox = styled.div`
  background: var(--color-link);
  color: #fff;
  font-size: var(--size-55px);
  text-align: center;
`;

const SubDiv = styled.div`
  position: relative;
  .subDiv {
    position: relative;
    padding-left: 35px;
  }
`;

const SBox = styled.div`
  position: relative;
  padding: var(--size-25px) var(--size-20px);
  background: #f2f5f7;
  margin-right: 7.5px;
  margin-bottom: 7.5px;
  width: 100%;
  font-weight: 500;
  .txt {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: var(--size-180px);
  }
`;

const Circle = styled.div`
  width: var(--size-120px);
  height: var(--size-120px);
  background: #fff;
  color: var(--color-link);
  border: 3px solid var(--color-link);
  text-align: center;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`;

function Ear3({ history, props }) {
  const dispatch = useDispatch();
  // 마우스 위치 기반 자동 가로 스크롤
  const horizontalScrollRef = useRef(null);
  const mX = useRef(0);
  const mX2 = useRef(0);
  const posX = useRef(0);
  const mPadd = 60;
  const damp = 20;
  // 요기까지
  const [visibleResv, setVisibleResv] = useState(false);
  const [defaultTreatId, setDefaultTreatId] = useState(0);
  const [defaultTreatNm, setDefaultTreatNm] = useState(0);

  // const handleResvDefaultValues = (id, tnm) => {
  //   if (id > 0 && tnm) {
  //     setDefaultTreatId(id);
  //     setDefaultTreatNm(tnm);
  //     setVisibleResv(true);
  //     console.log(id);
  //     console.log(tnm);
  //   }
  // };

  const handleResvDefaultValues = () => {
    setVisibleResv(true);
  };

  // 예약완료
  const resvComplate = () => {
    setVisibleResv(false);
  };

  const resvClose = () => {
    setVisibleResv(!resvClose);
  };

  // scroll 버튼 처리
  const scrollDown = () => {
    const n = document.documentElement.clientHeight;
    window.scrollBy({ top: n, left: 0, behavior: "smooth" });
  };

  // 자동 스크롤 리스너
  const horizontalScroll = (e) => {
    if (horizontalScrollRef.current) {
      const container = horizontalScrollRef.current;
      const parent = container.parentElement;
      const galW = container.offsetWidth;
      const galSW = container.scrollWidth;
      const wDiff = galSW / galW - 1; // widths difference ratio
      const mmAA = galW - mPadd * 2; // the mousemove available area
      const mmAAr = galW / mmAA;

      if (parent) {
        mX.current =
          e.pageX - parent.getBoundingClientRect().left - container.offsetLeft;

        mX2.current = Math.min(Math.max(0, mX.current - mPadd), mmAA) * mmAAr;
      }

      posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"

      setInterval(function () {
        posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"
        container.scrollLeft = posX.current * wDiff;
      }, 40);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>보청기 클리닉</title>
      </Helmet>
      {visibleResv ? (
        <ResvRodal
          visibleResv={visibleResv}
          resvClose={resvClose}
          resvComplate={resvComplate}
          defaultTreatId={defaultTreatId}
          defaultTreatNm={defaultTreatNm}
        />
      ) : (
        ""
      )}
      <div className="topBar"></div>
      <div className="sv">
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={"/img/ear/ear3.png"}
          bgImageAlt=""
          strength={-300}
        >
          <div className="container">
            <Slide up>
              <div className="parallaxExplain btm">
                <div className="top">
                  <h1 className="pgTitle">보청기 클리닉</h1>
                  <p className="pgTitleExp22 mb-30">
                    전문 보청기 인력과 원하는 시간에 자세한 상담을 받을 수
                    있습니다.
                  </p>
                  <p className="pgTitleExp22 mt-30">
                    <img src="/img/chk.svg" className="mr-20" alt="" />
                    <strong>청각장애 보청기 보조금 가능</strong>
                  </p>
                  <p className="pgTitleExp22 mt-10">
                    <img src="/img/chk.svg" className="mr-20" alt="" />
                    <strong>청각장애 복지카드 소지 시 5년에 1회 지원금</strong>
                  </p>
                </div>
                <div className="mid">
                  <Row noGutters>
                    <Col>
                      <button
                        type="button"
                        className="btnNoStyle"
                        // onClick={(e) =>
                        //   handleResvDefaultValues(9, "보청기 상담")
                        // }
                        onClick={(e) => handleResvDefaultValues()}
                      >
                        <img src="/img/ico-chart.svg" alt="" />
                        <span>예약하기</span>
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Slide>
          </div>
          <div style={{ height: "100vh" }} />
          <button
            type="button"
            className="btnScrollDown"
            onClick={() => scrollDown()}
          >
            <img src="/img/scrollDown.svg" alt="" />
          </button>
        </Parallax>
        <div className="wrapper mt-50 mb-50">
          <div className="row">
            <Slide bottom cascade>
              <div className="col-md-3">
                <h1 className="font-45 color-primary xs-to-center mb-100">
                  보청기 지원:
                </h1>
              </div>
            </Slide>
            <div className="col-md-9">
              <div className="row">
                <Slide bottom cascade>
                  <div
                    className="col-md-6"
                    style={{
                      background: "#dff9fb",
                      flexWrap: "wrap",
                      backgroundClip: "content-box",
                    }}
                  >
                    <SubDiv
                      className="item text-left xs-mb-100"
                      style={{ padding: "30px" }}
                    >
                      <h1>
                        <img src="/img/ico-plus.svg" className="mr-15" alt="" />
                        <strong>일반청각장애등록자</strong>
                      </h1>
                      <ul className="list-unstyled font-24">
                        <li>
                          <img src="/img/chk-xs.svg" className="mr-15" alt="" />{" "}
                          <strong>처음 구매 시</strong>
                          <div className="subDiv mb-50">
                            1,310,000원에서 자기부담금 10%를 제한
                            <br />
                            1,179,000원 중 99만 9천원 지원
                          </div>
                        </li>
                        <li>
                          <img src="/img/chk-xs.svg" className="mr-15" alt="" />{" "}
                          <strong>보청기 구매 2년 후부터 매년</strong>
                          <div className="subDiv mb-50">
                            4만 5천원 지원, 총 4년간 18만원
                          </div>
                        </li>
                        <li>
                          <img src="/img/chk-xs.svg" className="mr-15" alt="" />{" "}
                          <strong>총 지원금 1,179,000원</strong>
                          <div className="subDiv mb-50">
                            초기 99만 9천원 + 이후 4년간 18만원
                          </div>
                        </li>
                      </ul>
                    </SubDiv>
                  </div>
                  <div
                    className="col-md-6"
                    style={{
                      background: "#c7ecee",
                      flexWrap: "wrap",
                      backgroundClip: "content-box",
                    }}
                  >
                    <SubDiv
                      className="item text-left"
                      style={{ padding: "30px" }}
                    >
                      <h1>
                        <img src="/img/ico-plus.svg" className="mr-15" alt="" />
                        <strong>차상위/기초생활수급자</strong>
                      </h1>
                      <ul className="list-unstyled font-24">
                        <li>
                          <img src="/img/chk-xs.svg" className="mr-15" alt="" />{" "}
                          <strong>처음 구매 시</strong>
                          <div className="subDiv mb-50">
                            1,310,000원 중 1,110,000원 지원
                          </div>
                        </li>
                        <li>
                          <img src="/img/chk-xs.svg" className="mr-15" alt="" />{" "}
                          <strong>보청기 구매 2년 후부터 매년</strong>
                          <div className="subDiv mb-50">
                            5만원 지원, 총 4년간 20만원
                          </div>
                        </li>
                        <li>
                          <img src="/img/chk-xs.svg" className="mr-15" alt="" />{" "}
                          <strong>총 지원금 1,310,000원</strong>
                          <div className="subDiv mb-50">
                            초기 111만원 + 이후 4년간 20만원
                          </div>
                        </li>
                      </ul>
                    </SubDiv>
                  </div>
                </Slide>
              </div>
              <SubDiv className="item text-left">
                <Flip left cascade>
                  <h1 className="mt-50">
                    <img src="/img/ico-plus.svg" className="mr-15" alt="" />
                    <strong>
                      15세 이하 아동의 경우{" "}
                      <span className="color-primary">최대 262만원 지원</span>
                    </strong>
                  </h1>
                  <p className="font-24 color-primary mt-50 mb-30">
                    하기의 4가지 사항에 충족될 시 양측 지원
                    가능(131만원X2개=262만원)
                  </p>
                  <div className="d-flex justify-content-around">
                    <SBox className="font-24">
                      <Circle>80dB</Circle>
                      <div className="txt">
                        두 귀의 청력 손실이 모두 80dB 미만
                      </div>
                    </SBox>
                    <SBox className="font-24">
                      <Circle>50%</Circle>
                      <div className="txt">두 귀의 어음 명료도 50% 이상</div>
                    </SBox>
                  </div>
                  <div className="d-flex justify-content-around">
                    <SBox className="font-24">
                      <Circle>15dB</Circle>
                      <div className="txt">
                        두 귀의 순음 청력 역치 차이15dB 이하
                      </div>
                    </SBox>
                    <SBox className="font-24">
                      <Circle>20%</Circle>
                      <div className="txt">
                        두 귀의 어음 명료도 차이가 20% 이상
                      </div>
                    </SBox>
                  </div>
                </Flip>
              </SubDiv>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ear3;
