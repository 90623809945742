import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

// import { Parallax } from "react-scroll-parallax";
// import Image from "../../components/Image";

import { Parallax } from "react-parallax";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";

import ResvRodal from "../../components/resvRodal";

import "./nose1.css";

const TitleBullet = styled.h1`
  position: relative;
  font-size: var(--size-36px);
  margin-bottom: var(--size-50px);
  & > span {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: -2px;
      left: 0;
      width: var(--size-6px);
      height: var(--size-6px);
      display: block;
      border-radius: 100%;
      background: #0076be;
    }
  }
  @media (max-width: 720px) {
    font-size: var(--size-45px);
  }
`;

const Circle = styled.div`
  width: 21rem;
  height: 21rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  text-align: center;
  background: #fff;
  color: var(--color-link);
  font-size: var(--size-24px);
  @media (max-width: 767px) {
    width: 28rem;
    height: 28rem;
    font-size: var(--size-36px);
  }
`;

const CircleBtm = styled.p`
  font-size: var(--size-25px);
  margin-top: var(--size-50px);
  @media (max-width: 720px) {
    font-size: var(--size-40px);
  }
`;

const ListBottomContainer = styled.div`
  padding: var(--size-40px);
  color: var(--color-link);
  border: 3px solid var(--color-link);
  text-align: center;
  margin-left: 400px;
  margin-right: 50px;
  margin-bottom: var(--size-100px);
  @media (max-width: 720px) {
    margin-left: var(--size-20px);
    margin-right: var(--size-20px);
  }
`;

const WhiteBox = styled.div`
  border-radius: 5px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  padding: var(--size-60px);
`;

const Ccl1 = styled.div`
  width: 40rem;
  height: 40rem;
  font-size: 2.4rem;
  border-radius: 100%;
  overflow: hidden;
  border: 2px dashed var(--color-link);
  color: var(--color-link);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  text-align: center;
  padding: var(--size-50px);
  span {
    color: var(--color-text);
  }
  @media (max-width: 767px) {
    width: 30rem;
    height: 30rem;
    padding: var(--size-20px);
  }
`;
const Ccl2 = styled.div`
  width: 40rem;
  height: 40rem;
  font-size: 2.4rem;
  border-radius: 100%;
  overflow: hidden;
  border: 2px solid var(--color-link);
  color: var(--color-link);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  text-align: center;
  padding: var(--size-50px);
  span {
    color: var(--color-text);
  }
  @media (max-width: 767px) {
    width: 30rem;
    height: 30rem;
    padding: var(--size-20px);
  }
`;

const Ccl3 = styled.div`
  width: 40rem;
  height: 40rem;
  font-size: 2.4rem;
  border-radius: 100%;
  overflow: hidden;
  border: 2px solid var(--color-link);
  background: var(--color-link);
  color: #fff;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  text-align: center;
  padding: var(--size-50px);
  span {
    color: #fff;
  }
  @media (max-width: 767px) {
    width: 30rem;
    height: 30rem;
    padding: var(--size-20px);
  }
`;

function Nose2({ history, props }) {
  const dispatch = useDispatch();
  // 마우스 위치 기반 자동 가로 스크롤
  const horizontalScrollRef = useRef(null);
  const mX = useRef(0);
  const mX2 = useRef(0);
  const posX = useRef(0);
  const mPadd = 60;
  const damp = 20;
  const [visibleResv, setVisibleResv] = useState(false);
  const [tabNum, setTabNum] = useState(0);
  const [defaultTreatId, setDefaultTreatId] = useState(0);
  const [defaultTreatNm, setDefaultTreatNm] = useState(0);

  // const handleResvDefaultValues = (id, tnm) => {
  //   if (id > 0 && tnm) {
  //     setDefaultTreatId(id);
  //     setDefaultTreatNm(tnm);
  //     setVisibleResv(true);
  //     console.log(id);
  //     console.log(tnm);
  //   }
  // };

  const handleResvDefaultValues = () => {
    setVisibleResv(true);
  };

  // 예약완료
  const resvComplate = () => {
    setVisibleResv(false);
  };

  const resvClose = () => {
    setVisibleResv(!resvClose);
  };

  // 자동 스크롤 리스너
  const horizontalScroll = (e) => {
    if (horizontalScrollRef.current) {
      const container = horizontalScrollRef.current;
      const parent = container.parentElement;
      const galW = container.offsetWidth;
      const galSW = container.scrollWidth;
      const wDiff = galSW / galW - 1; // widths difference ratio
      const mmAA = galW - mPadd * 2; // the mousemove available area
      const mmAAr = galW / mmAA;

      if (parent) {
        mX.current =
          e.pageX - parent.getBoundingClientRect().left - container.offsetLeft;

        mX2.current = Math.min(Math.max(0, mX.current - mPadd), mmAA) * mmAAr;
      }

      posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"

      setInterval(function () {
        posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"
        container.scrollLeft = posX.current * wDiff;
      }, 40);
    }
  };

  // scroll 버튼 처리
  const scrollDown = () => {
    const n = document.documentElement.clientHeight;
    window.scrollBy({ top: n, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>알레르기 클리닉</title>
      </Helmet>
      {visibleResv ? (
        <ResvRodal
          visibleResv={visibleResv}
          resvClose={resvClose}
          resvComplate={resvComplate}
          defaultTreatId={defaultTreatId}
          defaultTreatNm={defaultTreatNm}
        />
      ) : (
        ""
      )}
      <div className="topBar"></div>
      <div className="sv">
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={"/img/nose/nose2.png"}
          bgImageAlt=""
          strength={-300}
        >
          <div className="container">
            <Slide up>
              <div className="parallaxExplain btm">
                <div className="top">
                  <h1 className="pgTitle">알레르기 클리닉</h1>
                  <p className="pgTitleExp mb-20">
                    맑은 콧물, 재채기, 가려움, 코막힘 ! 지속적 관리와 치료가
                    필요한 알레르기 DMC하나 에서 체계적인 치료를 받아보세요
                  </p>
                </div>
                <div className="mid">
                  <Row noGutters>
                    <Col>
                      <button
                        type="button"
                        className="btnNoStyle"
                        // onClick={(e) =>
                        //   handleResvDefaultValues(16, "알레르기 클리닉")
                        // }
                        onClick={(e) => handleResvDefaultValues()}
                      >
                        <img src="/img/ico-chart.svg" alt="" />
                        <span>예약하기</span>
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Slide>
          </div>
          <div style={{ height: "100vh" }} />
          <button
            type="button"
            className="btnScrollDown"
            onClick={() => scrollDown()}
          >
            <img src="/img/scrollDown.svg" alt="" />
          </button>
        </Parallax>
        <div className="container">
          <div className="tabHeader mt-100 mb-100">
            <Slide right>
              <ul className="d-flex justify-content-between align-items-center">
                <li>
                  <button
                    type="button"
                    className={tabNum === 0 ? "active" : ""}
                    onClick={() => setTabNum(0)}
                  >
                    알레르기와 알레르기 비염
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={tabNum === 1 ? "active" : ""}
                    onClick={() => setTabNum(1)}
                  >
                    알레르기 검사의 종류
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={tabNum === 2 ? "active" : ""}
                    onClick={() => setTabNum(2)}
                  >
                    알레르기의 치료
                  </button>
                </li>
              </ul>
            </Slide>
          </div>
        </div>
        <div className="tabContent mt-100">
          <div className={tabNum === 0 ? "tab active" : "tab"}>
            <div className="wrapper">
              <div className="row mb-50">
                <div className="col-md-4">
                  <Slide left>
                    <h1 className="font-45 wordKeep color-primary xs-to-center mb-100">
                      알레르기란?
                    </h1>
                  </Slide>
                </div>
                <div className="col-md-8">
                  <Slide right>
                    <p className="font-24">
                      외부물질에 대해 자기 신체의 방어기전, 즉 면역이 과도하게
                      <strong>과민 반응</strong> 하거나{" "}
                      <strong>변형된 반응</strong>을 일으키는 것을 말합니다.
                      이러한 이상과민반응이 아토피, 비염, 천식 등을 발현되는
                      것입니다.
                      <br />
                      알레르기는 유전적 요인과 환경적 요인이 복합적으로 작용하는
                      다인자요인(multi-factorial disease) 에 의해 발병되는
                      것으로 알려져 있습니다.
                    </p>
                  </Slide>
                </div>
              </div>
              <div className="row mb-50">
                <div className="col-md-4">
                  <Slide left>
                    <h1 className="font-45 wordKeep color-primary xs-to-center mb-100">
                      알레르기 왜!
                      <br />
                      생기나요?
                    </h1>
                  </Slide>
                </div>
                <div className="col-md-8">
                  <Slide right casecade>
                    <p className="font-24 mb-20">
                      알레르기 질환은 하나의 알러젠(allergen)에 의해서만
                      발생하지 않습니다.
                    </p>
                    <ul className="list-pre mb-20">
                      <li>
                        <strong>유전적 요인</strong>
                      </li>
                      <li>
                        <strong>알러젠(allergen)의 노출 정도</strong>
                      </li>
                      <li>
                        <strong>환경적 요인</strong>
                      </li>
                      <li>
                        <strong>Life style에 의한 증상</strong>
                      </li>
                    </ul>
                    <p className="font-24">
                      여러요인이 있으며 알러젠은 집먼지 진드기, 꽃가루(봄철:
                      수목화분, 초여름: 목초화분, 가을: 잡초화분), 곰팡이, 동물
                      비듬·털, 바퀴벌레, 음식물·음식물 첨가제, 약물 등 다양하게
                      존재합니다.
                    </p>
                    <div className="d-flex justify-content-between text-center mt-50 mb-20">
                      <div className="">
                        <img
                          src="/img/nose/nose2-1.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="">
                        <img
                          src="/img/nose/nose2-2.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="">
                        <img
                          src="/img/nose/nose2-3.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="">
                        <img
                          src="/img/nose/nose2-4.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="">
                        <img
                          src="/img/nose/nose2-5.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </Slide>
                </div>
              </div>
            </div>
          </div>
          <div className={tabNum === 1 ? "tab active" : "tab"}>
            <div className="wrapper">
              <div className="row mb-50">
                <div className="col-md-4">
                  <Slide left>
                    <h1 className="font-45 wordKeep color-primary xs-to-center mb-100">
                      알레르기 검사
                      <br />왜 해야 하나요?
                    </h1>
                  </Slide>
                </div>
                <div className="col-md-8">
                  <Slide right>
                    <p className="font-24">
                      알레르기 치료의 기본은 원인물질을 차단 하는 것으로 정확한
                      검사가 중요합니다. 정확한 검사를 통한 치료는 약물의
                      오남용을 막고 쳬계적 치료 계획 수립할 수 있습니다.
                      영유아기의 아토피피부염이 성장하면서 비염, 천식으로
                      진행되는 것을 예방하고 면역치료의 적응증 시 면역치료가
                      가능합니다.
                    </p>
                  </Slide>
                </div>
              </div>
            </div>
            <div className="blue-row">
              <div className="wrapper">
                <div className="row mb-50">
                  <div className="col-md-4">
                    <Slide left>
                      <h1 className="bar-text font-24">
                        진단과 검사는
                        <br />
                        어떻게 진행되나요?
                      </h1>
                    </Slide>
                  </div>
                  <div className="col-md-8">
                    <Slide right casecade>
                      <img
                        src="/img/nose/nose2-6.png"
                        className="img-fluid"
                        alt=""
                      />
                      <p className="font-24 mt-20 mb-50">
                        알레르기 질환을 판단하는 기본적 검사 중 하나입니다. 주의
                        사항은 검사 전 약 1주일간은 항히스타민제 (흔히 콧물약)를
                        복용해서는 않됩니다. 검사 후 15분 후에 결과를 바로 알 수
                        있는 장점이 있습니다.
                        <br />
                        검사 후에는 최소한 24시간은 목욕을 하거나 검사 부위에
                        물이 닿지 않도록 해야 합니다.
                      </p>
                      <table className="tbl">
                        <colgroup>
                          <col />
                          <col />
                          <col />
                        </colgroup>
                        <thead className="text-center">
                          <tr>
                            <th>검사 종류</th>
                            <th>방법</th>
                            <th>검사 소요 시간</th>
                          </tr>
                        </thead>
                        <tbody style={{ background: "#fff" }}>
                          <tr>
                            <td className="text-center">
                              피내반응시험
                              <br />
                              (Intradermal test)
                            </td>
                            <td>
                              <ul className="list-pre mb-20">
                                <li>팔꿈치 아래 앞면이나 측면에 시행</li>
                                <li>
                                  일회용 주사기를 사용하여 피부에 직경 3~4mm
                                  정도의 융기가 생기도록 검사용액을 주사합니다.
                                </li>
                                <li>
                                  히스타민용액(양성 대조군), 생리식염수(음성
                                  대조군)를 함께 시행
                                </li>
                              </ul>
                            </td>
                            <td className="text-center">
                              5분이내
                              <br />
                              (15~30분 뒤에 판독)
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">
                              단자시험
                              <br />
                              (Prick test)
                            </td>
                            <td>
                              <ul className="list-pre mb-20">
                                <li>등이나 팔의 앞면에 시행</li>
                                <li>
                                  알레르겐 시약을 3~5cm간격으로 한방울씩
                                  떨어뜨린 후, 아주 가는 주사바늘이나 침으로
                                  피부표면을 가볍게 찔러 검사함
                                </li>
                                <li>
                                  히스타민용액(양성 대조군), 생리식염수 (음성
                                  대조군)를 함께 시행
                                </li>
                              </ul>
                            </td>
                            <td>
                              5분이내
                              <br />
                              (5~20분 뒤에 판독)
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">
                              소파시험
                              <br />
                              (Scratch test)
                            </td>
                            <td>
                              <ul className="list-pre mb-20">
                                <li>
                                  단자시험과 동일한 원리, 좀 더 자세한 검사
                                </li>
                                <li>
                                  피부를 찌르지 않고 검사시약을 놓은 후 출혈되지
                                  않을 정도로 긁어서 검사
                                </li>
                                <li>
                                  검사의 정확성이 떨어지는 편이라 잘 사용하지
                                  않음
                                </li>
                              </ul>
                            </td>
                            <td className="text-center"></td>
                          </tr>
                        </tbody>
                      </table>
                    </Slide>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <Slide left>
                      <h1 className="bar-text font-24">혈액검사</h1>
                    </Slide>
                  </div>
                  <div className="col-md-8">
                    <Slide right cascade>
                      <img
                        src="/img/nose/nose2-7.png"
                        alt=""
                        className="img-fluid"
                      />
                      <ul className="list-pre mb-20 mt-20">
                        <li>
                          {" "}
                          <strong>
                            MAST(Multiple Allergen Simultaneous test)
                          </strong>{" "}
                          <br />
                          FOOD and INHALANT (음식, 흡입 알레르기 물질 등 ) 총 91
                          종 IgE 검사
                        </li>
                        <li>
                          <strong>IgG4 Food Antibodies 90종</strong>
                        </li>
                        <li>
                          <strong>Phadiatop Infant/ Phadiatop</strong>
                        </li>
                      </ul>
                      <p className="font-24 color-primary">
                        <strong>
                          MAST -Multiple Allergen Simultaneous test 란?
                        </strong>
                      </p>
                      <p className="font-24 mb-50">
                        한번의 혈액 채취를 통해 음식 알레르기 91종 호흡알레르기
                        62종을 동시에 빠른 시간 안에 검사 할 수 있습니다.
                        항히스타민제와 항알레르기를 복용한 경우에도 약물 복용의
                        영향을 받지 않아 객관적이고 정확한 검사 결과를 얻을 수
                        있습니다. 피부 반응 검사에서 나타날 수 있는 쇼크의
                        위험이 없어 소아에게도 안전하게 시행할 수 있는 장점이
                        있습니다.
                      </p>
                      <table className="tbl text-center mb-50">
                        <colgroup>
                          <col />
                          <col />
                          <col />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>검사 종류</th>
                            <th>방법</th>
                            <th>검사 소요 시간</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <strong>MAST</strong>
                            </td>
                            <td>채혈 (1회)</td>
                            <td>
                              5분이내
                              <br />
                              (판독 ~ 약 3일 소요)
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p className="font-24">
                        <strong>IgG4 Food Antibodies(90종/Korean panel)</strong>
                      </p>
                      <p className="font-24 mb-50">
                        음식 알레르기는 면역학적인 반응을 근거로 신체내 증상이
                        나타납니다. 음식알레르기는 먹고 난 후 바로 알레르기
                        반응이 일어나는 즉시형 과민 반응과 상당한 시간이지나
                        나타나는 지연형 과민반응 2가지가 있습니다. 이 지연형
                        과민반응은 음식 섭취 후 상당한 시간이 지나 체내에 유발
                        음식들이 축적되기 때문에 찾아 내기가 어렵습니다.
                      </p>
                      <p className="font-24 mb-20">
                        지연성 과민 반응 음식알레르기 증상
                      </p>
                    </Slide>
                    <div
                      className="gridContainer grid4-1 text-center"
                      ref={horizontalScrollRef}
                      onMouseMove={(e) => horizontalScroll(e)}
                    >
                      <div className="gridCol">
                        <img
                          src="/img/nose/nose2-8.png"
                          alt=""
                          className="img-fluid mb-30"
                        />
                        <p className="fon-20">
                          <strong>염증성 대장 질환</strong>
                        </p>
                      </div>
                      <div className="gridCol">
                        <img
                          src="/img/nose/nose2-9.png"
                          alt=""
                          className="img-fluid mb-30"
                        />
                        <p className="fon-20">
                          <strong>복부팽만</strong>
                        </p>
                      </div>
                      <div className="gridCol">
                        <img
                          src="/img/nose/nose2-10.png"
                          alt=""
                          className="img-fluid mb-30"
                        />
                        <p className="fon-20">
                          <strong>가스로 인한 더부룩함</strong>
                        </p>
                      </div>
                      <div className="gridCol">
                        <img
                          src="/img/nose/nose2-11.png"
                          alt=""
                          className="img-fluid mb-30"
                        />
                        <p className="fon-20">
                          <strong>기분의 두드러진 변화</strong>
                        </p>
                      </div>
                      <div className="gridCol">
                        <img
                          src="/img/nose/nose2-12.png"
                          alt=""
                          className="img-fluid mb-30"
                        />
                        <p className="fon-20">
                          <strong>두통</strong>
                        </p>
                      </div>
                      <div className="gridCol">
                        <img
                          src="/img/nose/nose2-13.png"
                          alt=""
                          className="img-fluid mb-30"
                        />
                        <p className="fon-20">
                          <strong>부비동염</strong>
                        </p>
                      </div>
                      <div className="gridCol">
                        <img
                          src="/img/nose/nose2-14.png"
                          alt=""
                          className="img-fluid mb-30"
                        />
                        <p className="fon-20">
                          <strong>재발성 귀 멍멍함</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrapper">
              <Rotate cascade left>
                <div className="d-flex justify-content-around align-items-center mt-100 mb-100">
                  <div className="">
                    <Ccl1 className="">
                      유전성, 가족력 파악
                      <br />
                      <span>천식, 결막염, 아토피 피부염 동반질환 확인</span>
                    </Ccl1>
                  </div>
                  <div className="">
                    <img
                      src="/img/nose/right-arr-primary2.svg"
                      className="nose2Arr"
                      alt=""
                    />
                  </div>
                  <div className="">
                    <Ccl2 className="">
                      원인파악
                      <br />
                      <span>
                        MAST(면역혈칭검사)
                        <br />
                        IgG4 Food 항체검사
                        <br />
                        알레르기피부검사
                      </span>
                    </Ccl2>
                  </div>
                  <div className="">
                    <img
                      src="/img/nose/right-arr-primary2.svg"
                      className="nose2Arr"
                      alt=""
                    />
                  </div>
                  <div className="">
                    <Ccl3 className="">
                      면역치료
                      <br />
                      환경요법
                      <br />
                      약물요법
                    </Ccl3>
                  </div>
                </div>
              </Rotate>
            </div>
          </div>
          <div className={tabNum === 2 ? "tab active" : "tab"}>
            <Slide bottom>
              <h1 className="text-center font-28 mb-50 color-primary">
                알레르기 비염은 조절이라는 개념을 가지고 꾸준히 치료하는 것이
                좋습니다.
                <br />
                <span className="color-default">
                  여러 가지 치료 방법이 있으므로 전문가의 조언에 따라 자기에게
                  맞는 치료방법을 찾아야 합니다.
                </span>
              </h1>
            </Slide>
            <div className="wrapper">
              <div className="row mb-50">
                <div className="col-md-4">
                  <Slide left>
                    <h1 className="font-45 wordKeep color-primary xs-to-center mb-100">
                      환경요법: <br />
                      <br />
                      <br />
                      <span className="font-20 color-default">
                        알레르기 비염을 일으키는 원인 물질 노출 줄이기
                      </span>
                    </h1>
                  </Slide>
                </div>
                <div className="col-md-8">
                  <Slide bottom casecade>
                    <img
                      src="/img/nose/nose2-15.png"
                      alt=""
                      className="img-fluid mb-20"
                    />
                    <p className="font-24">
                      <strong>집먼지 진드기</strong>
                    </p>
                    <p className="font-24 mb-50">
                      집먼지 진드기 자체가 직접적으로 전염병을 옮기거나 물어서
                      해를 입히진 않습니다. 하지만 집먼지 진드기의 배설물이나
                      시체 등이 호흡기에 들어가거나 점막, 피부 등에 닿아
                      알레르기를 일으킵니다. 최근에는 서양식 침구류의 사용이
                      늘어 집먼지 진드기로 인한 알레르기가 증가하는 추세입니다.
                    </p>
                    <ul className="list-pre color-primary mb-100">
                      <li>
                        소파 등의 사용을 줄이고 두꺼운 침대커버보다는 매트리스
                        커버를 사용한다.
                      </li>
                      <li>침구류는 뜨거운 온수로 자주 세탁한다.</li>
                      <li>집먼지 진드기 살충제를 이용한다.</li>
                      <li>환기를 자주시켜 습도를 50%이하로 낮춘다.</li>
                      <li>
                        집먼지 진드기는 추위에 약하므로 아이들 인형은 냉동실
                        등에서 얼리는 것이 좋다.
                      </li>
                      <li>에어컨필터를 자주 청소한다.</li>
                      <li>물걸레 청소를 자주하고 최소한의 가구를 설치한다.</li>
                      <li>
                        집먼지 진드기 상피껍질은 9-10월에 최고 농도를 이루므로
                        이시기를 주의한다.
                      </li>
                    </ul>
                    <img
                      src="/img/nose/nose2-16.png"
                      alt=""
                      className="img-fluid mb-20"
                    />
                    <p className="font-24">
                      <strong>꽃가루</strong>
                    </p>
                    <p className="font-24 mb-50">
                      봄은 유독 알레르기를 일으키는 항원이 많이 나타나는
                      계절입니다. 꽃가루는 우리나라에서 집먼지 진드기 다음으로
                      흔한 알레르기 항원으로, 예민한 사람에게 비염, 결막염, 천식
                      같은 질환을 일으킵니다.
                      <br />
                      (4월말 ~ 5월 중순, 8월 말 ~ 9월 중순이 가장 심함)
                    </p>
                    <ul className="list-pre color-primary mb-100">
                      <li>
                        바람이 강하고 맑은 날은 공기 중에 꽃가루의 양이 많아
                        외출을 삼간다.
                      </li>
                      <li>마스크와 안경을 착용한다.</li>
                      <li>
                        외출 후 귀가하면 옷에 붙은 꽃가루를 털어내고 손과 얼굴을
                        씻고 가글을 한다.{" "}
                      </li>
                      <li>
                        꽃가루 날리는 시기에는 환기 보다는 공기정화기를
                        사용한다.
                      </li>
                      <li>오전 10시에서 오후 2시의 야외할동을 자제한다.</li>
                    </ul>
                    <img
                      src="/img/nose/nose2-17.png"
                      alt=""
                      className="img-fluid mb-20"
                    />
                    <p className="font-24">
                      <strong>곰팡이</strong>
                    </p>
                    <p className="font-24 mb-50">
                      곰팡이 포자는 사람의 몸 내부에서 상장하는 경우 치명적인
                      질환을 유발합니다. 꽃가루와 달리 시기적으로 발생하지 않고
                      집안에 항상 존재하는 알레르기 항원이기 때문에 더욱
                      조심해야 합니다.
                    </p>
                    <ul className="list-pre color-primary mb-100">
                      <li>3~7월에 많이 발산하므로 이 시기를 주의한다.</li>
                      <li>
                        곰팡이가 서식하기 좋은 환경인 주방 욕실 창고 등을 자주
                        환기시켜준다.
                      </li>
                      <li>세탁물은 실내에서 건조하지 않고 외부에 건조한다.</li>
                    </ul>
                    <img
                      src="/img/nose/nose2-18.png"
                      alt=""
                      className="img-fluid mb-20"
                    />
                    <p className="font-24">
                      <strong>동물털</strong>
                    </p>
                    <p className="font-24 mb-50">
                      동물을 만지거나 안았을 때 알레르기 반응이 나타나기 때문에
                      털 자체에 알레르기가 있다고 생각하기 쉽습니다. 하지만 털
                      자체가 아니라 털에 묻어있는 동물의 침이나 각질에 의해
                      알레르기 반응이 일어납니다.
                    </p>
                    <ul className="list-pre color-primary mb-100">
                      <li>원인이 되는 동물을 키우지 않는 것이 좋다.</li>
                      <li>
                        반려동물을 키울 경우 자주 목욕을 시켜 청결을 유지한다.
                      </li>
                      <li>
                        공기 중에 털이 많이 날리지 않도록 실내 분수 등으로 털을
                        제거해 주는 것이 좋다.
                      </li>
                    </ul>
                    <img
                      src="/img/nose/nose2-19.png"
                      alt=""
                      className="img-fluid mb-20"
                    />
                    <p className="font-24  mb-50">
                      <strong>바퀴벌래</strong>
                    </p>
                    <ul className="list-pre color-primary mb-100">
                      <li>
                        바퀴벌레가 좋아하는 먹이(기름끼, 비누, 풀, 치약, 설탕류,
                        알코올류)가 없도록 치운다
                      </li>
                      <li>
                        살충제로 구제할 수도 있으나 효과는 청결관리가 보다 더
                        중요하다
                      </li>
                    </ul>
                    <img
                      src="/img/nose/nose2-20.png"
                      alt=""
                      className="img-fluid mb-20"
                    />
                    <p className="font-24">
                      <strong>음식</strong>
                    </p>
                    <p className="font-24 mb-50">
                      주로 단백질 식품과 견과류, 해산물(갑각류) 등에 많이
                      나타납니다.
                    </p>
                    <ul className="list-pre color-primary mb-100">
                      <li>
                        알레르기 반응이 나타났을 때는 먹었던 음식을 기록하여
                        다음부터는 원인이 되는 음식을 피하도록 한다.
                      </li>
                      <li>
                        아이가 고기를 먹고 알레르기 반응이 나타났을 때는 무조건
                        먹이지 않는 것보다는 영양분이 부족하지 않도록 주치의의
                        지시를 받도록 한다.
                      </li>
                      <li>
                        수면부족, 과로, 스트레스, 환경 등에 영향을 받으므로
                        컨디션이 좋지 않을 때는 알레르기가 나타날 수 있는 음식은
                        피한다.
                      </li>
                    </ul>
                  </Slide>
                </div>
              </div>
              <div className="row mb-50">
                <div className="col-md-4">
                  <Slide left>
                    <h1 className="font-45 wordKeep color-primary xs-to-center mb-100">
                      면역치료
                    </h1>
                  </Slide>
                </div>
                <div className="col-md-8">
                  <Slide right casecade>
                    <p className="font-24">
                      알레르기 질환의 자연경과를 바꿀수 있는 유일한 치료법이지만
                      장기간 치료가 필요하며 비용이 많이 듭니다. 원인 항원에
                      대해 약 3~5년의 치료를 지속하며 약 70~90% 이상 효과가 있는
                      것으로 알려져 있습니다. 치료 후 약 12개월 후 효과가 없다면
                      치료를 중단하고 알레르기 항원검사를 다시 하여 다른 원인을
                      찾아 보아야 합니다. 현재는 우리 나라에서 수입되어
                      치료가능한 항원은 미국형 집먼지 진드기, 유럽형 집먼지
                      진드기와 일부 화분류가 있습니다.{" "}
                    </p>
                  </Slide>
                </div>
              </div>
              <div className="row mb-50">
                <div className="col-md-4">
                  <Slide left>
                    <h1 className="font-45 wordKeep color-primary xs-to-center mb-100">
                      수술치료
                    </h1>
                  </Slide>
                </div>
                <div className="col-md-8">
                  <Slide right casecade>
                    <p className="font-24">
                      기본적으로 알레르기 비염에서 수술적 치료는 하지 않으나
                      알레르기 비염 환자에서 약물에 반응 없는 심한 비폐색을 가진
                      환자에서 제한적으로 비점막을 줄여주는 레이져 또는 고주파
                      수술 등을 시행할 수 있습니다.
                    </p>
                  </Slide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Nose2;
