import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

// import { Parallax } from "react-scroll-parallax";
// import Image from "../../components/Image";

import { Parallax } from "react-parallax";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";

import ResvRodal from "../../components/resvRodal";

import "./ear1.css";

const Jw = styled.div`
  .jwh {
    text-align: right;
    margin-right: var(--size-30px);
    @media (max-width: 767px) {
      .jwp {
        width: 20px;
      }
    }
  }
`;

const Dl = styled.dl`
  position: relative;
  dt {
    position: absolute;
  }
  dd {
    padding-left: var(--size-150px);
    @media (max-width: 767px) {
      padding-left: 100px;
    }
  }
`;

const Tbl = styled.table`
  colgroup {
    col:first-child {
      width: 250px;
    }
  }
  .bor {
    border-right: 1px solid #f2f5f7;
  }
  .thBor {
    border-right: 1px solid #fff;
  }
  @media (max-width: 719px) {
    colgroup {
      col:first-child {
        width: 100px;
      }
    }
  }
`;

const BlueSpan = styled.span`
  background: var(--color-link);
  color: #fff;
`;

function Ear4({ history, props }) {
  const dispatch = useDispatch();
  // 마우스 위치 기반 자동 가로 스크롤
  const horizontalScrollRef = useRef(null);
  const mX = useRef(0);
  const mX2 = useRef(0);
  const posX = useRef(0);
  const mPadd = 60;
  const damp = 20;
  const horizontalScrollRef2 = useRef(null);
  const mX12 = useRef(0);
  const mX22 = useRef(0);
  const posX2 = useRef(0);
  const mPadd2 = 60;
  const damp2 = 20;
  // 요기까지
  const [visibleResv, setVisibleResv] = useState(false);
  const [tabNum, setTabNum] = useState(0);
  const [defaultTreatId, setDefaultTreatId] = useState(0);
  const [defaultTreatNm, setDefaultTreatNm] = useState(0);

  // const handleResvDefaultValues = (id, tnm) => {
  //   if (id > 0 && tnm) {
  //     setDefaultTreatId(id);
  //     setDefaultTreatNm(tnm);
  //     setVisibleResv(true);
  //     console.log(id);
  //     console.log(tnm);
  //   }
  // };

  const handleResvDefaultValues = () => {
    setVisibleResv(true);
  };

  // 예약완료
  const resvComplate = () => {
    setVisibleResv(false);
  };

  const resvClose = () => {
    setVisibleResv(!resvClose);
  };

  // scroll 버튼 처리
  const scrollDown = () => {
    const n = document.documentElement.clientHeight;
    window.scrollBy({ top: n, left: 0, behavior: "smooth" });
  };

  const setTab = (num) => {
    setTabNum(num);
  };

  // 자동 스크롤 리스너
  const horizontalScroll = (e) => {
    if (horizontalScrollRef.current) {
      const container = horizontalScrollRef.current;
      const parent = container.parentElement;
      const galW = container.offsetWidth;
      const galSW = container.scrollWidth;
      const wDiff = galSW / galW - 1; // widths difference ratio
      const mmAA = galW - mPadd * 2; // the mousemove available area
      const mmAAr = galW / mmAA;

      if (parent) {
        mX.current =
          e.pageX - parent.getBoundingClientRect().left - container.offsetLeft;

        mX2.current = Math.min(Math.max(0, mX.current - mPadd), mmAA) * mmAAr;
      }

      posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"

      setInterval(function () {
        posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"
        container.scrollLeft = posX.current * wDiff;
      }, 40);
    }
  };
  const horizontalScroll2 = (e) => {
    if (horizontalScrollRef2.current) {
      const container2 = horizontalScrollRef2.current;
      const parent2 = container2.parentElement;
      const galW2 = container2.offsetWidth;
      const galSW2 = container2.scrollWidth;
      const wDiff2 = galSW2 / galW2 - 1; // widths difference ratio
      const mmAA2 = galW2 - mPadd2 * 2; // the mousemove available area
      const mmAAr2 = galW2 / mmAA2;

      if (parent2) {
        mX12.current =
          e.pageX -
          parent2.getBoundingClientRect().left -
          container2.offsetLeft;

        mX22.current =
          Math.min(Math.max(0, mX12.current - mPadd2), mmAA2) * mmAAr2;
      }

      posX2.current += (mX22.current - posX2.current) / damp2; // zeno's paradox equation "catching delay"

      setInterval(function () {
        posX2.current += (mX22.current - posX2.current) / damp; // zeno's paradox equation "catching delay"
        container2.scrollLeft = posX2.current * wDiff2;
      }, 40);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>청각 장애 진단</title>
      </Helmet>
      {visibleResv ? (
        <ResvRodal
          visibleResv={visibleResv}
          resvClose={resvClose}
          resvComplate={resvComplate}
          defaultTreatId={defaultTreatId}
          defaultTreatNm={defaultTreatNm}
        />
      ) : (
        ""
      )}
      <div className="topBar"></div>
      <div className="sv">
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={"/img/ear/ear4.png"}
          bgImageAlt=""
          strength={-300}
        >
          <div className="container">
            <Slide up>
              <div className="parallaxExplain btm">
                <div className="top">
                  <h1 className="pgTitle">청각 장애 진단</h1>
                  <p className="pgTitleExp22">
                    대학병원 보다 빠르고 편안하게 청각 장애 진단! 최고사양의
                    진단 기계를 이용하여 정확한 진단을 내려드립니다.
                  </p>
                  <p className="pgTitleExp22 mt-30">
                    <strong>
                      청각장애진단에서 보청기까지 ONE STOP 보장구처방전,
                      보장구검수확인
                    </strong>
                  </p>
                </div>
                <div className="mid">
                  <Row noGutters>
                    <Col>
                      <button
                        type="button"
                        className="btnNoStyle"
                        onClick={(e) => handleResvDefaultValues()}
                        // onClick={(e) =>
                        //   handleResvDefaultValues(10, "청각장애 진단")
                        // }
                      >
                        <img src="/img/ico-chart.svg" alt="" />
                        <span>예약하기</span>
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Slide>
          </div>
          <div style={{ height: "100vh" }} />
          <button
            type="button"
            className="btnScrollDown"
            onClick={() => scrollDown()}
          >
            <img src="/img/scrollDown.svg" alt="" />
          </button>
        </Parallax>
        <div className="wrapper">
          <div className="mt-100 mb-100">
            <div className="row no-gutters">
              <div className="col-md-4">
                <Slide up>
                  <h1 className="tabPre color-primary xs-to-center xs-mb-100">
                    청각장애 등급 기준
                  </h1>
                </Slide>
              </div>
              <div className="col-md-8">
                <Slide right>
                  <h2 className="color-primary font-30 mb-60">
                    <img src="/img/chk.svg" className="mr-10" alt="" />{" "}
                    장애정도가 심한 장애
                  </h2>
                  <Dl className="font-24">
                    <dt>청각장애 1급: </dt>
                    <dd>청각장애 2급과 동시 다른 장애와 중복되는 경우</dd>
                  </Dl>
                  <Dl className="font-24">
                    <dt>청각장애 2급: </dt>
                    <dd>두 귀 청력손실이 각각 90dB 이상인 경우</dd>
                  </Dl>
                  <Dl className="font-24 mb-60">
                    <dt>청각장애 3급: </dt>
                    <dd>두 귀 청력손실이 각각 80dB 이상인 경우</dd>
                  </Dl>
                  <h2 className="color-primary font-30 mb-60">
                    <img src="/img/chk.svg" className="mr-10" alt="" />{" "}
                    장애정도가 심하지 않은 장애
                  </h2>
                  <Dl className="font-24">
                    <dt>청각장애 4급: </dt>
                    <dd>
                      1호) 두 귀 청력소실이 각각 70dB 이상인 경우
                      <br />
                      2호) 두 귀 어음명료도가 50% 이하인 경우
                    </dd>
                  </Dl>
                  <Dl className="font-24">
                    <dt>청각장애 5급: </dt>
                    <dd>두 귀 청력손실이 각각 60dB 이상인 경우</dd>
                  </Dl>
                  <Dl className="font-24 mb-60">
                    <dt>청각장애 6급: </dt>
                    <dd>
                      한 귀 청력손실이 80dB 이상, 다른 귀의 청력손실이 40dB
                      이상인 경우
                    </dd>
                  </Dl>
                </Slide>
              </div>
            </div>
          </div>
          <Rotate top left>
            <div
              className="gridContainer grid3 padding text-center"
              ref={horizontalScrollRef}
              onMouseMove={(e) => horizontalScroll(e)}
            >
              <div className="gridCol">
                <img src="/img/ear/ear4-1.png" alt="" className="img-fluid" />
                <p className="font-24 mt-30">순음청력검사기기</p>
              </div>
              <div className="gridCol">
                <img src="/img/ear/ear4-2.png" alt="" className="img-fluid" />
                <p className="font-24 mt-30">오디오부스</p>
              </div>
              <div className="gridCol">
                <img src="/img/ear/ear4-3.png" alt="" className="img-fluid" />
                <p className="font-24 mt-30">청성뇌간유발반응검사(ABR)</p>
              </div>
            </div>
          </Rotate>
        </div>
        <div className="blue-row">
          <Fade up>
            <h1 className="text-center font-45 color-primary">
              청각장애 등록절차
            </h1>
          </Fade>
          <div
            className="d-flex align-self-center align-items-center mt-100 mb-100 mr-30 overScrollX"
            ref={horizontalScrollRef2}
            onMouseMove={(e) => horizontalScroll2(e)}
          >
            <img src="/img/ear/ear4-4.png" className="img-fluid" alt="" />
            <img src="/img/ear/ear4-arr-r.png" className="img-fluid" alt="" />
            <img src="/img/ear/ear4-5.png" className="img-fluid" alt="" />
            <img src="/img/ear/ear4-arr-r.png" className="img-fluid" alt="" />
            <img src="/img/ear/ear4-6.png" className="img-fluid" alt="" />
            <img src="/img/ear/ear4-arr-r.png" className="img-fluid" alt="" />
            <img src="/img/ear/ear4-7.png" className="img-fluid" alt="" />
          </div>
          <div className="container-xl">
            <p className="font-24">
              <strong>1.</strong> 거주지 동, 읍, 면 사무소에서 장애 진단
              의뢰서와 진단서를 발급받는다.
            </p>
            <p className="font-24">
              <strong>2.</strong> 청력검사 장비가 갖춰진 이비인후과 병원에서
              장애 진단 후 장애 등급이 명시된 장애진단서를 발급 받는다.
            </p>
            <p className="font-24">
              <strong>3.</strong> 동, 읍, 면 사무소에 진단서 제출 일주일 후
              동사무소에서 장애인증을 발급 받는다.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ear4;
