import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link, useParams } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

import { instance } from "../../_helpers";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

const RodalHeader = styled.h1`
  text-align: center;
  color: var(--color-link);
  margin-bottom: var(--size-30px);
  img {
    margin-right: 10px;
  }
`;

const ActionBtn = styled.button`
  border: 0;
  outline: 0;
  display: block;
  text-align: center;
  background: var(--color-link);
  color: #fff;
  width: 100%;
  padding: var(--size-20px);
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const CancelMsg = styled.div``;

const Centered = styled.div`
  padding-top: var(--size-200px);
  padding-bottom: var(--size-100px);
  .d-flex {
    overflow-x: auto;
  }
  .flexItem {
    padding: var(--size-10px);
  }
  .colBox {
    border: 2px solid var(--color-link);
    padding: var(--size-30px);
    min-height: 350px;
  }
  @media (max-width: 767px) {
    .imgPt {
      max-width: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .d-flex {
      margin-left: -15px;
      margin-right: -15px;
    }
    .flexItem img {
      width: 200px;
    }
  }
  .btnAct {
    border: 0;
    outline: 0;
    display: block;
    text-align: center;
    background: var(--color-link);
    color: #fff;
    width: 250px;
    padding: var(--size-20px);
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

const DoneAlert = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 1);
  z-index: 1000;
  .ovIn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 90%;
    background: #fff;
    border-radius: 5px;
    padding: 25px 15px 15px 15px;
    .ovMsg {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
`;

function Cancel({ history, props }) {
  const dispatch = useDispatch();
  const param = useParams();
  const [doctorName, setDoctorName] = useState("");
  const [phonePre, setPhonePre] = useState("");
  const [phoneMid, setPhoneMid] = useState("");
  const [phoneEnd, setPhoneEnd] = useState("");
  const [resvName, setResvName] = useState("");
  const [schDate, setSchDate] = useState("");
  const [schTime, setSchTime] = useState("");
  const [treatName, setTreatName] = useState("");
  const [resvYn, setResvYn] = useState("n");
  const [cancelBtnDisabled, setCancelBtnDisabled] = useState(true);
  const [noData, setNoData] = useState(false);
  const [visibleRodal, setVisibleRodal] = useState(false);
  const [alreadyCancel, setAlreadyCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cancelDone, setCancelDone] = useState(false);

  const goMain = () => (location.href = "/");

  const hideRodal = () => {
    setVisibleRodal(false);
  };

  const cancelAlert = () => {
    if (param.token) setVisibleRodal(true);
  };

  const cancelAction = () => {
    if (param.token) {
      const token = {
        token: param.token,
      };
      instance
        .post(`/api/v2/front/cancel`, token)
        .then((res) => res.data)
        .then((data) => {
          setCancelDone(true);
        })
        .catch((err) => {
          alert(
            "오류가 발생했습니다.\n문제가 지속될 경우 병원으로 연락주세요."
          );
        });
    } else {
      alert("토큰 정보가 올바르지 않습니다.");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));

    async function fetchUserData() {
      const token = {
        token: param.token,
      };
      instance
        .post(`/api/v2/front/checkResv`, token)
        .then((res) => res.data)
        .then((data) => {
          console.log(data);
          console.log(data.resvYn)
          if (
            data.resvYn === "y"
          ) {
            // 예약 대기인 상태
            console.log('예약 대기인 상태')
            setDoctorName(data.doctorName);
            setPhonePre(data.phonePre);
            setPhoneMid(data.phoneMid);
            setPhoneEnd(data.phoneEnd);
            setResvName(data.resvName);
            setSchDate(data.schDate);
            setSchTime(data.schTime);
            setTreatName(data.treatName);
            setResvYn(data.resvYn);
            setCancelBtnDisabled(false);
            setNoData(false);
            setAlreadyCancel(false);
          } else if (
            // 예약 취소인상태
            data.resvYn === "n"
          ) {
            console.log("이미 예약 취소된건");
            setNoData(false);
            setResvYn("n");
            setCancelBtnDisabled(true);
            setAlreadyCancel(true);
          } else {
            setNoData(true);
          }
        })
        .catch((err) => console.log("err"))
        .finally(() => setIsLoading(false));
    }
    if (param.token) fetchUserData();
  }, []);

  return (
    <>
      <Helmet>
        <title>예약 취소</title>
      </Helmet>

      <Rodal
        visible={visibleRodal}
        onClose={hideRodal}
        animation="slideUp"
        closeOnEsc
        customStyles={{
          borderRadius: "5px",
          height: "auto",
          bottom: "auto",
          top: "50%",
          transform: "translateY(-50%)",
          width: "500px",
          border: "5px solid #0076BE",
        }}
      >
        {cancelDone ? (
          <DoneAlert>
            <div className="ovIn">
              <p className="ovMsg text-center">취소되었습니다.</p>
              <ActionBtn type="button" className="font-24" onClick={goMain}>
                확인
              </ActionBtn>
            </div>
          </DoneAlert>
        ) : null}
        <RodalHeader className="font-30">예약 취소 확인</RodalHeader>
        <CancelMsg className="font-30 text-center">
          예약을 취소 하시겠습니까?
        </CancelMsg>
        <div className="d-flex mt-30">
          <ActionBtn type="button" className="font-24" onClick={cancelAction}>
            예
          </ActionBtn>
          <ActionBtn
            type="button"
            className="font-24"
            style={{ backgroundColor: "#575c60", marginLeft: "10px" }}
            onClick={() => setVisibleRodal(false)}
          >
            아니오
          </ActionBtn>
        </div>
      </Rodal>
      <div className="topBar"></div>
      <div className="sv">
        <Centered className="wrapper">
          {isLoading ? null : (
            <>
              <p className="font-30 text-center color-primary mb-50">
                {resvYn === "y" || resvYn === "r"
                  ? "예약하신 정보를 취소하시겠습니까?"
                  : "이미 예약을 취소하셨습니다."}
              </p>
              {resvYn === "y" || resvYn === "r" ? (
                <div className="d-flex">
                  <div className="center-block">
                    <p className="font-30">예약자 명 : {resvName}님</p>
                    <p className="font-30">
                      전화번호 : {phonePre}-{phoneMid}-{phoneEnd}
                    </p>
                    <p className="font-30">
                      예약일 : {schDate} {schTime}
                    </p>
                    <p className="font-30">진료과목 : {treatName}</p>
                    <p className="font-30">담당의사 : {doctorName}</p>
                  </div>
                </div>
              ) : null}
              {resvYn === "y" || resvYn === "r" ? (
                <div className="d-flex mt-50">
                  <button
                    type="button"
                    className="btnAct font-30"
                    onClick={cancelAlert}
                    disabled={cancelBtnDisabled}
                  >
                    예약 취소
                  </button>
                </div>
              ) : null}
            </>
          )}
        </Centered>
      </div>
    </>
  );
}

export default Cancel;
