import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

import { Parallax, Background } from "react-parallax";

// import { Parallax } from "react-scroll-parallax";
// import Image from "../../components/Image";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

import ResvRodal from "../../components/resvRodal";

const insideStyles = {
  position: "absolute",
  top: "50%",
  left: "2rem",
  right: "2rem",
  transform: "translateY(-50%)",
  textAlign: "center",
};

const Centered = styled.div`
  padding-top: var(--size-200px);
  padding-bottom: var(--size-100px);
  .d-flex {
    overflow-x: auto;
  }
  .flexItem {
    padding: var(--size-10px);
  }
  .colBox {
    border: 2px solid var(--color-link);
    padding: var(--size-30px);
    min-height: 350px;
  }
  @media (max-width: 767px) {
    .imgPt {
      max-width: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .d-flex {
      margin-left: -15px;
      margin-right: -15px;
    }
    .flexItem img {
      width: 200px;
    }
  }
`;

function Clean2({ history, props }) {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>수술 후 관리</title>
      </Helmet>
      <div className="topBar"></div>
      <div className="sv">
        <Centered className="wrapper">
          <h1 className="font-45 color-primary text-center mb-60">
            수술 후 관리
          </h1>
          <Row>
            <div className="col-md-4">
              <img
                src="/img/clean/clean2.png"
                alt=""
                className="img-fluid mb-50"
              />
            </div>
            <div className="col-md-4">
              <h1 className="font-30 mb-50">1. 수술 전 정밀검사</h1>
              <ul className="list-pre mb-50">
                <li>
                  3D 컴퓨터단층촬영
                  <br />
                  <span className="font-lighter">3D conebeam CT</span>
                </li>
                <li>
                  음향비강통기도검사
                  <br />
                  <span className="font-lighter">Acoustic Rhinometery</span>
                </li>
                <li>
                  부비동초음파
                  <br />
                  <span className="font-lighter">Sinus Sonography</span>
                </li>
                <li>
                  후각검사
                  <br />
                  <span className="font-lighter">Olfactometry</span>
                </li>
                <li>
                  알레르기검사
                  <br />
                  <span className="font-lighter">Skin test or MAST</span>
                </li>
                <li>
                  수술 안전도 평가
                  <br />
                  <span className="font-lighter">Serologic Test & EKG</span>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <h1 className="font-30 mb-50">2. 최첨단 수술 시스템</h1>
              <ul className="list-pre mb-50">
                <li>Full HD endosopic visual system</li>
                <li>미세절삭기 Debrider</li>
                <li>Coblator II</li>
                <li>Sutter – 고주파 수술 기구</li>
              </ul>
              <h1 className="font-30 mb-50">3. 철저한 소독 시스템</h1>
              <ul className="list-pre">
                <li>고압증기 멸균소독기</li>
                <li>EO 가스 소독기</li>
                <li>자외선 소독기</li>
              </ul>
            </div>
          </Row>
        </Centered>
        <Parallax bgImage={`/img/clean/clean2-1.png`} strength={500}>
          <div style={{ height: 400 }}>
            <div className="wordKeep" style={insideStyles}>
              <h1 className="font-30 mb-10 font-normal">
                안전하고 편안한 <strong>최소 침습적 수술</strong>
              </h1>
              <h1 className="font-30 color-primary mb-40">
                수술 중 대학병원과 동일한 기계를 이용한 환자 Monitoring System
              </h1>
              <p className="font-24">
                혈압, 맥박, 산소포화도, 이산화탄소분압 등 지속적 관찰을 통해
                환자의 안전을 최우선으로 합니다.
                <br />
                하나이비인후과의 마취 시스템으로 통증 없는 수술을 제공합니다.
              </p>
            </div>
          </div>
        </Parallax>
        <div className="text-center mt-100 mb-100">
          <img src="/img/clean/clean2-2.png" alt="" className="img-fluid" />
        </div>
      </div>
    </>
  );
}

export default Clean2;
