import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

// import { Parallax } from "react-scroll-parallax";
// import Image from "../../components/Image";

import { Parallax } from "react-parallax";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";

import ResvRodal from "../../components/resvRodal";

import "./ear1.css";

const Jw = styled.div`
  .jwh {
    text-align: right;
    margin-right: var(--size-30px);
    @media (max-width: 767px) {
      .jwp {
        width: 20px;
      }
    }
  }
`;

function Ear1({ history, props }) {
  const dispatch = useDispatch();
  // 마우스 위치 기반 자동 가로 스크롤
  const horizontalScrollRef = useRef(null);
  const mX = useRef(0);
  const mX2 = useRef(0);
  const posX = useRef(0);
  const mPadd = 60;
  const damp = 20;
  // 요기까지
  const [visibleResv, setVisibleResv] = useState(false);
  const [tabNum, setTabNum] = useState(0);
  const [defaultTreatId, setDefaultTreatId] = useState(0);
  const [defaultTreatNm, setDefaultTreatNm] = useState(0);

  // const handleResvDefaultValues = (id, tnm) => {
  //   if (id > 0 && tnm) {
  //     setDefaultTreatId(id);
  //     setDefaultTreatNm(tnm);
  //     setVisibleResv(true);
  //     console.log(id);
  //     console.log(tnm);
  //   }
  // };

  const handleResvDefaultValues = () => {
    setVisibleResv(true);
  };

  // 예약완료
  const resvComplate = () => {
    setVisibleResv(false);
  };

  const resvClose = () => {
    setVisibleResv(!resvClose);
  };

  // scroll 버튼 처리
  const scrollDown = () => {
    const n = document.documentElement.clientHeight;
    window.scrollBy({ top: n, left: 0, behavior: "smooth" });
  };

  const setTab = (num) => {
    setTabNum(num);
  };

  // 자동 스크롤 리스너
  const horizontalScroll = (e) => {
    if (horizontalScrollRef.current) {
      const container = horizontalScrollRef.current;
      const parent = container.parentElement;
      const galW = container.offsetWidth;
      const galSW = container.scrollWidth;
      const wDiff = galSW / galW - 1; // widths difference ratio
      const mmAA = galW - mPadd * 2; // the mousemove available area
      const mmAAr = galW / mmAA;

      if (parent) {
        mX.current =
          e.pageX - parent.getBoundingClientRect().left - container.offsetLeft;

        mX2.current = Math.min(Math.max(0, mX.current - mPadd), mmAA) * mmAAr;
      }

      posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"

      setInterval(function () {
        posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"
        container.scrollLeft = posX.current * wDiff;
      }, 40);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>난청클리닉</title>
      </Helmet>
      {visibleResv ? (
        <ResvRodal
          visibleResv={visibleResv}
          resvClose={resvClose}
          resvComplate={resvComplate}
          defaultTreatId={defaultTreatId}
          defaultTreatNm={defaultTreatNm}
        />
      ) : (
        ""
      )}
      <div className="topBar"></div>
      <div className="sv">
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={"/img/ear/ear1.png"}
          bgImageAlt=""
          strength={-300}
        >
          <div className="container">
            <Slide up>
              <div className="parallaxExplain btm">
                <div className="top">
                  <h1 className="pgTitle">난청클리닉</h1>
                  <p className="pgTitleExp22">
                    난청은 귓속에서 소리가 전달되거나 변화되는 과정 중에
                    이상으로 발생합니다. 난청은 일반적으로 전음성 난청(소리가
                    달팽이관에 전달되는 경로에 발생하는 이상)과 감각신경성
                    난청(달팽이관 및 소리 의한 자극을 뇌로 전하는 청신경 또는
                    중추신경계의 이상으로 소리를 감지하는 데에 문제가 있는
                    경우)으로 나눌 수 있습니다.
                  </p>
                  <p className="pgTitleExp22 mt-30">
                    <strong>
                      DMC하나이비인후과에서는 난청의 원인을 정확하고 적정한
                      검사를 통해 환자의 난청 상태를 파악하고 각각의 원인에 맞는
                      치료가 가능합니다.
                    </strong>
                  </p>
                </div>
                <div className="mid">
                  <Row noGutters>
                    <Col xs="12">
                      <button
                        type="button"
                        className="btnNoStyle"
                        // onClick={(e) => handleResvDefaultValues(6, "난청 검사")}
                        onClick={(e) => handleResvDefaultValues()}
                      >
                        <img src="/img/ico-chart.svg" alt="" />
                        <span>예약하기</span>
                      </button>
                    </Col>
                    {/* <Col xs="6">
                      <button
                        type="button"
                        className="btnNoStyle"
                        // onClick={(e) => handleResvDefaultValues(7, "이명 검사")}
                        onClick={(e) => handleResvDefaultValues()}
                      >
                        <img src="/img/ico-chart.svg" alt="" />
                        <span>이명 검사 예약</span>
                      </button>
                    </Col> */}
                  </Row>
                </div>
              </div>
            </Slide>
          </div>
          <div style={{ height: "100vh" }} />
          <button
            type="button"
            className="btnScrollDown"
            onClick={() => scrollDown()}
          >
            <img src="/img/scrollDown.svg" alt="" />
          </button>
        </Parallax>
        <div className="wrapper">
          <div className="tabHeader mt-100 mb-100">
            <div className="row no-gutters">
              <div className="col-sm-4">
                <Slide up>
                  <h1 className="tabPre color-primary xs-to-center xs-mb-100">
                    난청의 원인
                  </h1>
                </Slide>
              </div>
              <div className="col-sm-8">
                <Slide right>
                  <ul className="d-flex justify-content-between align-items-center">
                    <li>
                      <button
                        type="button"
                        className={tabNum === 0 ? "active" : ""}
                        onClick={() => setTabNum(0)}
                      >
                        <span className="d-none d-md-block d-lg-block d-xl-block d-xxl-block">
                          전음성 난청을 일으키는 원인
                        </span>
                        <span className="d-block d-md-none">
                          전음성 난청을
                          <br />
                          일으키는 원인
                        </span>
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className={tabNum === 1 ? "active" : ""}
                        onClick={() => setTabNum(1)}
                      >
                        <span className="d-none d-md-block d-lg-block d-xl-block d-xxl-block">
                          감각신경성 난청을 일으키는 원인
                        </span>
                        <span className="d-block d-md-none">
                          감각신경성 난청을
                          <br />
                          일으키는 원인
                        </span>
                      </button>
                    </li>
                  </ul>
                </Slide>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="tabContent mt-100">
            <div className={tabNum === 0 ? "tab active" : "tab"}>
              <div className="row">
                <Fade>
                  <div className="col-md-6 text-center">
                    <img
                      src="/img/ear/ear1-1.png"
                      className="img-fluid"
                      alt=""
                    />
                    <p className="font-24 mt-20">[정상 순음청력도]</p>
                  </div>
                  <div className="col-md-6 text-center">
                    <img
                      src="/img/ear/ear1-2.png"
                      className="img-fluid"
                      alt=""
                    />
                    <p className="font-24 mt-20">[전음성 난청의 순음청력도]</p>
                  </div>
                </Fade>
              </div>
              <div
                className="gridContainer grid5 mt-50 mb-100"
                ref={horizontalScrollRef}
                onMouseMove={(e) => horizontalScroll(e)}
              >
                <Rotate top left>
                  <div className="gridCol">
                    <div className="gridColInner">
                      <img src="/img/ear/ear1-3.png" alt="" />
                      <p className="text">정상인 고막의 모습</p>
                    </div>
                  </div>
                  <div className="gridCol">
                    <div className="gridColInner">
                      <img src="/img/ear/ear1-4.png" alt="" />
                      <p className="text">
                        삼출성중이염
                        <br />
                        (소아의 경우에 흔함)
                      </p>
                    </div>
                  </div>
                  <div className="gridCol">
                    <div className="gridColInner">
                      <img src="/img/ear/ear1-5.png" alt="" />
                      <p className="text">만성중이염</p>
                    </div>
                  </div>
                  <div className="gridCol">
                    <div className="gridColInner">
                      <img src="/img/ear/ear1-6.png" alt="" />
                      <p className="text">심한 귀지로 인한 외이도 폐쇄</p>
                    </div>
                  </div>
                  <div className="gridCol">
                    <div className="gridColInner">
                      <img src="/img/ear/ear1-7.png" alt="" />
                      <p className="text">외상성 고막 천공</p>
                    </div>
                  </div>
                </Rotate>
              </div>
            </div>
            <div className={tabNum === 1 ? "tab active" : "tab"}>
              <div className="row">
                <Fade>
                  <div className="col-md-6 text-center">
                    <img
                      src="/img/ear/ear1-10.png"
                      className="img-fluid"
                      alt=""
                    />
                    <p className="font-24 mt-20">[정상 순음청력도]</p>
                  </div>
                  <div className="col-md-6 text-center">
                    <img
                      src="/img/ear/ear1-11.png"
                      className="img-fluid"
                      alt=""
                    />
                    <p className="font-24 mt-20">
                      [감각신경성 난청의 순음청력도]
                    </p>
                  </div>
                </Fade>
              </div>

              <Jw className="mt-100">
                <div className="row no-gutters">
                  <Rotate top left>
                    <div className="col-md-6 mb-100">
                      <div className="row no-gutters">
                        <div className="col-5">
                          <h1 className="font-28 jwh mb-20">
                            <img
                              src="/img/ico-plus.svg"
                              className="jwp"
                              alt=""
                            />{" "}
                            유전질환
                          </h1>
                        </div>
                        <div className="col-7">
                          <ul className="list-unstyled">
                            <li className="font-24">Alport 증후군</li>
                            <li className="font-24">신경섬유종</li>
                            <li className="font-24">Crouzon</li>
                            <li className="font-24">Cockayne 증후군</li>
                            <li className="font-24">Klippel - Feil 증후군</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-100">
                      <div className="row no-gutters">
                        <div className="col-5">
                          <h1 className="font-28 jwh mb-20">
                            <img
                              src="/img/ico-plus.svg"
                              className="jwp"
                              alt=""
                            />{" "}
                            비유성질환
                          </h1>
                        </div>
                        <div className="col-7">
                          <ul className="list-unstyled">
                            <li className="font-24">산전감기</li>
                            <li className="font-24">이독성약물부작용</li>
                            <li className="font-24">세균성-미로염, 수막염</li>
                            <li className="font-24">노인성 난청</li>
                            <li className="font-24">메니에르병</li>
                            <li className="font-24">
                              대사이상(갑상선 기능 이상)
                            </li>
                            <li className="font-24">청신경 종양</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Rotate>
                </div>
              </Jw>
            </div>
          </div>
        </div>
        <div className="wrapper mb-100">
          <div className="row no-gutters">
            <div className="col-md-3">
              <Fade>
                <h1 className="font-45 color-primary xs-to-center mb-100">
                  난청 검사
                </h1>
              </Fade>
            </div>
            <div className="col-md-9">
              <Fade>
                <div className="row mb-50">
                  <div className="col">
                    <img
                      src="/img/ear/ear1-12.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="col">
                    <img
                      src="/img/ear/ear1-13.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </Fade>
              <Fade>
                <div className="row mb-50">
                  <div className="col">
                    <img
                      src="/img/ear/titan.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="col">
                    <img
                      src="/img/ear/ad629.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </Fade>
              <Fade>
                <h2 className="font-24 mb-60">
                  여러 원인에 의해 난청이 발생할 수 있기 때문에 보다 정확한
                  검사를 위해 DMC하나이비인후과에서는 대학 병원 수준의 최신 검사
                  기기를 구비하고 있습니다.
                </h2>
              </Fade>
              <Slide right cascade>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    <strong>순음청력검사: </strong> 난청의 정도와 종류를 파악
                  </p>
                </div>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    <strong>어음청력검사 : </strong> 선별된 단어를 통해 난청
                    상태 파악
                  </p>
                </div>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    <strong>임피던스 청력검사 : </strong> 고막, 중이강, 이관
                    등의 상태 확인
                  </p>
                </div>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    <strong>이음향방사검사 : </strong> 달팽이관의 손상을 확인
                  </p>
                </div>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    <strong>뇌간유발반응 청력검사 : </strong> 고도 난청일 경우,
                    순음청력검사가 어려운 경우 사용
                  </p>
                </div>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    <strong>컴퓨터 단층 촬영 (CT)</strong>
                  </p>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ear1;
