import axios from "axios";
// import { API_URL } from "../constants";

// export const instance = axios.create({
//   baseURL: "http://localhost:8080",
//   timeout: 5000,
// });

export const instance = axios.create({
  baseURL: "https://api.dmchana.co.kr",
  timeout: 5000,
});

const CancelToken = axios.CancelToken;
let cancel;

instance.interceptors.request.use(
  (config) => {
    // if (cancel) {
    //   cancel(); // cancel request
    // }

    // config.cancelToken = new CancelToken(function executor(c) {
    //   cancel = c;
    // });

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// instance.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
//     if (
//       error.response &&
//       // eslint-disable-next-line no-underscore-dangle
//       !originalRequest._retry &&
//       (error.response.status === 406 ||
//         error.response.status === 403 ||
//         error.response.status === 401)
//     ) {
//       // eslint-disable-next-line no-underscore-dangle
//       originalRequest._retry = true;
//       //   const userTokenInfo = JSON.parse(
//       //     localStorage.getItem('gogo_current_user'),
//       //   );
//       //   const responseData = await instance.post(`${API_URL}/auth/refresh`, {
//       //     refreshToken: `${userTokenInfo.refreshToken}`,
//       //   });
//       //   const item = {
//       //     accessToken: responseData.data.accessToken,
//       //     refreshToken: responseData.data.refreshToken,
//       //     tokenType: responseData.data.tokenType,
//       //     date: 'date',
//       //     id: 1,
//       //     img: '',
//       //     role: 0,
//       //     title: 'title',
//       //   };
//       //   setCurrentUser(item);

//       //   instance.defaults.headers.Authorization = `${responseData.data.tokenType}${responseData.data.accessToken}`;

//       return instance(originalRequest);
//     }
//     return Promise.reject(error);
//   }
// );

// export default instance;
