import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

// import { Parallax } from "react-scroll-parallax";
// import Image from "../../components/Image";

import { Parallax } from "react-parallax";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

import ResvRodal from "../../components/resvRodal";

const Dl = styled.dl`
  position: relative;
  dt {
    position: absolute;
    span {
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      margin-right: 11px;
      &.red {
        background: #f07777;
      }
      &.blue {
        background: #8db3df;
      }
      &.green {
        background: #99d1a8;
      }
      &.yellow {
        background: #ebcd50;
      }
    }
  }
  dd {
    padding-left: var(--size-200px);
    @media (max-width: 767px) {
      padding-left: 30rem;
    }
  }
`;

const ListBottomContainer = styled.div`
  padding: var(--size-40px);
  color: var(--color-link);
  border: 3px solid var(--color-link);
  text-align: center;
  margin-bottom: var(--size-100px);
`;

function Neck2({ history, props }) {
  const dispatch = useDispatch();
  // 마우스 위치 기반 자동 가로 스크롤
  const horizontalScrollRef = useRef(null);
  const mX = useRef(0);
  const mX2 = useRef(0);
  const posX = useRef(0);
  const mPadd = 60;
  const damp = 20;
  // 요기까지
  const [visibleResv, setVisibleResv] = useState(false);
  const [tabNum, setTabNum] = useState(0);
  const [defaultTreatId, setDefaultTreatId] = useState(0);
  const [defaultTreatNm, setDefaultTreatNm] = useState(0);

  const handleResvDefaultValues = (id, tnm) => {
    if (id > 0 && tnm) {
      setDefaultTreatId(id);
      setDefaultTreatNm(tnm);
      setVisibleResv(true);
      console.log(id);
      console.log(tnm);
    }
  };

  // 예약완료
  const resvComplate = () => {
    setVisibleResv(false);
  };

  const resvClose = () => {
    setVisibleResv(!resvClose);
  };

  // scroll 버튼 처리
  const scrollDown = () => {
    const n = document.documentElement.clientHeight;
    window.scrollBy({ top: n, left: 0, behavior: "smooth" });
  };

  const setTab = (num) => {
    setTabNum(num);
  };

  // 자동 스크롤 리스너
  const horizontalScroll = (e) => {
    if (horizontalScrollRef.current) {
      const container = horizontalScrollRef.current;
      const parent = container.parentElement;
      const galW = container.offsetWidth;
      const galSW = container.scrollWidth;
      const wDiff = galSW / galW - 1; // widths difference ratio
      const mmAA = galW - mPadd * 2; // the mousemove available area
      const mmAAr = galW / mmAA;

      if (parent) {
        mX.current =
          e.pageX - parent.getBoundingClientRect().left - container.offsetLeft;

        mX2.current = Math.min(Math.max(0, mX.current - mPadd), mmAA) * mmAAr;
      }

      posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"

      setInterval(function () {
        posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"
        container.scrollLeft = posX.current * wDiff;
      }, 40);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>음성질환 클리닉</title>
      </Helmet>
      {visibleResv ? (
        <ResvRodal
          visibleResv={visibleResv}
          resvClose={resvClose}
          resvComplate={resvComplate}
          defaultTreatId={defaultTreatId}
          defaultTreatNm={defaultTreatNm}
        />
      ) : (
        ""
      )}
      <div className="topBar"></div>
      <div className="sv">
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={"/img/neck/neck2.png"}
          bgImageAlt=""
          strength={-300}
        >
          <div className="container">
            <Slide up>
              <div className="parallaxExplain btm">
                <div className="top">
                  <h1 className="pgTitle" style={{ marginBottom: "0" }}>
                    갑상선&middot;목멍울 클리닉
                  </h1>
                </div>
                <div className="mid">
                  <Row noGutters>
                    <Col>
                      <button
                        type="button"
                        className="btnNoStyle"
                        onClick={(e) =>
                          handleResvDefaultValues(11, "갑상선, 목멍울 클리닉")
                        }
                      >
                        <img src="/img/ico-chart.svg" alt="" />
                        <span>갑상선&middot;목멍울 클리닉 예약하기</span>
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Slide>
          </div>
          <div style={{ height: "100vh" }} />
          <button
            type="button"
            className="btnScrollDown"
            onClick={() => scrollDown()}
          >
            <img src="/img/scrollDown.svg" alt="" />
          </button>
        </Parallax>
        <div className="container">
          <div className="tabHeader mt-100 mb-100">
            <Slide right>
              <ul className="d-flex justify-content-between align-items-center">
                <li>
                  <button
                    type="button"
                    className={tabNum === 0 ? "active" : ""}
                    onClick={() => setTabNum(0)}
                  >
                    갑상선 클리닉
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={tabNum === 1 ? "active" : ""}
                    onClick={() => setTabNum(1)}
                  >
                    목멍울 클리닉
                  </button>
                </li>
              </ul>
            </Slide>
          </div>
        </div>

        <div className="tabContent mt-100">
          <div className={tabNum === 0 ? "tab active" : "tab"}>
            <div className="wrapper mb-100">
              <div className="row">
                <div className="col-md-2">
                  <Slide left>
                    <h2 className="color-primary font-30 mb-60 xs-to-center">
                      갑상선이란:
                    </h2>
                  </Slide>
                </div>
                <div className="col-md-3">
                  <Slide up>
                    <img
                      src="/img/neck/neck2-1.png"
                      className="img-fluid xs-mb-100"
                      alt=""
                    />
                  </Slide>
                </div>
                <div className="col-md-7">
                  <Slide right>
                    <p className="font-24">
                      <strong>
                        목의 전면부의 나비모양을 한 기관으로 갑상선 호르몬을
                        분비하는 기관
                      </strong>
                    </p>
                    <p className="font-24 mt-50">
                      <img src="/img/chk.svg" className="mr-20" alt="" />
                      갑상선 기능항진증 · 저하증 검사 가능
                    </p>
                    <p className="font-24">
                      <img src="/img/chk.svg" className="mr-20" alt="" />
                      갑상선 초음파 종양검사 가능
                    </p>
                    <p className="font-24">
                      <img src="/img/chk.svg" className="mr-20" alt="" />
                      갑상선 종양 초음파유도 세침검사 및 총-조직검사 가능
                    </p>
                    <p className="font-24">
                      <img src="/img/chk.svg" className="mr-20" alt="" />
                      당일 방문으로 초음파, 기능검사, 조직검사
                    </p>
                  </Slide>
                </div>
              </div>
            </div>
            <div className="blue-row">
              <div className="wrapper">
                <Row noGutters className="mb-50">
                  <div className="col-md-4">
                    <Slide up>
                      <h1 className="bar-text font-24 mb-30">
                        갑상선의 양성 결절 모두
                        <br />
                        치료해야 할까요?
                      </h1>
                    </Slide>
                  </div>
                  <div className="col-md-8">
                    <Slide up>
                      <p className="font-24">
                        양성으로 진단되었고 증상이 없는 결절은 치료할 필요가
                        없습니다. 하지만 양성 중에서는 크기가 해가 거듭할수록
                        커져서 미용상 문제가 되거나 목의 이물감 등을 유발할
                        경우는 드물지만 치료가 필요한 경우도 있습니다.
                      </p>
                    </Slide>
                  </div>
                </Row>
                <Row noGutters className="mb-50">
                  <div className="col-md-4">
                    <Slide up>
                      <h1 className="bar-text font-24 mb-30">
                        그러면 양성으로 진단은
                        <br />
                        어떻게 하나요?
                      </h1>
                    </Slide>
                  </div>
                  <div className="col-md-8">
                    <Slide up>
                      <p className="font-24 mb-20">
                        <strong>[갑상선 초음파 종양 검사]</strong>
                      </p>
                      <div className="row text-center">
                        <div className="col-xs-6" style={{ padding: "0 20px" }}>
                          <img
                            src="/img/neck/neck2-2.png"
                            className="img-fluid"
                            alt=""
                          />
                          <p className="font-24 color-primary mt-20">
                            양측 갑상선 종양
                          </p>
                        </div>
                        <div className="col-xs-6" style={{ padding: "0 20px" }}>
                          <img
                            src="/img/neck/neck2-3.png"
                            className="img-fluid"
                            alt=""
                          />
                          <p className="font-24 color-primary mt-20">
                            우측 갑상선 종양
                          </p>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <p className="font-24 mt-30">
                        <strong>
                          확실한 양성인 경우 초음파 소견만으로도 확진이
                          가능합니다.
                        </strong>
                        다만 초음파 소견 상 중간형결절(indeterminate nodule)
                        또는 악성(malignant) 소견이 보이는 경우는 조직 검사가
                        필요합니다. 보통 문제가 되는 경우는 중간형결절에서
                        생깁니다. 이런 중간형 결절의 경우 조직검사를 시행하고
                        결과에 따라 정기적인 검사를 권유하기도 합니다.
                      </p>
                    </Slide>
                  </div>
                </Row>
                <Row noGutters className="mb-50">
                  <div className="col-md-4">
                    <Slide left>
                      <h1 className="bar-text font-24 mb-30">
                        조직검사는
                        <br />
                        어떻게 하나요?
                      </h1>
                    </Slide>
                  </div>
                  <div className="col-md-8">
                    <Slide right>
                      <p className="font-24">
                        DMC 하나이비인후과에서는 대학병원 급의 조직검사를 결과
                        정확도를 보이고 있습니다. 초음파유도하 세침흡인
                        세포검사(USG FNA)를 비롯하여 초음파유도하 총-조직검사
                        (USG-Gun biopsy)를 제공하고 있습니다.
                      </p>
                      <div className="row text-center mt-50">
                        <div className="col-xs-6" style={{ padding: "0 20px" }}>
                          <img
                            src="/img/neck/neck2-4.png"
                            className="img-fluid"
                            alt=""
                          />
                          <p className="font-24 color-primary mt-20">
                            세포검사
                          </p>
                        </div>
                        <div className="col-xs-6" style={{ padding: "0 20px" }}>
                          <img
                            src="/img/neck/neck2-5.png"
                            className="img-fluid"
                            alt=""
                          />
                          <p className="font-24 color-primary mt-20">
                            총 조직검사
                          </p>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="mt-50">
                        <img
                          src="/img/neck/neck2-6.png"
                          className="img-fluid"
                          alt=""
                        />
                        <p className="font-24 mt-20">
                          좌측 갑상선의 초음파유도하 세침흡입검사 소견
                        </p>
                      </div>
                    </Slide>
                  </div>
                </Row>
                <Row noGutters className="mb-50">
                  <div className="col-md-4">
                    <Slide up>
                      <h1 className="bar-text font-24 mb-30">
                        갑상선 기능 이상은
                        <br />
                        무엇인가요?
                      </h1>
                    </Slide>
                  </div>
                  <div className="col-md-8">
                    <Fade right>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="item text-left">
                            <h1>
                              <img src="/img/ico-plus.svg" alt="" /> 갑상선 기능
                              항진증
                            </h1>
                            <ul className="list-unstyled">
                              <li>· 식욕 왕성하나 체중감소</li>
                              <li>· 가슴이 두근거리고 더위를 못 참음</li>
                              <li>· 불안 초조 예민 불면</li>
                              <li>· 피부 머리카락 손발톱 생리 변화</li>
                              <li>· 안구돌출</li>
                              <li>· 목 이물감 압박감</li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="item text-left">
                            <h1>
                              <img src="/img/ico-plus.svg" alt="" /> 갑상선 기능
                              저하증
                            </h1>
                            <ul className="list-unstyled">
                              <li>
                                · 거친 피부 안면부종 하지부종 서맥
                                <br />
                                (심장이 늦게 뜀)
                              </li>
                              <li>· 피로감 전신무기력 </li>
                              <li>· 추위에 약함</li>
                              <li>· 체중증가</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <p className="font-24 mt-50">
                        <strong>
                          갑상선 기능이상은 혈액검사를 통해 알아내며 1일 후
                          검사결과를 알 수 있습니다. 갑상선의 기능 이상은 갑상선
                          호르몬제 또는 갑상선 호르몬을 억제해주는 메티마졸 등과
                          같은 약제로 충분히 조절 가능합니다. 하지만 기능항진의
                          경우 자가 면역성 질환 등은 재발이 잦아 면밀한 정기
                          검진이 중요합니다. 또한 약물로 치료가 잘 되지 않는
                          경우는 동위원소를 이용하여 갑상선 조직을 파괴하거나
                          수술로 제거하는 경우도 종종 발생합니다.
                        </strong>
                      </p>
                    </Fade>
                  </div>
                </Row>
              </div>
            </div>
          </div>

          <div className={tabNum === 1 ? "tab active" : "tab"}>
            <div className="wrapper mb-100">
              <div className="row">
                <div className="col-md-4">
                  <Slide left>
                    <h2 className="color-primary font-30 mb-60 xs-to-center">
                      목 멍울이란?
                    </h2>
                  </Slide>
                </div>
                <div className="col-md-8">
                  <Slide bottom>
                    <p className="font-24">
                      혈관에 혈액이 흐르듯이 몸 안쪽의 림프액이 흘러다니는 관을
                      림프관이라고 하고 이 림프관들의 교차점 또는 정류장 같은
                      곳을 림프절이라고 합니다.
                      <br />
                      림프절과 같은 혼용해서 쓰는 말로는 임파선, 임파절 등이
                      있고 이것은 다 같은 말입니다.
                      <br />
                      경부 림프절(Cervical Lymph Node)의 경우 양측 경부에 약
                      150~300여개가 존재하고 있습니다.
                      <br />
                      또한 경부에는 림프절 말고도 감별해야 할 많은 질환이
                      있습니다.
                      <br />
                      <strong>
                        환자의 나이, 임상증상, 목 전체를 세밀하게 경부초음파와
                        후두내시경으로 통합검사 해야합니다.
                      </strong>
                    </p>
                    <div className="row mt-50 mb-50">
                      <div className="col-md-6">
                        <img
                          src="/img/neck/neck2-7.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="col-md-6">
                        <img
                          src="/img/neck/neck2-8.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <Dl className="font-24 mb-40">
                      <dt>
                        <span className="red"></span>턱 밑
                      </dt>
                      <dd>
                        턱 밑에 발생하는 멍울은 악하선이라고 불리는 침샘,
                        림파선, 피부연조직, 혈관과 감별해야 합니다. 침샘염,
                        침샘종양, 임파선비대, 임파선염, 임파선종양,
                        임파선전이암(이 경우는 침샘암, 구강암, 갑산선암,
                        인후두암의 전이) 지방종, 갑상설관낭종, 새열낭종,
                        경동맥종양, 경부농양이 관련있습니다.
                      </dd>
                    </Dl>
                    <Dl className="font-24 mb-40">
                      <dt>
                        <span className="blue"></span>가운데 목 앞
                      </dt>
                      <dd>
                        갑상선과 후두와 관련된 선천성종물이 주로 발병하는
                        곳입니다. 목 앞쪽의 피부 연족직의 지방종과 혈관종도
                        확인해야 합니다. 갑성선종양, 갑상선비대, 갑상설관낭종,
                        지방종, 표피낭종이 관련됩니다.
                      </dd>
                    </Dl>
                    <Dl className="font-24 mb-40">
                      <dt>
                        <span className="green"></span>목 양측 옆
                      </dt>
                      <dd>
                        흔하게 목 멍울이 잘 만져지는 곳으로 다른 어느 곳 보다
                        림파선염증과 림파선종양을 감별해야할 필요가 있습니다.
                        젊은 여성에게 호발하는 기구치병, 결핵성림파선염,
                        임파선전이암, 양성 림프절비대 등이 관련있습니다.
                      </dd>
                    </Dl>
                    <Dl className="font-24 mb-40">
                      <dt>
                        <span className="yellow"></span>쇄골 위
                      </dt>
                      <dd>
                        가장 자세히 면밀히 관찰해야 하는 부분으로 우측인지
                        좌측인지에 따라 가능성 있는 병도 달라집니다. 이 부위는
                        폐, 위장관관 관련된 전이암이 생길 수 있어 경부 뿐 아니라
                        전신을 같이 체크해봐야합니다. 쇄골 위 종물이 만져진다면
                        지체없이 내원해야 합니다. 림파선전이암-갑상선, 후두,
                        식도, 폐, 위, 대장의 암이 전이, 림파선염-결핵성,
                        기구치병, 신경종, 혈관종등이 관련됩니다.
                      </dd>
                    </Dl>
                    <ListBottomContainer className="font-28">
                      DMC하나이비인후과에서는 후두경 검사, 경부초음파,
                      경부초음파 하 세침세포검사, 경부초음파 하 총 조직검사 및
                      수술 또한 가능합니다.
                    </ListBottomContainer>
                  </Slide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Neck2;
