import { combineReducers } from "redux";

import { alert } from "./alert.reducer";
import { logo } from "./logo.reducer";

const rootReducer = combineReducers({
  alert,
  logo,
});

export default rootReducer;
