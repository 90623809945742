import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

import { Pannellum } from "pannellum-react";

import Lobby01 from '../../assets/img/robby01.jpg';
import Corr from '../../assets/img/corr.jpg';
import OperIn from '../../assets/img/oper_in.jpg';
import OperIn2 from '../../assets/img/oper_in2.jpg';
import OperIn1 from '../../assets/img/oper2.jpg';
import Voice from '../../assets/img/voice.jpg';
import Ph1 from '../../assets/img/ph1.jpg';
import Ph2 from '../../assets/img/ph2.jpg';
import Ct from '../../assets/img/ct.jpg';

import TLobby01 from '../../assets/img/thumb/robby01.jpg';
import TCorr from '../../assets/img/thumb/corr.jpg';
import TOperIn from '../../assets/img/thumb/oper_in.jpg';
import TOperIn2 from '../../assets/img/thumb/oper_in2.jpg';
import TOperIn1 from '../../assets/img/thumb/oper2.jpg';
import TVoice from '../../assets/img/thumb/voice.jpg';
import TPh1 from '../../assets/img/thumb/ph1.jpg';
import TPh2 from '../../assets/img/thumb/ph2.jpg';
import TCt from '../../assets/img/thumb/ct.jpg';

import Close from '../../assets/img/x.png';

const Photo360 = styled.div`
    position: fixed;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    .btnClose {
        position: fixed;
        bottom: 30px;
        right: 30px;
        z-index: 10000;
        width: 28px;
        height: 28px;
        background: #fff;
        border: 0;
        outline: 0;
        padding: 3px;
        box-sizing: border-box;
        img {
            width: 22px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 3px;
        }
    }
`;

const Centered = styled.div`
  padding-top: var(--size-200px);
  padding-bottom: var(--size-100px);
  .d-flex {
    overflow-x: auto;
  }
  .flexItem {
    padding: var(--size-10px);
  }
  .colBox {
    border: 2px solid var(--color-link);
    padding: var(--size-30px);
    min-height: 350px;
  }
  @media (max-width: 767px) {
    .imgPt {
      max-width: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .d-flex {
      margin-left: -15px;
      margin-right: -15px;
    }
    .flexItem img {
      width: 200px;
    }
  }
`;

const ItemRow = styled.div`
  .col-md-4 {
      margin-bottom: 10px;
  }
  button {
      background: transparent;
      border: 0;
      outline: 0;
      img {
          max-width: 100%;
          margin-bottom: 5px;
      }
  }
`;

function About8({ history, props }) {
    const [photo, setPhoto] = useState("");
  const dispatch = useDispatch();

  const setPhotoAction = (tg) => {
      if (tg === 'Corr') {
          setPhoto(Corr);
      } else if (tg === 'Lobby01') {
        setPhoto(Lobby01);
      } else if (tg === 'OperIn') {
        setPhoto(OperIn);
      } else if (tg === 'OperIn2') {
        setPhoto(OperIn2);
      } else if (tg === 'OperIn1') {
        setPhoto(OperIn1);
      } else if (tg === 'Voice') {
        setPhoto(Voice);
      } else if (tg === 'Ph1') {
        setPhoto(Ph1);
      } else if (tg === 'Ph2') {
        setPhoto(Ph2);
      } else if (tg === 'Ct') {
        setPhoto(Ct);
      }
      
  }

  const closePhotoAction = () => setPhoto('');

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>병원소개</title>
      </Helmet>
      <div className="topBar"></div>
      <div className="sv">
        <Centered className="wrapper">
          <ItemRow className="row">
              <div className="col-md-4">
                  <button type="button" onClick={() => setPhotoAction('Corr')}>
                    <img src={TCorr} alt="" />
                    복도
                    </button>
              </div>
              <div className="col-md-4"><button type="button" onClick={() => setPhotoAction('Lobby01')}><img src={TLobby01} alt="" />로비</button></div>
              <div className="col-md-4"><button type="button" onClick={() => setPhotoAction('OperIn')}><img src={TOperIn} alt="" />수술실 내부</button></div>
              <div className="col-md-4"><button type="button" onClick={() => setPhotoAction('OperIn2')}><img src={TOperIn2} alt="" />수액실</button></div>
              <div className="col-md-4"><button type="button" onClick={() => setPhotoAction('OperIn1')}><img src={TOperIn1} alt="" />어지럼증검사실</button></div>
              <div className="col-md-4"><button type="button" onClick={() => setPhotoAction('Voice')}><img src={TVoice} alt="" />음성검사실</button></div>
              <div className="col-md-4"><button type="button" onClick={() => setPhotoAction('Ph1')}><img src={TPh1} alt="" />입원실</button></div>
              <div className="col-md-4"><button type="button" onClick={() => setPhotoAction('Ph2')}><img src={TPh2} alt="" />청력검사실</button></div>
              <div className="col-md-4"><button type="button" onClick={() => setPhotoAction('Ct')}><img src={TCt} alt="" />CT실</button></div>
          </ItemRow>
        </Centered>
        {photo ? (
        <Photo360>
            <button type="button" className="btnClose" onClick={closePhotoAction}><img src={Close} alt="닫기" /></button>
          <Pannellum
            width="100%"
            height="100%"
            image={photo}
            pitch={10}
            yaw={180}
            hfov={110}
            autoLoad
            showZoomCtrl={false}
          />
        </Photo360>
        ) : null}
      </div>
    </>
  );
}

export default About8;
