import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

const Pagination = ({
  totalPage = 0,
  currentPage = 0,
  numberLimit = 5,
  lastIsActive = true,
  firstIsActive = true,
  onChangePage,
}) => {
  let startPoint = 1;
  let endPoint = numberLimit;

  if (numberLimit > totalPage) {
    startPoint = 1;
    endPoint = totalPage;
  } else if (currentPage <= parseInt(numberLimit / 2, 10)) {
    startPoint = 1;
    endPoint = numberLimit;
  } else if (currentPage + parseInt(numberLimit / 2, 10) <= totalPage) {
    startPoint = currentPage - parseInt(numberLimit / 2, 10);
    endPoint = currentPage + parseInt(numberLimit / 2, 10);
  } else {
    startPoint = totalPage - (numberLimit - 1);
    endPoint = totalPage;
  }
  startPoint = startPoint === 0 ? 1 : startPoint;
  const points = [];
  for (let i = startPoint; i <= endPoint; i += 1) {
    points.push(i);
  }

  const firstPageButtonClassName = currentPage <= 0 ? "disabled" : "";
  const lastPageButtonClassName =
    currentPage >= totalPage - 1 ? "disabled" : "";
  const prevPageButtonClassName = currentPage <= 0 ? "disabled" : "";
  const nextPageButtonClassName =
    currentPage >= totalPage - 1 ? "disabled" : "";

  return totalPage > 0 ? (
    <div className="paging d-flex justify-content-center mt-50 mb-50">
      {firstIsActive && (
        <div className={`page-item ${firstPageButtonClassName}`}>
          <a
            className="page-link first c-pointer"
            onClick={() => onChangePage(0)}
          >
            <img src="/img/p_prev.svg" alt="" />
          </a>
        </div>
      )}

      <div className={`page-item ${prevPageButtonClassName}`}>
        <a
          className="page-link prev c-pointer"
          onClick={() => onChangePage(currentPage - 1)}
        >
          <img src="/img/p_prev.svg" alt="" />
        </a>
      </div>
      {points.map((i) => {
        return (
          <div
            key={i}
            className={`page-item ${currentPage === i - 1 && "active"}`}
          >
            <a
              className="page-link c-pointer"
              onClick={() => onChangePage(i - 1)}
            >
              {i}
            </a>
          </div>
        );
      })}
      <div className={`page-item ${nextPageButtonClassName}`}>
        <a
          className="page-link next c-pointer"
          onClick={() => onChangePage(currentPage + 1)}
        >
          <img src="/img/p_next.svg" alt="" />
        </a>
      </div>
      {lastIsActive && (
        <div className={`page-item ${lastPageButtonClassName}`}>
          <a
            className="page-link last c-pointer"
            onClick={() => onChangePage(totalPage - 1)}
          >
            <img src="/img/p_next.svg" alt="" />
          </a>
        </div>
      )}
    </div>
  ) : (
    ""
  );
};

export default Pagination;
