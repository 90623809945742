import React, { useRef, useEffect, useState } from "react";
import { Spring } from "react-spring/renderprops";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";

import { instance } from "../_helpers";

import BannerRodal from "../components/BannerRodal";

const SiteContent = styled.div`
  min-height: var(--height-100vh);
  border: 1rem solid #b4001b;
  border: solid var(--size-10px) var(--color-link);
  padding: 16.6rem 5rem;
  padding: calc(var(--margin-top) - var(--size-10px)) var(--size-50px);
  transition: border-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 0.1s ease;
  height: var(--height-100vh);
  -webkit-transform: none !important;
  transform: none !important;

  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  opacity: 1;
  pointer-events: all;
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 100%;
  height: 100%;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0s;
  border: 1px solid #b2b2b2;
  @media (max-width: 1024px) {
    display: block;
    overflow-y: auto;
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  & > .gridItem {
    grid-column: auto;
    grid-row: auto;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;
    border-right: 1px solid #b2b2b2;
    @media (max-width: 1024px) {
      display: block;
      height: 350px;
    }
    & > .img {
      pointer-event: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -2;
      opacity: 0;
      transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
        background-size 1.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &:first-of-type {
      grid-coulmn: 1;
      & > .img {
        background: url("/img/tica101h20010134.png") no-repeat center;
        background-size: 100%;
      }
    }
    &:nth-of-type(2) {
      grid-coulmn: 2;
      & > .img {
        background: url("/img/8f56b9be1ba2b56f488b8b29af5121aa.png") no-repeat
          center;
        background-size: 100%;
      }
    }
    &:nth-of-type(3) {
      grid-coulmn: 3;
      & > .img {
        background: url("/img/tica101d19020786.png") no-repeat center;
        background-size: 100%;
      }
    }
    &:nth-of-type(4) {
      grid-coulmn: 4;
      & > .img {
        background: url("/img/189df769da6f7cbdc2740e5811e3c6fa.png") no-repeat
          center;
        background-size: 100%;
      }
    }
    &:last-of-type {
      border-right: 0;
      & > .img {
        background: url("/img/tica101g20020951.png") no-repeat center;
        background-size: 100%;
      }
    }
    & > .imgOver {
      pointer-event: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      z-index: -1;
    }
    &:hover {
      & > .img {
        opacity: 1;
        background-size: 150%;
      }
      & > .imgOver {
        opacity: 1;
      }
      & > .info {
        & > .title,
        & > .desc,
        & > .subName > li > a {
          color: #fff !important;
          .lv,
          .lh {
            background: #fff;
          }
        }
        & > .btn {
          transform: scale(1.2);
        }
      }
    }
    & > .info {
      pointer-event: none;
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: flex-start;
      height: 100%;
      color: #2a2a2a;
      color: var(--color-text);
      padding: 5rem 2rem;
      padding: var(--size-50px) var(--size-20px);
      transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
        visibility 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
      @media (max-width: 1024px) {
        padding: 2rem 2rem;
      }
      @media (min-width: 1025px) and (max-width: 1280px) {
        padding: 2rem 2rem;
      }
      & > .subName {
        position: absolute;
        bottom: var(--size-30px);
        & > li {
          margin-bottom: var(--size-10px);
          & > a {
            position: relative;
            color: #2a2a2a;
            font-size: 1.2vw;
            padding-left: var(--size-30px);
            @media (max-width: 720px) {
              font-size: 4vw;
            }
            @media (min-width: 721px) and (max-width: 1024px) {
              font-size: 2vw;
            }
            &:hover,
            &:active,
            &:focus {
              .lv {
                transform: rotate(90deg);
                opacity: 1;
              }
            }
          }
          .lv,
          .lh {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            width: var(--size-20px);
            height: 3px;
            background: var(--color-link);
            transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
              visibility 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
          }
          .lv {
            opacity: 0;
          }
        }
      }
      & > .title {
        font-size: var(--size-30px);
        font-size: 2.5vw;
        line-height: 1.2;
        font-weight: 400;
        color: #46524d;
        color: var(--color-logo);
        margin-top: 3rem;
        margin-top: var(--size-30px);
        -webkit-transition: font-weight 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: font-weight 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        @media (max-width: 720px) {
          margin-top: 2rem;
          margin-top: var(--size-20px);
          font-size: var(--size-50px);
          font-size: 8vw;
          font-weight: 700;
        }
        @media (min-width: 721px) and (max-width: 1024px) {
          font-size: 4vw;
        }
        @media (min-width: 1025px) and (max-width: 1280px) {
          margin-top: 1rem;
          margin-top: var(--size-10px);
        }
      }
      & > .desc {
        //display: none;
        width: 100%;
        font-size: var(--size-18px);
        font-weight: 400;
        margin-top: 5rem;
        margin-top: var(--size-50px);
        transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
          -webkit-transform 0s;
        @media (max-width: 1024px) {
          font-size: var(--size-36px);
        }
      }
      & > .btn {
        position: relative;
        display: -webkit-box;
        display: flex;
        width: 7.6rem;
        width: var(--size-76px);
        height: 7.6rem;
        height: var(--size-76px);
        min-height: 7.6rem;
        min-height: var(--size-76px);
        border-radius: 50%;
        background: #b4001b;
        background: var(--color-link);
        transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
        margin-top: auto;
        & > .h {
          width: 30%;
          height: 2px;
          background: #fff;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
        & > .v {
          width: 2px;
          height: 30%;
          background: #fff;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .gridItem {
      border-bottom: 1px solid #b2b2b2;
      &:last-of-type {
        border-bottom: 0;
        border-right: 1px solid #b2b2b2;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .gridItem {
      border-bottom: 1px solid #b2b2b2;
      &:last-of-type {
        border-right: 1px solid #b2b2b2;
      }
    }
  }
`;

function Index() {
  const history = useHistory();
  const [banner, setBanner] = useState([]);
  const [visibleBanner, setVisibleBanner] = useState(false);

  const [notShow, setNotShow] = useState(false);

  const [bannerWidth, setBannerWidth] = useState('500px');

  const notShowToday = localStorage.getItem("notShowToday");

  const notToday = () => {
    setNotShow(!notShow);
  };

  const resvCloseAction = () => {
    if (notShow) {
      let expires = new Date();
      expires = expires.setHours(expires.getHours() + 24);
      localStorage.setItem("notShowToday", expires);
    }
    setVisibleBanner(false);
    //document.getElementById(`banner_${idx}`).remove();
  };

  const resvClose = () => {
    setVisibleBanner(false);
    //document.getElementById(`banner_${idx}`).remove();
  };

  useEffect(() => {
    if (notShowToday && notShowToday > new Date()) return;
    if (!notShowToday) {
      instance
        .get(`/api/front/banner`)
        .then((res) => res.data)
        .then((data) => {
          console.log(data)
          setBanner(data);
          if (data.length > 0) {
            setBannerWidth('800px');
            setVisibleBanner(true);
          }
        });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>DMC하나이비인후과</title>
      </Helmet>
        <BannerRodal
          visibleBanner={visibleBanner}
          resvClose={resvClose}
          banner={banner}
          notToday={notToday}
          resvCloseAction={resvCloseAction}
          bannerWidth={bannerWidth}
        />
      <SiteContent>
        <ServiceGrid>
          <div className="gridItem" onClick={() => history.push("/nose/nose3")}>
            <div className="img"></div>
            <div className="imgOver"></div>
            <div className="info">
              <div className="title">코골이</div>
              <p className="desc">
                DMC하나이비인후과에서는 대학병원급의 CT 및 수면다원검사로 정확한
                검사 및 수술이 가능합니다.
              </p>
            </div>
          </div>
          <div className="gridItem" onClick={() => history.push("/nose/nose1")}>
            <div className="img"></div>
            <div className="imgOver"></div>
            <div className="info">
              <div className="title">코막힘수술</div>
              <p className="desc">
                정확한 원인을 파악하여 맞춤 치료를 제공하며 꼭 필요한 경우만
                수술을 진행합니다.
              </p>
            </div>
          </div>
          <div className="gridItem" onClick={() => history.push("/ear/ear2")}>
            <div className="img"></div>
            <div className="imgOver"></div>
            <div className="info">
              <div className="title">어지러움</div>
              <p className="desc">
                DMC하나이비인후과에서는 꼭 필요한 검사로 정확한 원인을 찾아
                맞춤치료를 제공합니다.
              </p>
            </div>
          </div>
          <div className="gridItem" onClick={() => history.push("/nose/nose2")}>
            <div className="img"></div>
            <div className="imgOver"></div>
            <div className="info">
              <div className="title">알레르기</div>
              <p className="desc">
                맑은 콧물, 재채기, 가려움, 코막힘 등 DMC하나이비인후과에서
                지속적이고 체계적인 알레르기 치료를 받아보세요.
              </p>
            </div>
          </div>
          <div className="gridItem" onClick={() => history.push("/neck/neck1")}>
            <div className="img"></div>
            <div className="imgOver"></div>
            <div className="info">
              <div className="title">음성클리닉</div>
              <p className="desc">
                DMC하나이비인후과에서는 대학병원에서 가능한 전문 검사 기기와
                전문 음성치료사의 음성치료를 제공합니다.
              </p>
            </div>
          </div>
        </ServiceGrid>
      </SiteContent>
    </>
  );
}

export default Index;
