import { logoConstants } from "../_constants";

export function logo(state = {}, action) {
  switch (action.type) {
    case logoConstants.LOGO:
      return {
        type: "LOGO",
        message: action.message,
      };
    default:
      return state;
  }
}
