import React, { useState, useEffect } from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
// import "react-datepicker/dist/react-datepicker.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Input,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import styled from "styled-components";

const Title = styled.div`
  border-bottom: 1px solid #efefef;
  padding-bottom: 10px;
`;

const Content = styled.div`
  text-align: center;
  img {
    max-width: 100% !important;
    width: auto !important;
  }
`;

const Close = styled.button`
  position: absolute;
  right: 0;
  bottom: 0;
  border: 0;
  outline: 0;
  width: 100px;
  display: block;
  background: var(--color-link);
  color: #fff;
`;

const RodalBottom = styled.div`
  position: relative;
  border-top: 1px solid #efefef;
  padding-top: 10px;
  label {
    margin-left: 10px;
  }
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  .c-sub {

  }
  @media (max-width: 767px) {
    display: block;
    .c-sub {}
  }
`;

function BannerRodal({ visibleBanner, resvClose, banner, notToday, resvCloseAction, bannerWidth }) {
  useEffect(() => {}, []);

  return (
    <Rodal
      visible={visibleBanner}
      onClose={resvClose}
      animation="slideUp"
      closeOnEsc
      customStyles={{
        borderRadius: "5px",
        height: "auto",
        bottom: "auto",
        top: "50%",
        transform: "translateY(-50%)",
        width: bannerWidth,
      }}
    >
      <Title>알림</Title>
      {/* <Title dangerouslySetInnerHTML={{ __html: banner.title }} /> */}
      <ContentContainer>
      {banner.map((item, index) => (
        <div className="c-sub" key={index}>
          <Content dangerouslySetInnerHTML={{ __html: item.content }} />
        </div>
      ))}
      </ContentContainer>
      
      <RodalBottom>
        <input
          type="checkbox"
          id="chk"
          className="font-20"
          onClick={() => notToday()}
        />
        <label className="font-20" htmlFor="chk">
          오늘 하루 보지 않기
        </label>
        <Close type="button" className="font-20" onClick={resvCloseAction}>
          닫기
        </Close>
      </RodalBottom>
    </Rodal>
  );
}

export default BannerRodal;
