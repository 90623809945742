import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

// import { Parallax } from "react-scroll-parallax";
// import Image from "../../components/Image";

import { Parallax } from "react-parallax";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";

import ResvRodal from "../../components/resvRodal";

import "./nose1.css";

const TitleBullet = styled.h1`
  position: relative;
  font-size: var(--size-36px);
  margin-bottom: var(--size-50px);
  & > span {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: -2px;
      left: 0;
      width: var(--size-6px);
      height: var(--size-6px);
      display: block;
      border-radius: 100%;
      background: #0076be;
    }
  }
  @media (max-width: 720px) {
    font-size: var(--size-45px);
  }
`;

const Circle = styled.div`
  width: 21rem;
  height: 21rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  text-align: center;
  background: #fff;
  color: var(--color-link);
  font-size: var(--size-24px);
  @media (max-width: 767px) {
    width: 28rem;
    height: 28rem;
    font-size: var(--size-36px);
  }
`;

const CircleBtm = styled.p`
  font-size: var(--size-25px);
  margin-top: var(--size-50px);
  @media (max-width: 720px) {
    font-size: var(--size-40px);
  }
`;

const ListBottomContainer = styled.div`
  padding: var(--size-40px);
  color: var(--color-link);
  border: 3px solid var(--color-link);
  text-align: center;
  margin-left: 400px;
  margin-right: 50px;
  margin-bottom: var(--size-100px);
  @media (max-width: 720px) {
    margin-left: var(--size-20px);
    margin-right: var(--size-20px);
  }
`;

const WhiteBox = styled.div`
  border-radius: 5px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  padding: var(--size-60px);
`;

const FlexInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .nImg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
`;

function Nose3({ history, props }) {
  const dispatch = useDispatch();
  // 마우스 위치 기반 자동 가로 스크롤
  const horizontalScrollRef = useRef(null);
  const mX = useRef(0);
  const mX2 = useRef(0);
  const posX = useRef(0);
  const mPadd = 60;
  const damp = 20;
  const horizontalScrollRef2 = useRef(null);
  const mX12 = useRef(0);
  const mX22 = useRef(0);
  const posX2 = useRef(0);
  const mPadd2 = 60;
  const damp2 = 20;
  // 요기까지
  const [visibleResv, setVisibleResv] = useState(false);
  const [defaultTreatId, setDefaultTreatId] = useState(0);
  const [defaultTreatNm, setDefaultTreatNm] = useState(0);

  // const handleResvDefaultValues = (id, tnm) => {
  //   if (id > 0 && tnm) {
  //     setDefaultTreatId(id);
  //     setDefaultTreatNm(tnm);
  //     setVisibleResv(true);
  //     console.log(id);
  //     console.log(tnm);
  //   }
  // };

  const handleResvDefaultValues = () => {
    setVisibleResv(true);
  };

  // 예약완료
  const resvComplate = () => {
    setVisibleResv(false);
  };

  const resvClose = () => {
    setVisibleResv(!resvClose);
  };

  // 자동 스크롤 리스너
  const horizontalScroll = (e) => {
    if (horizontalScrollRef.current) {
      const container = horizontalScrollRef.current;
      const parent = container.parentElement;
      const galW = container.offsetWidth;
      const galSW = container.scrollWidth;
      const wDiff = galSW / galW - 1; // widths difference ratio
      const mmAA = galW - mPadd * 2; // the mousemove available area
      const mmAAr = galW / mmAA;

      if (parent) {
        mX.current =
          e.pageX - parent.getBoundingClientRect().left - container.offsetLeft;

        mX2.current = Math.min(Math.max(0, mX.current - mPadd), mmAA) * mmAAr;
      }

      posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"

      setInterval(function () {
        posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"
        container.scrollLeft = posX.current * wDiff;
      }, 40);
    }
  };
  const horizontalScroll2 = (e) => {
    if (horizontalScrollRef2.current) {
      const container2 = horizontalScrollRef2.current;
      const parent2 = container2.parentElement;
      const galW2 = container2.offsetWidth;
      const galSW2 = container2.scrollWidth;
      const wDiff2 = galSW2 / galW2 - 1; // widths difference ratio
      const mmAA2 = galW2 - mPadd2 * 2; // the mousemove available area
      const mmAAr2 = galW2 / mmAA2;

      if (parent2) {
        mX12.current =
          e.pageX -
          parent2.getBoundingClientRect().left -
          container2.offsetLeft;

        mX22.current =
          Math.min(Math.max(0, mX12.current - mPadd2), mmAA2) * mmAAr2;
      }

      posX2.current += (mX22.current - posX2.current) / damp2; // zeno's paradox equation "catching delay"

      setInterval(function () {
        posX2.current += (mX22.current - posX2.current) / damp; // zeno's paradox equation "catching delay"
        container2.scrollLeft = posX2.current * wDiff2;
      }, 40);
    }
  };

  // scroll 버튼 처리
  const scrollDown = () => {
    const n = document.documentElement.clientHeight;
    window.scrollBy({ top: n, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>코골이 수면무호흡 클리닉</title>
      </Helmet>
      {visibleResv ? (
        <ResvRodal
          visibleResv={visibleResv}
          resvClose={resvClose}
          resvComplate={resvComplate}
          defaultTreatId={defaultTreatId}
          defaultTreatNm={defaultTreatNm}
        />
      ) : (
        ""
      )}
      <div className="topBar"></div>
      <div className="sv">
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={"/img/nose/nose3.png"}
          bgImageAlt=""
          strength={-300}
        >
          <div className="container">
            <Slide up>
              <div className="parallaxExplain btm">
                <div className="top">
                  <h1 className="pgTitle">코골이 수면무호흡 클리닉</h1>
                  <p className="pgTitleExp">
                    DMC하나이비인후과에서는 대학병원 급의 CT 및 수면다원검사로
                    정확한 검사와 (비수술적 치료-양압기, 구강내 장치), 수술적
                    치료까지 가능한 병원입니다.
                  </p>
                </div>
                <div className="mid">
                  <Row noGutters>
                    <Col>
                      <button
                        type="button"
                        className="btnNoStyle"
                        // onClick={(e) =>
                        //   handleResvDefaultValues(
                        //     15,
                        //     "코골이 수면무호흡 클리닉"
                        //   )
                        // }
                        onClick={(e) => handleResvDefaultValues()}
                      >
                        <img src="/img/ico-chart.svg" alt="" />
                        <span>코골이 수면무호흡 클리닉 예약하기</span>
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Slide>
          </div>
          <div style={{ height: "100vh" }} />
          <button
            type="button"
            className="btnScrollDown"
            onClick={() => scrollDown()}
          >
            <img src="/img/scrollDown.svg" alt="" />
          </button>
        </Parallax>
        <div className="wrapper mt-50 mb-50 wordKeep">
          <Row className="mb-50">
            <Col md={6}>
              <Slide left cascade>
                <img src="/img/nose/hos-1.png" alt="" className="img-fluid" />
              </Slide>
            </Col>
            <Col md={6}>
              <Slide right cascade>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    불편한 입원실에서의 검사가 아닌 수면환자만을 위한 호텔식
                    설비
                  </p>
                </div>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    매일 매일 세탁하는 침구류
                  </p>
                </div>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    전문업체에서 케어 받는 집먼지 진드기 관리 서비스
                  </p>
                </div>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    검사 후 25종 바이러스(코로나바이러스를 포함) 제거하는 소독
                    시스템
                  </p>
                </div>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    최고 등급의 ACE 침대 메트리스
                  </p>
                </div>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    매일 제공되는 호텔 식 어메니티
                  </p>
                </div>
                <div>
                  <p className="font-24 text-center color-primary mt-50 wordKeep">
                    DMC하나이비인후과에서는{" "}
                    <strong>
                      뇌파부터 안구움직임, 호흡곡선, 호흡노력, 심전도,
                      산소포화도, 하지(다리)움직임
                    </strong>
                    까지 간이검사가 아닌 <strong>Full phase 검사</strong>를
                    제공합니다. 이를 토대로 보다 체계적인 치료를 계획할 수
                    있습니다.
                  </p>
                </div>
              </Slide>
            </Col>
          </Row>
          <Row>
            <Col sm={4} className="text-center">
              <Slide right cascade>
                <img src="/img/nose/hos-2.png" alt="" className="img-fluid" />
                <h1 className="font-24 mt-20">
                  <strong>양압기</strong> (수동양압기, 자동양압기, BIPAP)
                </h1>
                <p className="font-20 color-primary wordKeep mt-20">
                  디엠씨 하나이비인후과에서는
                  <br />
                  최고 사양의 양압기를 처방 받을 수 있습니다.
                </p>
              </Slide>
            </Col>
            <Col sm={4} className="text-center">
              <Slide right cascade>
                <img src="/img/nose/hos-3.png" alt="" className="img-fluid" />
                <h1 className="font-24 mt-20">
                  <strong>마스크</strong>
                </h1>
                <p className="font-20 color-primary wordKeep mt-20">
                  각 환자 분께 적합한 마스크를
                  <br />
                  착용 해 보고 고를 수 있습니다
                </p>
              </Slide>
            </Col>
            <Col sm={4} className="text-center">
              <Slide right cascade>
                <img
                  src="/img/nose/hos-4.png"
                  alt=""
                  className="img-fluid nImg"
                />
                <h1 className="font-24 mt-20">
                  <strong>구강내 장치</strong>
                </h1>
              </Slide>
            </Col>
          </Row>
        </div>
        <div className="blue-row">
          <div className="wrapper">
            <Slide bottom cascade>
              <Row>
                <Col md={4}>
                  <h1 className="bar-text font-24 mb-30">
                    코골이와 수면 무호흡 증상과 합병증
                  </h1>
                </Col>
                <Col md={8}>
                  <img
                    src="/img/nose/hos-5.png"
                    alt=""
                    className="img-fluid nImg"
                  />
                </Col>
              </Row>
            </Slide>
          </div>
        </div>
        <div className="wrapper mt-50 mb-100">
          <Slide left cascade>
            <h1 className="bar-text font-24 mb-30">코골이 수면무호흡 원인</h1>
          </Slide>
          <Row style={{ flexWrap: "wrap" }}>
            <Col md={3}>
              <FlexInner>
                <Slide right cascade>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    숨을 쉬는 곳(상기도)의 좁아짐 (후두 부종)
                  </p>
                  <img
                    src="/img/nose/nose3-2.png"
                    alt=""
                    className="img-fluid nImg"
                  />
                </Slide>
              </FlexInner>
            </Col>
            <Col md={3}>
              <FlexInner>
                <Slide right cascade>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    코를 막히게 하는 여러 질환
                  </p>
                  <p className="font-20 color-primary">
                    알레르기 비염, 비중격만곡증 부비동염 (축농증), 부비동 및
                    비강 물혹(풀립)
                  </p>
                  <img
                    src="/img/nose/nose3-4.png"
                    alt=""
                    className="img-fluid nImg"
                  />
                </Slide>
              </FlexInner>
            </Col>
            <Col md={3}>
              <FlexInner>
                <Slide right cascade>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    편도와 아데노이드 (특히 소아)
                  </p>
                  <img
                    src="/img/nose/nose3-1.png"
                    alt=""
                    className="img-fluid nImg"
                  />
                </Slide>
              </FlexInner>
            </Col>
            <Col md={3}>
              <Slide right cascade>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    입천장의 연구개 늘어짐
                  </p>
                </div>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    아래턱이(하악)이 작거나 뒤로 밀린 경우
                  </p>
                </div>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    후두에 이상이 있는 경우
                  </p>
                </div>
              </Slide>
            </Col>
          </Row>
        </div>
        <div className="wrapper mb-100">
          <Row>
            <Col md={4}>
              <Slide left cascade>
                <h1 className="bar-text font-24 mb-30">
                  코골이 수면무호흡 검사 및 진단
                </h1>
              </Slide>
            </Col>
            <Col md={4}>
              <Slide right cascade>
                <img src="/img/nose/hos-6.png" alt="" className="img-fluid" />
              </Slide>
            </Col>
            <Col md={4}>
              <Slide right cascade>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    내시경 진료
                    <br />
                    <span className="font-20 font-lighter">
                      비강 내시경과 후두 내시경으로 숨을 쉬는 곳의 해부학적 이상
                      소견을 확인합니다.
                    </span>
                  </p>
                </div>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    컴퓨터 단층 촬영(CT)
                  </p>
                </div>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    음향 비강 통기도 검사
                  </p>
                </div>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    알레르기 검사
                  </p>
                </div>
                <div>
                  <p className="font-24">
                    <img src="/img/chk2.svg" className="mr-10" alt="" />
                    수면다원 검사
                  </p>
                </div>
              </Slide>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Nose3;
