import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { logoActions } from "../_actions";

import styled from "styled-components";

import ReactCardFlip from "react-card-flip";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";

import ResvRodal from "../components/resvRodal";

const Sub = styled.div`
  position: relative;
`;

const NormalLayout = ({ children }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [mNavActive, setMNavActive] = useState(false);
  const [mNavGone, setMNavGone] = useState(false);
  const [sub0, setSub0] = useState(false);
  const [sub1, setSub1] = useState(false);
  const [sub2, setSub2] = useState(false);
  const [sub3, setSub3] = useState(false);
  const [sub4, setSub4] = useState(false);
  const [sub5, setSub5] = useState(false);
  const [frShow, setFrShow] = useState(true);

  const [isFlipped, setIsFlipped] = useState(false);

  const [visibleResv, setVisibleResv] = useState(false);
  const [defaultTreatId, setDefaultTreatId] = useState(0);
  const [defaultTreatNm, setDefaultTreatNm] = useState(0);

  const logo = useSelector((state) => state.logo);

  const mainNavAction = (pgNm) => {
    setFrShow(true);
    setSub0(false);
    setSub1(false);
    setSub2(false);
    setSub3(false);
    setSub4(false);
    setSub5(false);
    setMNavActive(false);
    history.push(pgNm);
  };

  // 예약완료
  const resvComplate = () => {
    setVisibleResv(false);
  };

  const resvClose = () => {
    setVisibleResv(!resvClose);
  };

  const handleResvDefaultValues = () => {
    setVisibleResv(true);
  };

  const mNav = () => {
    setMNavActive(!mNavActive);
    setMNavGone(true);
    setSub0(false);
    setSub1(false);
    setSub2(false);
    setSub3(false);
    setSub4(false);
    setSub5(false);
    setIsFlipped(false);
  };

  const handleFlip = (e, num) => {
    e.preventDefault();
    switch (num) {
      case 0:
        setFrShow(false);
        setSub0(true);
        break;
      case 1:
        setFrShow(false);
        setSub1(true);
        break;
      case 2:
        setFrShow(false);
        setSub2(true);
        break;
      case 3:
        setFrShow(false);
        setSub3(true);
        break;
      case 4:
        setFrShow(false);
        setSub4(true);
        break;
      case 5:
        setFrShow(false);
        setSub5(true);
        break;
      default:
        break;
    }
    setIsFlipped(!isFlipped);
  };

  const flipBack = (e) => {
    e.preventDefault();
    setSub0(false);
    setSub1(false);
    setSub2(false);
    setSub3(false);
    setSub4(false);
    setSub5(false);

    setFrShow(true);
    setIsFlipped(false);
  };

  const movePage = (pgNm) => {
    setFrShow(true);
    setSub0(false);
    setSub1(false);
    setSub2(false);
    setSub3(false);
    setSub4(false);
    setSub5(false);
    setMNavActive(false);
    history.push(pgNm);
  };

  const goToMain = () => {
    dispatch(logoActions.logo(""));
  };

  useEffect(() => {}, []);

  return (
    <>
      {visibleResv ? (
        <ResvRodal
          visibleResv={visibleResv}
          resvClose={resvClose}
          resvComplate={resvComplate}
          defaultTreatId={defaultTreatId}
          defaultTreatNm={defaultTreatNm}
        />
      ) : (
        ""
      )}
      <div className="contentWrapper">
        <header className={mNavActive ? "header" : "header bg"}>
          <div className="headerContent">
            <div
              className={mNavActive ? `logoContainer active` : `logoContainer`}
            >
              <Link to="/" onClick={() => goToMain()} className="logoContent">
                {logo.message === "1" ? (
                  <img
                    src="/img/subLogo.svg"
                    className="siteLogo2"
                    alt="DMC하나이비인후과"
                  />
                ) : (
                  <img
                    src="/img/logo.svg"
                    className="siteLogo"
                    alt="DMC하나이비인후과"
                  />
                )}
              </Link>
            </div>
            <ul className={mNavActive ? "navbar active" : "navbar"}>
              <li>
                {/* <Link to="/about/about1">병원소개</Link> */}
                <button
                  type="button"
                  onClick={() => mainNavAction("/about/about1")}
                >
                  병원소개
                </button>
              </li>
              <li>
                {/* <Link to="/about/about3">진료 안내</Link> */}
                <button
                  type="button"
                  onClick={() => mainNavAction("/about/about3")}
                >
                  진료안내
                </button>
              </li>
              <li>
                {/* <Link to="/about/about2">의료진 소개</Link> */}
                <button
                  type="button"
                  onClick={() => mainNavAction("/about/about2")}
                >
                  의료진 소개
                </button>
              </li>
              <li>
                {/* <Link to="/about/about7">오시는 길</Link> */}
                <button
                  type="button"
                  onClick={() => mainNavAction("/about/about7")}
                >
                  오시는 길
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={(e) => handleResvDefaultValues()}
                >
                  예약하기
                </button>
              </li>
            </ul>
            {/* <Link to="/about" className="intro">
              예약하기
            </Link> */}
            <button
              className={mNavActive ? `btnMenu active` : `btnMenu`}
              onClick={() => mNav()}
              type="button"
            >
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </button>
          </div>
        </header>
        {children}
        <footer className={mNavActive ? `footer active` : `footer`}>
          <div className="footerContent">
            <div className="copyright">
              COPYRIGHT(C) 2021 HANA ENT HOSPITAL. ALL RIGHT RESERVED.
            </div>
            <div>
              대표전화 02-373-0075
              <br />
              개인정보책임자 최환
            </div>
            <div>서울특별시 서대문구 수색로 56, 성공타워1, 212호</div>
          </div>
        </footer>
      </div>
      <div className={mNavActive ? `mNav active` : `mNav`}>
        <div className="mNavInner">
          <div className="container-xl" style={{ position: "relative" }}>
            <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
              <div className="fr">
                {frShow ? (
                  <Bounce left cascade when={mNavGone}>
                    <div>
                      <div>
                        <button
                          onClick={(e) => handleFlip(e, 0)}
                          type="button"
                          className="btnNavItem font-45"
                        >
                          병원소개
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={(e) => handleFlip(e, 1)}
                          type="button"
                          className="btnNavItem font-45"
                        >
                          귀질환
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={(e) => handleFlip(e, 2)}
                          type="button"
                          className="btnNavItem font-45"
                        >
                          코질환
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={(e) => handleFlip(e, 3)}
                          type="button"
                          className="btnNavItem font-45"
                        >
                          목질환
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={(e) => handleFlip(e, 4)}
                          type="button"
                          className="btnNavItem font-45"
                        >
                          예방 접종 클리닉
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={(e) => handleFlip(e, 5)}
                          type="button"
                          className="btnNavItem font-45"
                        >
                          DMC하나클린수술시스템
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/board/event")}
                          type="button"
                          className="btnNavItem font-45"
                        >
                          이벤트
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btnNavItem font-45"
                          onClick={(e) => handleResvDefaultValues()}
                        >
                          예약하기
                        </button>
                      </div>
                    </div>
                  </Bounce>
                ) : (
                  ""
                )}
              </div>
              <div className="bk">
                {sub0 ? (
                  <Sub className="mNavSub0">
                    <div>
                      <div>
                        <button
                          onClick={(e) => flipBack(e)}
                          type="button"
                          className="btnNavItem btnNavBack font-lighter font-45"
                        >
                          <img
                            src="/img/nav_back.svg"
                            className="navBack"
                            alt=""
                          />
                          돌아가기
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/about/about1")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          병원 소개
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/about/about2")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          의료진 안내
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/about/about3")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          진료 안내
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/about/about4")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          첨단 장비
                        </button>
                      </div>
                      {/* <div>
                        <button
                          onClick={() => movePage("/about/about5")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          병원 둘러보기
                        </button>
                      </div> */}
                      <div>
                        <button
                          onClick={() => movePage("/about/about6")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          비급여 & 서류발급
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/about/about7")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          오시는 길
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/about/about8")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          병원 둘러보기
                        </button>
                      </div>
                    </div>
                  </Sub>
                ) : (
                  ""
                )}
                {sub1 ? (
                  <Sub className="mNavSub1">
                    <div>
                      <div>
                        <button
                          onClick={(e) => flipBack(e)}
                          type="button"
                          className="btnNavItem btnNavBack font-45"
                        >
                          <img
                            src="/img/nav_back.svg"
                            className="navBack"
                            alt=""
                          />
                          돌아가기
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/ear/ear1")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          난청 클리닉
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/ear/ear2")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          어지럼 클리닉
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/ear/ear3")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          보청기 클리닉
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/ear/ear4")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          청각장애 진단
                        </button>
                      </div>
                    </div>
                  </Sub>
                ) : (
                  ""
                )}
                {sub2 ? (
                  <Sub className="mNavSub2">
                    <div>
                      <div>
                        <button
                          onClick={(e) => flipBack(e)}
                          type="button"
                          className="btnNavItem btnNavBack font-45"
                        >
                          <img
                            src="/img/nav_back.svg"
                            className="navBack"
                            alt=""
                          />
                          돌아가기
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/nose/nose1")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          코막힘 수술 클리닉
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/nose/nose2")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          알레르기 클리닉
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/nose/nose3")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          코골이 수면 무호흡 클리닉
                        </button>
                      </div>
                    </div>
                  </Sub>
                ) : (
                  ""
                )}
                {sub3 ? (
                  <Sub className="mNavSub3">
                    <div>
                      <div>
                        <button
                          onClick={(e) => flipBack(e)}
                          type="button"
                          className="btnNavItem btnNavBack font-45"
                        >
                          <img
                            src="/img/nav_back.svg"
                            className="navBack"
                            alt=""
                          />
                          돌아가기
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/neck/neck1")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          음성 클리닉
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/neck/neck2")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          갑상선 클리닉
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/neck/neck3")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          편도결석 클리닉
                        </button>
                      </div>
                    </div>
                  </Sub>
                ) : (
                  ""
                )}
                {sub4 ? (
                  <Sub className="mNavSub4">
                    <div>
                      <div>
                        <button
                          onClick={(e) => flipBack(e)}
                          type="button"
                          className="btnNavItem btnNavBack font-45"
                        >
                          <img
                            src="/img/nav_back.svg"
                            className="navBack"
                            alt=""
                          />
                          돌아가기
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          onClick={() => movePage("/vaccination/vaccination")}
                          className="btnNavItem ml-60px font-45"
                        >
                          예방접종 & 수액클리닉
                        </button>
                      </div>
                    </div>
                  </Sub>
                ) : (
                  ""
                )}
                {sub5 ? (
                  <Sub className="mNavSub5">
                    <div>
                      <div>
                        <button
                          onClick={(e) => flipBack(e)}
                          type="button"
                          className="btnNavItem btnNavBack font-45"
                        >
                          <img
                            src="/img/nav_back.svg"
                            className="navBack"
                            alt=""
                          />
                          돌아가기
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => movePage("/clean/clean1")}
                          type="button"
                          className="btnNavItem ml-60px font-45"
                        >
                          감염 청정 병원
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          onClick={() => movePage("/clean/clean2")}
                          className="btnNavItem ml-60px font-45"
                        >
                          수술 후 관리
                        </button>
                      </div>
                    </div>
                  </Sub>
                ) : (
                  ""
                )}
              </div>
            </ReactCardFlip>
          </div>
        </div>
      </div>
    </>
  );
};

export default NormalLayout;
