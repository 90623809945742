import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

import { API_URL } from "../../_constants/defaultValue";
import { instance } from "../../_helpers";

import ThumbnailListing from "./ThumbnailListing";

const Centered = styled.div`
  padding-top: var(--size-200px);
  padding-bottom: var(--size-100px);
  .d-flex {
    overflow-x: auto;
  }
  .flexItem {
    padding: var(--size-10px);
  }
  .colBox {
    border: 2px solid var(--color-link);
    padding: var(--size-30px);
    min-height: 350px;
  }
  @media (max-width: 767px) {
    .imgPt {
      max-width: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .d-flex {
      margin-left: -15px;
      margin-right: -15px;
    }
    .flexItem img {
      width: 200px;
    }
  }
`;

function Event({ history, props }) {
  const dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedPageSize, setSelectedPageSize] = useState(0);
  const [page, setPage] = useState(0);
  const [items, setItems] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));

    async function getData() {
      instance
        .get(`/api/front/list`)
        .then((res) => res.data)
        .then((data) => {
          console.log(data.content.length);
          setTotalPages(data.totalPages);
          setItems(data.content);
          setTotalElements(data.totalElements);
        })
        .finally(() => setIsLoaded(true));
    }
    setPage(page);
    getData();
  }, [searchKeyword, selectedPageSize, page]);

  const startIndex = (currentPage - 1) * totalPages;
  const endIndex = currentPage * totalPages;

  return (
    <>
      <Helmet>
        <title>이벤트</title>
      </Helmet>
      <div className="topBar"></div>
      <div className="sv">
        <Fade bottom>
          <Centered className=" text-center">
            <h1 className="font-45 color-primary mb-20">이벤트</h1>
          </Centered>
          <div className="wrapper">
            <Slide up casecade>
              {items.length === 0 ? (
                <p className="text-center font-30">게시물이 없습니다.</p>
              ) : (
                <div className="row">
                  <ThumbnailListing
                    items={items}
                    currentPage={currentPage}
                    totalPage={totalPages}
                    onChangePage={setPage}
                  />
                </div>
              )}
            </Slide>
          </div>
        </Fade>
      </div>
    </>
  );
}

export default Event;
