import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
@font-face {
    font-family: 'Noto sans';
    font-weight: 200;
    src: url("/fonts/NotoSans-Light.woff2") format("woff2"),
        url("/fonts/NotoSans-Light.woff") format("woff"),
}
@font-face {
    font-family: 'Noto sans';
    font-weight: 300;
    src: url("/fonts/NotoSans-Regular.woff2") format("woff2"),
        url("/fonts/NotoSans-Regular.woff") format("woff"),
}
@font-face {
    font-family: 'Noto sans';
    font-weight: 500;
    src: url("/fonts/NotoSans-Medium.woff2") format("woff2"),
        url("/fonts/NotoSans-Medium.woff") format("woff"),
}
@font-face {
    font-family: 'Noto sans';
    font-weight: 700;
    src: url("/fonts/NotoSans-Bold.woff2") format("woff2"),
        url("/fonts/NotoSans-Bold.woff") format("woff"),
}
:root {
    --font-titles: "Noto sans",sans-serif;
    --font-text: "Noto sans",sans-serif;
    --color-background: #fff;
    --color-text: #36373C;
    --color-complement: #6b6b6b;
    --color-link: #0076BE;
    --color-navigation: #1e1e1e;
    --color-logo: #46524d;
    --color-danger: #A54646;
    --size-2px: 0.2rem;
    --size-4px: 0.4rem;
    --size-5px: 0.5rem;
    --size-6px: 0.6rem;
    --size-10px: 1rem;
    --size-14px: 1.4rem;
    --size-15px: 1.5rem;
    --size-16px: 1.6rem;
    --size-18px: 1.8rem;
    --size-20px: 2rem;
    --size-24px: 2.4rem;
    --size-25px: 2.5rem;
    --size-28px: 2.8rem;
    --size-30px: 3rem;
    --size-36px: 3.6rem;
    --size-40px: 4rem;
    --size-45px: 4.5rem;
    --size-50px: 5rem;
    --size-55px: 5.5rem;
    --size-60px: 6rem;
    --size-70px: 7rem;
    --size-75px: 7.5rem;
    --size-76px: 7.6rem;
    --size-80px: 8rem;
    --size-100px: 10rem;
    --size-120px: 12rem;
    --size-130px: 13rem;
    --size-150px: 15rem;
    --size-180px: 18rem;
    --size-200px: 20rem;
    --size-280px: 28rem;
    --index-col: 45rem;
    --margin-top: 17.6rem;
    --margin-side: 6rem;
}

.clearfix:before, .clearfix:after {
    display: table;
    content: '';
}
.clearfix:after {
    clear: both;
}

// 폰트
.font-20 {
    font-size: var(--size-20px);
}
.font-24 {
    font-size: var(--size-24px);
}
.font-28 {
    font-size: var(--size-28px);
}
.font-30 {
    font-size: var(--size-30px);
}
.font-36 {
    font-size: var(--size-36px);
}
.font-45 {
    font-size: var(--size-45px);
}
.dcNm {
    font-size: var(--size-30px);
}

@media (max-width: 767px) {
    .font-20 {
        font-size: var(--size-36px);
    }
    .font-24 {
        font-size: var(--size-40px);
    }
    .font-28 {
        font-size: var(--size-45px);
    }
    .font-30 {
        font-size: var(--size-45px);
    }
    .font-36 {
        font-size: var(--size-50px);
    }
    .dcNm {
        font-size: var(--size-50px);
        margin-top: var(--size-30px);
        margin-bottom: var(--size-30px);
    }
}

.font-normal {
    font-weight: 400;
}

.font-lighter {
    font-weight: 300;
}

// 마진
.mt-20 {
    margin-top: var(--size-20px);
}
.mt-30 {
    margin-top: var(--size-30px);
}
.mt-50 {
    margin-top: var(--size-50px);
}
.mt-80 {
    margin-top: var(--size-80px);
}
.mt-100 {
    margin-top: var(--size-100px);
}
.mt-130 {
    margin-top: var(--size-130px);
}
.mb-20 {
    margin-bottom: var(--size-20px);
}
.mb-30 {
    margin-bottom: var(--size-30px);
}
.mb-40 {
    margin-bottom: var(--size-40px);
}
.mb-50 {
    margin-bottom: var(--size-50px);
}
.mb-60 {
    margin-bottom: var(--size-60px);
}
.mb-100 {
    margin-bottom: var(--size-100px);
}
.mr-10 {
    margin-right: var(--size-10px);
}
.mr-15 {
    margin-right: var(--size-15px);
}
.mr-20 {
    margin-right: var(--size-20px);
}
.mr-30 {
    margin-right: var(--size-30px);
}
.ml-60 {
    margin-left: var(--size-60px);
}
.ml-60px {
    margin-left: 60px;
    @media(max-width: 767px) {
        margin-left: 40px;
    }
}

// 색상
.color-primary {
    color: var(--color-link);
}
.color-default {
    color: var(--color-text);
}
.color-danger {
    color: var(--color-danger);
}

// 헤딩 스타일
.bar-text {
    position: relative;
    padding-left: var(--size-120px);
    &:before {
        position: absolute;
        top: var(--size-15px);
        left: 0;
        width: var(--size-100px);
        height: 3px;
        content: '';
        background: var(--color-link);
    }
}

// 셀렉트
.dropdownFullWidth {
    width: 100%;
    .dropdown-toggle {
        width: 100%;
        font-size: var(--size-20px);
    }
    .dropdown-menu {
        width: 100%;
        .dropdown-item {
            font-size: var(--size-20px);
        }
    }
    .treatList {
        right: 0
    }
}
.react-datepicker {
    display: block;
    width: 100%;
    font-size: var(--size-20px) !important;
    .react-datepicker__month-container {
        float: none !important;
    }
    .react-datepicker__day-name:first-child, .react-datepicker__day:first-child {
        color: #A54646;
    }
    .react-datepicker__day-name {
        color: #373B3E;
    }
    .react-datepicker__day-name:last-child, .react-datepicker__day:last-child {
        color: #0076BE;
    }
    .react-datepicker__day[aria-disabled=true] {
        color: #ccc;
        font-weight: 300;
    }
}
.react-datepicker__day-names, .react-datepicker__week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 100%;
    text-align: center;
}
.react-datepicker__day-name, .react-datepicker__day {
    font-size: 20px;
    line-height: 40px;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
}
.react-datepicker__current-month {
    text-align: center;
    color: #373B3E;
    font-size: 24px;
    margin-top: 20px;
}


html {
    font-size: .977vmin;
    overflow-x: hidden;
    overflow-y: auto;
}
body {
    position: relative;
    --height-100vh: 100vh;
    width: 100vw;
    min-height: var(--height-100vh);
    font-family: Source Sans Pro,sans-serif;
    font-family: var(--font-text);
    font-weight: 400;
    font-style: normal;
    font-size: 2rem;
    line-height: 1.4;
    color: #2a2a2a;
    color: var(--color-text);
    background: #f7f7f7;
    background: var(--color-background);
    overflow-x: hidden;
}
* {
    box-sizing: border-box;
}
#root {
}
img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}
.contentWrapper {
    position: relative;
    min-height: var(--height-100vh)!important;
    &:before {
        content: "";
        position: fixed;
        display: block;
        top: 0;
        height: 100%;
        width: 1rem;
        width: var(--size-10px);
        background: #b4001b;
        background: var(--color-link);
        pointer-events: none;
        z-index: -1;
        left: 0;
    }
}
/** header **/
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1rem 0;
    padding: var(--size-10px) 0;
    pointer-events: none;
    z-index: 10;
    &.bg {
        // background: rgba(255,255,255,0.5);
    }
}
.headerContent {
    width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 4.5rem 6rem;
    padding: var(--size-45px) var(--size-60px);
    opacity: 1;
    visibility: visible;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.6s, transform 0s,
      opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0s;
}
.headerContent img {
    height: 5.5rem;
    height: var(--size-55px);
    @media(max-width: 767px) {
        height: 40px;
        margin-top: 20px;
    }
}
.logoContainer {
    position: relative;
    height: 6rem;
    height: var(--size-60px);
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
    // overflow-x: hidden;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    &.active {
        opacity: 0;
    }
}
.logoContainer .logoContent {
    display: -webkit-box;
    display: flex;
    height: 100%;
    -webkit-box-align: center;
    align-items: center;
    // -webkit-transform: translateX(calc(-100% + 15.2rem));
    // transform: translateX(calc(-100% + 15.2rem));
    // -webkit-transform: translateX(calc(-100% + var(--size-76px) * 2));
    // transform: translateX(calc(-100% + var(--size-76px) * 2));
    // -webkit-transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    // transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.siteLogo {
    height: 50%;
    width: auto;
    max-width: 700px;
    margin-right: 0;
    cursor: pointer;
    -webkit-transition: all .2s cubic-bezier(.215,.61,.355,1);
    transition: all .2s cubic-bezier(.215,.61,.355,1);
    margin-right: 2rem;
    margin-right: var(--size-20px);
    opacity: 1;
    visibility: visible;
}
@media (max-width: 1024px) {
    .logoContainer {
        height: var(--size-60px);
    }
}
@media (max-width: 1300px) {
    .siteLogo {
        width: 100%;
    }
}

/** nav **/
.navbar {
    align-self: center;
    display: -webkit-box;
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    margin-left: auto;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
      visibility 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    list-style: none;
    &.active li a {
        color: #fff;
    }
    &.sub li a, &.sub li button {
        color: #fff;
        text-shadow: 0 0 3px rgba(0,0,0,0.5)
    }
    &.scrolled {
        li a {
            color: #111;
            text-shadow: 0 0 3px rgba(255,255,255,0.5)
        }
    }
}
@media (max-width: 1024px) {
    .navbar {
        display: none;
    }
}
.navbar li {
    margin-right: 5rem;
    margin-right: var(--size-50px);
}
.navbar li a, .navbar li button {
    position: relative;
    display: block;
    height: 3.6rem;
    height: var(--size-36px);
    font-size: var(--size-20px);
    font-weight: 500;
    line-height: 3.6rem;
    line-height: var(--size-36px);
    color: var(--color-text);
    pointer-events: visible;
    background: transparent;
    border: 0;
    outline: 0;
    -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    // text-shadow: 1px 1px 10px white;
}
.navbar li:hover {
    color: #0076be;
}
@media (max-width: 1400px) {
    .navbar li {
        margin-right: 3rem;
        margin-right: var(--size-30px);
    }
}

.intro {
    position: relative;
    height: 7.6rem;
    height: var(--size-76px);
    padding: 0 3.6rem;
    padding: 0 var(--size-36px);
    font-size: 1.6rem;
    font-size: var(--size-16px);
    font-weight: 600;
    line-height: 7.6rem;
    line-height: var(--size-76px);
    text-transform: uppercase;
    color: #f7f7f7;
    color: var(--color-background);
    background: #b4001b;
    background: var(--color-link);
    pointer-events: visible;
    transition: all .3s cubic-bezier(.215,.61,.355,1),transform 0s,-webkit-transform 0s;
}
.intro:hover {
    text-decoration: none;
    color: #fff;
}

@media (max-width: 1024px) {
    .intro {
        display: none;
    }
}

.btnMenu {
    position: relative;
    cursor: pointer;
    pointer-events: visible;
    border: 0;
    outline: 0;
    background: var(--color-link);
    width: var(--size-70px);
    height: var(--size-70px);
    .bar {
        display: block;
        width: var(--size-30px);
        height: 4px;
        background: #fff;
        margin-bottom: 5px;
        margin-left: auto;
        margin-right: auto;
        transform-origin: 50% 50%;
        transition: all 0.3s ease-in-out;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &:focus {
        outline: 0;
    }
    &.active {
        border: 0;
        outline: 0;
        .bar {
            background: #fff;
            &:first-child {
                transform: rotate(-225deg) translateX(5%) translateY(-190%);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:last-child {
                transform: rotate(225deg) translateX(15%) translateY(300%);
            }
        }
    }
    @media (max-width: 767px) {
        width: 50px;
        height: 40px;
        .bar {
            width: 30px;
            height: 3px;
        }
        &.active {
            .bar {
                &:first-child {
                    transform: rotate(-225deg) translateX(10%) translateY(-220%);
                }
                &:last-child {
                    transform: rotate(225deg) translateX(15%) translateY(300%);
                }
            }
        }
    }
    @media (max-width: 1024px) {
        display: block;
    }
}
.mNav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;
    z-index: 9;
    .mNavInner {
        position: absolute;
        top: 0;
        right: 0;
        width: 0%;
        height: 0%;
        background: var(--color-link);
        white-space: nowrap;
        transition: all 0.3s ease-in-out;
        color: #fff;
        padding: 0;
        font-size: var(--size-60px);
        -webkit-backface-visibility: hidden;
        .btnNavItem {
            border: 0;
            outline: 0;
            background: transparent;
            color: #fff;
        }
    }
    &.active {
        pointer-events: auto;
        .mNavInner {
            width: 100%;
            height: 100%;
            padding: calc(var(--margin-top) - var(--size-10px)) var(--size-50px);
        }
    }
    .fr {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
        &.hidden {
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }
        @media (max-width: 767px) {
            padding-top: var(--size-50px);
        }
    }
    .bk {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
        &.hidden {
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }
        @media (max-width: 767px) {
            padding-top: var(--size-50px);
        }
    }
    .navBack {
        margin-right: 20px;
        @media (max-width: 1024px) {
            width: 20px;
        }
    }
    .btnNavItem {
        &.btnNavBack {
            font-size: var(--size-30px);
            @media (max-width: 767px) {
                font-size: var(--size-60px);
            }
        }
        @media (max-width: 767px) {
            font-size: var(--size-70px);
            margin-bottom: var(--size-70px);
        } 
    }
}

/** footer **/
.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: all;
    z-index: 1;
    opacity: 1;
    transition: all .3s cubic-bezier(.215,.61,.355,1),transform 0s,-webkit-transform 0s;
    &.active {
        opacity: 0;
    }
    &.static {
        position: static;
        background: #373B3E;
        .footerContent > div {
            color: #fff !important;
        }
    }
}
.footer .footerContent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    -webkit-box-align: end;
    align-items: end;
    justify-items: center;
    padding: 5.5rem 6rem;
    padding: var(--size-55px) var(--size-60px);
    width: 100%;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.6s,
    -webkit-transform 0s;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.6s,
    -webkit-transform 0s;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.6s, transform 0s;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.6s, transform 0s,
    -webkit-transform 0s;
}
.footer .footerContent > div {
    font-size: var(--size-14px);
    font-weight: 300;
    color: #2a2a2a;
    color: var(--color-text);
}
.footer .footerContent .copy {
    font-size: var(--size-14px);
    font-weight: 300;
    color: #2a2a2a;
    color: var(--color-text);
    justify-self: left;
}
@media (max-width: 1024px) {
    .footer .footerContent {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }
    .footer .footerContent > div {
        font-size: var(--size-30px);
        text-align: center;
    }
}
@media (min-width: 1025px) and (max-width: 1280px) {
    .footer .footerContent {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }
}

.topBar {
    position: fixed;
    top: 0;
    left: 0;
    height: 1rem;
    height: var(--size-10px);
    width: 100%;
    background: #b4001b;
    background: var(--color-link);
    pointer-events: none;
    z-index: 8;
}

// #svgLogo {
//     width: 284px;
//     height: 32px;
//     transition: all 0.5s ease;
// }
// #p_1 {
//     animation: hideshow 5s 1.5s ease infinite;
// }
// @keyframes hideshow {
//     0% {
//         transform: translateY(150px);
//       }
//       34% {
//         transform: translateY(20px);
//       }
//       37% {
//         transform: translateY(150px);
//       }
//       100% {
//         transform: translateY(150px);
//       }
// }

*::-webkit-scrollbar {
    display: none;
}

.btnScrollDown {
    position: absolute;
    left: 50%;
    bottom: 30px;
    display: block;
    width: 64px;
    height: 64px;
    background: #fff;
    border: 0;
    outline: 0;
    text-align: center;
    border-radius: 100%;
    transform: translateX(-50%);
    &:before {
        content: 'SCROLL';
        position: absolute;
        left: 0;
        right: 0;
        top: -30px;
        color: #fff;
        font-size: 10px;
    }
    &:hover, &:focus, &:active {
        outline: 0;
    }
    img {
        animation: scrollArrow 1s 1.5s ease infinite;
    }
}
@keyframes scrollArrow {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0px);
    }
}

.sv {
    min-height: var(--height-100vh);
    border: 1rem solid #b4001b;
    border: solid var(--size-10px) var(--color-link);
    padding: 16.6rem 5rem;
    padding: calc(var(--margin-top) - var(--size-10px)) var(--size-50px);
    -webkit-transition: border-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
      opacity 0.1s ease;
    transition: border-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
      opacity 0.1s ease;
    position: relative;
    height: auto;
    min-height: var(--height-100vh);
    padding: initial !important;
    background: #f7f7f7;
    background: var(--color-background); 

    & > * {
        word-break: keep-all;
    }
}

.btnNoStyle {
    border: 0;
    outline: 0;
    background: transparent;
    color: inherit;
    &:focus, &:active, &:hover {
        outline: 0;
    }
}

.react-parallax .container {
    // position: relative;
    // height: 300px;
}
.wordKeep {
    word-break: keep-all;
}
.parallaxExplain {
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 100px;
    width: 600px;
    &.btm {
        bottom: 100px;
    }
    .pgTitle {
        color: var(--color-link);
        font-size: var(--size-45px);
        margin-bottom: var(--size-40px);
    }
    .pgTitleExp {
        font-size: var(--size-28px);
        margin-bottom: var(--size-40px);
        word-break: keep-all;
    }
    .pgTitleExp22 {
        font-size: var(--size-22px);
        word-break: keep-all;
    }
    .flex-fi2ll, .flex-f3ill {
        font-size: var(--size-20px);
        margin-bottom: var(--size-20px);
        img {
            margin-right: 10px;
        }
    }
    .top {
        padding: var(--size-40px);
    }
    .mid {
        background: #575C60;
        color: #fff;
        text-align: center;
        & > div {
            padding: var(--size-40px);
        }
        span {
            margin-left: 10px;
        }
    }
    .btm {
        background: #373B3E;
        color: #fff;
        padding: var(--size-40px);
        ul li {
            font-size: var(--size-20px);
            word-break: keep-all;
        }
    }
    @media (max-width: 767px) {
        left: 20px;
        right: 20px;
        bottom: 150px;
        top: auto;
        width: auto;
        &.btm {
            bottom: 150px;
        }
        .pgTitle {
            font-size: var(--size-50px);
        }
        .pgTitleExp, .pgTitleExp22 {
            font-size: 16px;
        }
        .flex-fi2ll, .flex-f3ill {
            font-size: var(--size-40px);
        }
        .mid {
            & > div {
                font-size: var(--size-40px);
            }
            span {
                margin-left: 10px;
            }
        }
        .btm {
            padding-left: var(--size-20px);
            ul li {
                font-size: var(--size-36px);
            }
        }
    }
}

.wrapper {
    position: relative;
    width: 1600px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    @media (min-width: 1280px) and (max-width: 1599px) {
        width:  1280px;
    }
    @media (min-width: 1024px) and (max-width: 1279px) {
        width:  1024px;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
        width:  768px;
    }
    @media (min-width: 640px) and (max-width: 767px) {
        width:  640px;
    }
    @media (max-width: 639px) {
        width: 100%;
    }
}

.gridContainer {
    display: grid;
    // justify-content: center;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &.grid3 {
        grid-template-columns: repeat(3, minmax(300px, 1fr));
        grid-template-rows: 100%;
        &.w200 {
            grid-template-columns: repeat(3, minmax(200px, 1fr));
        }
    }
    &.grid4 {
        grid-template-columns: repeat(4, minmax(200px, 1fr));
        grid-template-rows: 100%;
    }
    &.grid4-1 {
        grid-template-columns: repeat(8, minmax(300px, 1fr));
        grid-template-rows: 100%;
    }
    &.grid5 {
        grid-template-columns: repeat(5, minmax(300px, 1fr));
        grid-template-rows: 100%;
        &.w200 {
            grid-template-columns: repeat(5, minmax(200px, 1fr));
        }
    }
    &.grid8 {
        grid-template-columns: 300px 100px 100px 100px 100px 100px 100px 100px;
        grid-template-rows: 100%;
        @media (max-width: 919px) {
            grid-template-columns: 200px 80px 80px 80px 80px 80px 80px 80px;
        }
        &.jc {
            justify-content: center;
            @media (max-width: 919px) {
                justify-content: start;
                padding-left: 30px;
            }
        }
    }
    &.grid-auto {
        grid-template-columns: minmax(200px, max-content);
        repeat(auto-fill, 150px);
    }
    .gridCol {
        &.sticy {
            position :sticky;
            top: 0;
        }
    }
    &.padding {
        .gridCol {
            padding: var(--size-20px);
        }
    }
    @media (max-width: 639px) {
        &.grid4 {
            grid-template-columns: repeat(4, minmax(250px, 1fr));
        }
    }
    @media (max-width: 375px) {
        &.grid5.xsAuto {
            grid-template-columns: repeat(5, minmax(150px, 1fr));
        }
    }
}

.overScrollX {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-left: var(--size-30px);
    padding-right: var(--size-30px);
}

.pre {
    background: #fff;
    word-break: keep-all;
    padding-left: var(--size-40px);
    & > h1 {
        color: var(--color-link);
        font-size: var(--size-45px);
        margin-bottom: var(--size-40px);
    }
    & > p {
        font-size: var(--size-24px);
    }
    @media (max-width: 720px) {
        & > h1 {
        font-size: var(--size-55px);
        }
        & > p {
        font-size: var(--size-40px);
        }
    }
    @media (max-width: 920px) {
    }
}
.item {
    text-align: center;
    & > h1 {
        font-size: var(--size-28px);
        & > img {
        width: var(--size-28px);
        }
        & > span {
        font-size: var(--size-18px);
        display: block;
        padding-left: var(--size-36px);
        margin-top: 0.5rem;
        font-weight: 300;
        }
    }
    ul {
        margin-top: var(--size-55px);
        & > li {
        font-size: var(--size-24px);
        & > span {
            display: block;
            font-size: var(--size-20px);
            font-weight: 300;
            &.inline {
            display: inline;
            margin-left: 10px;
            }
        }
        }
    }
    @media (max-width: 720px) {
        & > h1 {
        font-size: var(--size-40px);
        }
        & > span {
            font-size: var(--size-24px);
        }
        ul {
        & > li {
            font-size: var(--size-40px);
            & > span {
                font-size: var(--size-30px);
            }
        }
        }
    }
    @media (max-width: 920px) {
    }
}
.blue-row {
    position: relative;
    padding: var(--size-100px) 0;
    background: #f2f5f7;
}
.react-datepicker__day--keyboard-selected {
    background: var(--color-link);
    color: #fff !important;
}

@media (max-width: 639px) {
    .rodal-dialog {
        width: 320px !important;
    }
}

.tabPre {
    font-size: var(--size-40px);
    word-break: keep-all;
}
.tabHeader {
    position: relative;
    ul {
        list-style: none;
        padding: 0;
        width: 100%;
    }
    li {
        width: 100%;
        margin-right: 15px;
        &:last-child {
            margin-right: 0;
        }
        button {
            width: 100%;
            display: block;
            border: 4px solid var(--color-link);
            outline: 0;
            background: #fff;
            color: var(--color-link);
            font-size: var(--size-20px);
            padding: var(--size-15px) 0;
            box-shadow: 0;
            transition: all 0.1s ease-in-out;
            &.active {
                background: var(--color-link);
                color: #fff;
            }
            &:hover {
                box-shadow: 0 5px 5px rgba(0,0,0,0.3);
                transform: translateY(-5px);
            }
            @media (max-width: 767px) {
                font-size: var(--size-36px);
                border: 2px solid var(--color-link);
            }
        }
    }
}
.tabContent {
    .tab {
        display: none;
        &.active {
            display: block;
        }
    }
}

@media (max-width: 767px) {
    .xs-to-center {
        text-align: center;
    }
    .xs-mb-100 {
        margin-bottom: var(--size-100px);
    }
}

.tbl {
    width: 100%;
    border-collapse: collapse;
    thead {
        background: #373B3E;
        color: #fff;
    }
    th {
        font-size: var(--size-24px);
    }
    td {
        font-size: var(--size-20px);
    }
    th, td {
        padding: var(--size-20px);
    }
    .bg {
        background: #F2F5F7;
    }
    @media (max-width: 767px) {
        th {
            font-size: var(--size-40px);
        }
        td {
            font-size: var(--size-40px);
        }
    }
}
.list-pre {
    list-style: none;
    padding-left: 0;
    li {
        position: relative;
        padding-left: var(--size-25px);
        margin-bottom: var(--size-10px);
        &::before {
            content: '';
            width: 15px;
            height: 12px;
            display: block;
            position: absolute;
            left: 0;
            top: var(--size-5px);
            background: url('/img/list-pre.svg') no-repeat center;
            @media(max-width: 767px) {
                width: 10px;
                height: 12px;
                background-size: 10px auto;
            }
        }
        @media(max-width: 767px) {
            padding-left: var(--size-50px);
            font-size: var(--size-40px);
        }
    }
}
.paging {
    width: 100%;
    .page-item {
        margin-left: 5px;
        margin-right: 5px;
        a {
            color: var(--color-text);
        }
        &.false {
            a {
                background: #ebebeb;
            }
        }
    }
}
.nose2Arr {
    margin-left: var(--size-10px);
    margin-right: var(--size-10px);
    @media(max-width: 767px) {
        width: var(--size-30px);
    }
}

.CardFront {
  position: relative;
  border: 1px solid #ebebeb;
  &:before, &:after {
      content: '';
      width: 0;
      white-space: normal;
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
  }
  &:before {
    padding-top: 100%;
  }
  h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      text-align: center;
      text-shadow: 1px 1px 1px rgb(0 0 0 / 50%);
      width: 100%;
      padding: var(--size-30px);
  }
}
.CardBack {
    position: relative;
    border: 1px solid #ebebeb;
    background: var(--color-text);
    color: #fff;
    &:before, &:after {
        content: '';
        width: 0;
        white-space: normal;
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
    }
    &:before {
      padding-top: 100%;
    }
    .desc {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: var(--size-30px);
    }
}

.react-datepicker {
    position: relative;
    .react-datepicker__navigation {
        display: inline-block;
        width: 24px;
        height: 24px;
        border: 0;
        outline: 0;
        text-indent: -9999px;
        position: absolute;
        top: 5px;
        &.react-datepicker__navigation--next {
            right: 0;
            background: url('/img/p_next.svg') no-repeat center;
            background-size: 24px auto;
        }
        &.react-datepicker__navigation--previous {
            left: 0;
            background: url('/img/p_prev.svg') no-repeat center;
            background-size: 24px auto;
        }
    }
}
.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.pnlm-controls-container {
    position: absolute;
    top: auto;
    bottom: 30px;
    left: 30px;
    z-index: 1;
}
.react-datepicker__day--today {
    // background: var(--color-link);
}
`;

export default GlobalStyles;
