import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

// import { Parallax } from "react-scroll-parallax";
// import Image from "../../components/Image";

import { Parallax } from "react-parallax";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

import ResvRodal from "../../components/resvRodal";

const Circle = styled.div`
  width: 21rem;
  height: 21rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  border-radius: 100%;
  text-align: center;
  background: #fff;
  color: var(--color-link);
  font-size: var(--size-24px);
  @media (max-width: 767px) {
    width: 28rem;
    height: 28rem;
    font-size: var(--size-36px);
  }
`;

const Vocal = styled.div`
  background: #fff;
  padding: var(--size-40px);
  text-align: center;
`;

const Video = styled.video`
  width: 100%;
`;

function Neck1({ history, props }) {
  const dispatch = useDispatch();
  // 마우스 위치 기반 자동 가로 스크롤
  const horizontalScrollRef = useRef(null);
  const mX = useRef(0);
  const mX2 = useRef(0);
  const posX = useRef(0);
  const mPadd = 60;
  const damp = 20;
  // 요기까지
  const [visibleResv, setVisibleResv] = useState(false);
  const [defaultTreatId, setDefaultTreatId] = useState(0);
  const [defaultTreatNm, setDefaultTreatNm] = useState(0);

  const handleResvDefaultValues = (id, tnm) => {
    if (id > 0 && tnm) {
      setDefaultTreatId(id);
      setDefaultTreatNm(tnm);
      setVisibleResv(true);
      console.log(id);
      console.log(tnm);
    }
  };

  // 예약완료
  const resvComplate = () => {
    setVisibleResv(false);
  };

  const resvClose = () => {
    setVisibleResv(!resvClose);
  };

  // scroll 버튼 처리
  const scrollDown = () => {
    const n = document.documentElement.clientHeight;
    window.scrollBy({ top: n, left: 0, behavior: "smooth" });
  };

  // 자동 스크롤 리스너
  const horizontalScroll = (e) => {
    if (horizontalScrollRef.current) {
      const container = horizontalScrollRef.current;
      const parent = container.parentElement;
      const galW = container.offsetWidth;
      const galSW = container.scrollWidth;
      const wDiff = galSW / galW - 1; // widths difference ratio
      const mmAA = galW - mPadd * 2; // the mousemove available area
      const mmAAr = galW / mmAA;

      if (parent) {
        mX.current =
          e.pageX - parent.getBoundingClientRect().left - container.offsetLeft;

        mX2.current = Math.min(Math.max(0, mX.current - mPadd), mmAA) * mmAAr;
      }

      posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"

      setInterval(function () {
        posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"
        container.scrollLeft = posX.current * wDiff;
      }, 40);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>음성질환 클리닉</title>
      </Helmet>
      {visibleResv ? (
        <ResvRodal
          visibleResv={visibleResv}
          resvClose={resvClose}
          resvComplate={resvComplate}
          defaultTreatId={defaultTreatId}
          defaultTreatNm={defaultTreatNm}
        />
      ) : (
        ""
      )}
      <div className="topBar"></div>
      <div className="sv">
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={"/img/neck/neck1.png"}
          bgImageAlt=""
          strength={-300}
        >
          <div className="container">
            <Slide up>
              <div className="parallaxExplain btm">
                <div className="top">
                  <h1 className="pgTitle">음성 질환 클리닉</h1>
                  <p className="pgTitleExp22">
                    음성이상은 성대 질환 뿐 아니라 후두강, 구강 및 비강 이상으로
                    생기는 경우가 많습니다.
                    <br />
                    DMC하나이비인후과에서는 대학병원에서 가능한 전문 검사 기기와
                    전문 음성치료사의 음성치료를 제공합니다.
                  </p>
                </div>
                <div className="mid">
                  <Row noGutters>
                    <Col>
                      <button
                        type="button"
                        className="btnNoStyle"
                        onClick={(e) =>
                          handleResvDefaultValues(11, "음성 질환 클리닉")
                        }
                      >
                        <img src="/img/ico-chart.svg" alt="" />
                        <span>음성 질환 클리닉 예약하기</span>
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Slide>
          </div>
          <div style={{ height: "100vh" }} />
          <button
            type="button"
            className="btnScrollDown"
            onClick={() => scrollDown()}
          >
            <img src="/img/scrollDown.svg" alt="" />
          </button>
        </Parallax>
        <div
          className="gridContainer grid4 padding mt-130 mb-60"
          ref={horizontalScrollRef}
          onMouseMove={(e) => horizontalScroll(e)}
        >
          <Slide up>
            <div className="gridCol">
              <div className="pre">
                <h1>음성질환이란:</h1>
              </div>
            </div>
            <div className="gridCol">
              <div className="item">
                <h1>
                  <img src="/img/ico-plus.svg" alt="" /> 기능성 음성질환
                </h1>
                <ul className="list-unstyled wordKeep">
                  <li>
                    잘못된 발성으로 생기는 음성변화 음성혹사로 인해
                    근긴장성발성장애, 성대결절, 성대용종, 레인케씨부종,
                    만성후두염, 성대낭종 등 다양하게 발현됩니다.
                  </li>
                </ul>
              </div>
            </div>
            <div className="gridCol">
              <div className="item">
                <h1>
                  <img src="/img/ico-plus.svg" alt="" /> 기질성 음성질환
                </h1>
                <ul className="list-unstyled wordKeep">
                  <li>
                    신경질환 또는 구조적 이상으로 생기는 음성변화. 연축성
                    발성장애, 후두암, 성대마비, 성대구증 등이 있습니다.
                  </li>
                </ul>
              </div>
            </div>
            <div className="gridCol">
              <div className="item">
                <h1>
                  <img src="/img/ico-plus.svg" alt="" /> 목소리 관련질환
                </h1>
                <ul className="list-unstyled wordKeep">
                  <li>
                    축농증, 비후성비염, 알레르기비염 등 각종 코질환,
                    아데노이드비대증, 역류성인후두염 등 성대 이외에도 다양한
                    질환으로 음성변화를 일으키게됩니다.
                  </li>
                </ul>
              </div>
            </div>
          </Slide>
        </div>
        <div className="blue-row">
          <div className="wrapper">
            <Row noGutters className="mb-50">
              <div className="col-md-4">
                <Slide left>
                  <h1 className="bar-text font-24 mb-30">
                    어떤 검사로 진단하나요?
                  </h1>
                </Slide>
              </div>
              <div className="col-md-8">
                <Slide right>
                  <p className="font-24">
                    <strong>
                      일반적으로 후두 내시경,
                      후두미세진동기(후두스트로보스코피), 비강내시경, 음성검사
                      등을 통해 진단 가능합니다.
                    </strong>
                  </p>
                  <p className="font-24 mt-50">
                    <img src="/img/chk.svg" className="mr-20" alt="" />
                    후두내시경을 통한 상기도와 후두의 전반적인 구조를 관찰하고
                    필요한 경우 음성분석을 이용
                  </p>
                  <p className="font-24">
                    <img src="/img/chk.svg" className="mr-20" alt="" />
                    종양 발견 시 필요한 경우 조직검사 및 CT촬영
                  </p>
                  <p className="font-24">
                    <img src="/img/chk.svg" className="mr-20" alt="" />
                    비강질환 및 아데노이드편도질환으로 인한 비음이 의심되는 경우
                    비강내시경과 x-ray시행
                  </p>
                </Slide>
              </div>
            </Row>
            <Row noGutters className="mt-80">
              <div className="col-md-4">
                <Slide left>
                  <h1 className="bar-text font-24 mb-30">
                    어떻게 치료 진단하나요?
                  </h1>
                </Slide>
              </div>
              <div className="col-md-8">
                <Slide right>
                  <p className="font-24">
                    <strong>
                      음성질환의 다양한 원인을 파악하여 그에 맞는 치료를
                      실시합니다.
                    </strong>
                  </p>
                  <p className="font-24">
                    <strong>
                      음성치료, 성대보톡스, 필러주입술, 현미경하후두미세수술 등
                      각각의 질환에 적절한 개별치료를 시행합니다.
                    </strong>
                  </p>
                </Slide>
              </div>
            </Row>
            <Row className="mt-80">
              <Slide up>
                <div className="col-md-4">
                  <Vocal className="mb-40">
                    <img
                      src="/img/neck/neck1-1.png"
                      className="img-fluid mb-30"
                      alt=""
                    />
                    <h2 className="font-24 mb-30">
                      <strong>양측 성대 결절</strong>
                    </h2>
                    <p className="font-20">
                      성대결절의 경우 수술 없이 음성치료만으로 호전되는 경우가
                      많습니다.
                    </p>
                  </Vocal>
                </div>
                <div className="col-md-8">
                  <Video autoplay loop muted controls>
                    <source src="/img/neck/video1.mp4" />
                  </Video>
                </div>
              </Slide>
            </Row>
          </div>
        </div>
        <div className="wrapper">
          <Row className="mt-80 mb-60 text-center">
            <Zoom left cascade>
              <div className="col-md-6">
                <img
                  src="/img/neck/neck1-2.png"
                  className="img-fluid mb-40"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <img
                  src="/img/neck/neck1-3.png"
                  className="img-fluid mb-40"
                  alt=""
                />
                <p className="font-24 mt-50">
                  <strong>성대용종과 레인케씨부종</strong>
                </p>
                <p className="font-24">
                  성대부종과 레인케씨부종의 경우는 음성치료보다는 수술적 치료의
                  적응이 됩니다.
                </p>
              </div>
            </Zoom>
          </Row>
          <Rotate top left cascade>
            <div className="d-flex justify-content-around mt-50 mb-50 text-center color-primary">
              <div className="">
                <img src="/img/neck/neck1-4.png" className="img-fluid" alt="" />
                <p className="font-24 mt-20">[성대백반증(전암성병변)증]</p>
              </div>
              <div className="">
                <img src="/img/neck/neck1-5.png" className="img-fluid" alt="" />
                <p className="font-24 mt-20">[후두암]</p>
              </div>
              <div className="">
                <img src="/img/neck/neck1-6.png" className="img-fluid" alt="" />
                <p className="font-24 mt-20">[하인두암]</p>
              </div>
            </div>
          </Rotate>
        </div>
      </div>
    </>
  );
}

export default Neck1;
