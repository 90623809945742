import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

const Centered = styled.div`
  padding-top: var(--size-200px);
  padding-bottom: var(--size-100px);
  .d-flex {
    overflow-x: auto;
  }
  .flexItem {
    padding: var(--size-10px) var(--size-20px);
    width: 25%;
    @media (max-width: 767px) {
      flex: 0 0 60%;
      scroll-snap-align: start;
    }
  }
  @media (max-width: 767px) {
    .d-flex {
      -webkit-overflow-scrolling: touch;
      scroll-snap-type: x mandatory;
    }
  }
`;

function About5({ history, props }) {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>병원 둘러보기</title>
      </Helmet>
      <div className="topBar"></div>
      <div className="sv">
        <Centered className=" text-center">
          <h1 className="font-45 color-primary mb-60">병원 둘러보기</h1>
        </Centered>
      </div>
    </>
  );
}

export default About5;
