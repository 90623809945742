import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

const Centered = styled.div`
  padding-top: var(--size-200px);
  padding-bottom: var(--size-100px);
  .d-flex {
    overflow-x: auto;
  }
  .flexItem {
    padding: var(--size-10px) var(--size-20px);
    width: 25%;
    @media (max-width: 767px) {
      flex: 0 0 60%;
      scroll-snap-align: start;
    }
  }
  @media (max-width: 767px) {
    .d-flex {
      -webkit-overflow-scrolling: touch;
      scroll-snap-type: x mandatory;
    }
  }
`;

const Tbl = styled.table`
  tbody {
    border-top: 1px solid #8a8c8e;
    border-bottom: 1px solid #8a8c8e;
    tr:nth-child(odd) {
      background: #f7fbfd;
    }
  }
`;

function About6({ history, props }) {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>비급여 & 서류발급</title>
      </Helmet>
      <div className="topBar"></div>
      <div className="sv">
        <Centered className=" text-center">
          <h1 className="font-45 color-primary mb-60">비급여 & 서류발급</h1>
        </Centered>
        <div className="container">
          <p className="text-right font-20">(단위: 원)</p>
          <Tbl className="tbl mb-20">
            <colgroup>
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th className="text-center" rowSpan="7">
                  재증명발급
                </th>
                <td className="text-center" colSpan="2">
                  진단서발급비
                </td>
                <td className="text-right">10,000</td>
              </tr>
              <tr>
                <td className="text-center" rowSpan="2">
                  상해진단서
                </td>
                <td className="text-center">3주 미만</td>
                <td className="text-right">100,000</td>
              </tr>
              <tr>
                <td className="text-center">3주 이상</td>
                <td className="text-right">150,000</td>
              </tr>
              <tr>
                <td className="text-center" colSpan="2">
                  영문진단서
                </td>
                <td className="text-right">20,000</td>
              </tr>
              <tr>
                <td className="text-center" colSpan="2">
                  확인서 발급비
                </td>
                <td className="text-right">2,000</td>
              </tr>
              <tr>
                <td className="text-center" rowSpan="2">
                  진료 차트 복사
                </td>
                <td className="text-center">1 ~ 5매</td>
                <td className="text-right">1,000</td>
              </tr>
              <tr>
                <td className="text-center">6매 이상</td>
                <td className="text-right">100</td>
              </tr>
              <tr>
                <th
                  className="text-center"
                  rowSpan="4"
                  style={{ background: "#f7fbfd" }}
                >
                  수술
                </th>
                <td className="text-center" colSpan="2">
                  상급 병료실
                </td>
                <td className="text-right">200,000</td>
              </tr>
              <tr>
                <td className="text-center" colSpan="2">
                수술지혈제 
                </td>
                <td className="text-right">200,000</td>
              </tr>
              <tr>
                <td className="text-center" colSpan="2">
                리포라제  
                </td>
                <td className="text-right">100,000</td>
              </tr>
              <tr>
                <td className="text-center" colSpan="2">
                나비밴드  
                </td>
                <td className="text-right">10,000</td>
              </tr>
              <tr>
                <th
                  className="text-center"
                  rowSpan="6"
                  style={{ background: "#f7fbfd" }}
                >
                  검사 및 기타
                </th>
                <td className="text-center" colSpan="2">
                  후각검사 (수술 전 검사)
                </td>
                <td className="text-right">200,000</td>
              </tr>
              <tr>
                <td className="text-center" colSpan="2">
                  후각검사
                </td>
                <td className="text-right">50,000 ~ 80,000</td>
              </tr>
              <tr>
                <td className="text-center" colSpan="2">
                  미각검사 (수술 전 검사)
                </td>
                <td className="text-right">30,000 ~ 50,000</td>
              </tr>
              <tr>
                <td className="text-center" colSpan="2">
                  독감검사
                </td>
                <td className="text-right">30,000</td>
              </tr>
              <tr>
                <td className="text-center" colSpan="2">
                  수액
                </td>
                <td className="text-right">30,000 ~ 150,000</td>
              </tr>
              <tr>
                <td className="text-center" colSpan="2">
                알레르기 설하 면역 치료
                </td>
                <td className="text-right">140,000 ~ 190,000</td>
              </tr>
              {/* <tr>
                <th className="text-center" style={{ background: "#f7fbfd" }}>
                  음성 클리닉
                </th>
                <td className="text-center" colSpan="2">
                  상급 병료실
                </td>
                <td className="text-right">150,000</td>
              </tr> */}
              <tr>
                <th className="text-center" rowSpan="3" style={{ background: "#f7fbfd" }}>
                  초음파 클리닉
                </th>
                <td className="text-center" colSpan="2">
                  갑상선 초음파
                </td>
                <td className="text-right">30,000</td>
              </tr>
              <tr>
                <td className="text-center" colSpan="2">
                  경부 초음파
                </td>
                <td className="text-right">50,000</td>
              </tr>
              <tr>
                <td className="text-center" colSpan="2">
                비강-부비동 초음파
                </td>
                <td className="text-right">100,000</td>
              </tr>
              <tr>
                <th className="text-center" style={{ background: "#f7fbfd" }}>장애 진단 클리닉</th>
                <td className="text-center" colSpan="2">
                  청각장애진단
                </td>
                <td className="text-right">200,000</td>
              </tr>
            </tbody>
          </Tbl>
          <p className="p font-24 mb-100 text-right">
            * 상한금액은 진찰료 및 각종 검사료 등 진료비용을포함하지 않습니다.
          </p>
        </div>
      </div>
    </>
  );
}

export default About6;
