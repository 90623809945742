import React from "react";
import Moment from "moment";
import styled from "styled-components";

import { API_URL } from "../../_constants/defaultValue";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

const ThumbnailItem = styled.div`
  position: relative;
  display: block;
  color: var(--color-text);
  &:hover,
  &:focus,
  &:active {
  }
  .thumb {
    position: relative;
    height: 280px;
    overflow: hidden;
    background: #ebebeb;
    img {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      max-width: 100%;
      max-height: 280px;
    }
  }
  .info {
    padding: 0 10px 10px 10px;
    .title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  a {
    color: var(--color-text);
    font-size: var(--size-20px);
    transition: all 0.1s ease-in-out;
    display: block;
    box-shadow: 0 rgba(0, 0, 0, 0);
    &:hover,
    &:focus,
    &:active {
      color: inherit;
      transform: scale(1.05);
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
    }
  }
`;

const EvtStatus = styled.span`
  border-radius: 20px;
  color: #fff;
  display: inline-block;
  padding: 5px 20px;
  &.over {
    background: #ccc;
    color: #000;
  }
  &.prg {
    background: var(--color-link);
  }
  &.rdy {
    background: #e74c3c;
  }
`;

function ThumbnailListView({ board }) {
  const transDate = (e) => {
    Moment.locale("ko");
    const d = Moment(e).format("yyyy-MM-DD");
    return d;
  };

  const evtStatus = (sDt, eDt) => {
    Moment.locale("ko");
    const s = Moment(sDt).format("yyyy-MM-DD");
    const e = Moment(eDt).format("yyyy-MM-DD");
    const n = Moment(new Date()).format("yyyy-MM-DD");
    if (s > n && e > n) {
      return <EvtStatus className="rdy">예정</EvtStatus>;
    }
    if (s <= n && e >= n) {
      return <EvtStatus className="prg">진행</EvtStatus>;
    }
    if (s < n && e < n) {
      return <EvtStatus className="over">종료</EvtStatus>;
    }
  };

  return (
    <ThumbnailItem className="col-md-4" key={board.id}>
      <a href={`/board/event/${board.id}`} title={board.title}>
        <div className="thumb mb-20">
          {board.uploadFiles.map((i) => {
            return (
              <img src={`${API_URL}/front/image/${i.id}`} alt="" key={i.id} />
            );
          })}
        </div>
        <div className="info">
          <p className="font-24 title">
            <strong>{board.title}</strong>
          </p>
          <div className="clearfix">
            <p className="font-20 float-left">
              {transDate(board.startDate)} ~ {transDate(board.endDate)}
            </p>
            <p className="font-20 float-right">
              {evtStatus(board.startDate, board.endDate)}
            </p>
          </div>
        </div>
      </a>
    </ThumbnailItem>
  );
}

export default ThumbnailListView;
