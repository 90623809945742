import React, { useState, useEffect } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link, useParams } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

import { API_URL } from "../../_constants/defaultValue";
import { instance } from "../../_helpers";

import Moment from "moment";

const Centered = styled.div`
  padding-top: var(--size-200px);
  padding-bottom: var(--size-100px);
  .d-flex {
    overflow-x: auto;
  }
  .flexItem {
    padding: var(--size-10px);
  }
  .colBox {
    border: 2px solid var(--color-link);
    padding: var(--size-30px);
    min-height: 350px;
  }
  @media (max-width: 767px) {
    .imgPt {
      max-width: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .d-flex {
      margin-left: -15px;
      margin-right: -15px;
    }
    .flexItem img {
      width: 200px;
    }
  }
`;

const ViewData = styled.div`
  position: relative;
  .viewHeader {
    position: relative;
    border-top: 2px solid var(--color-text);
    padding: var(--size-30px);
    border-bottom: 1px solid var(--color-text);
    .viewDate {
      span {
        margin-right: 20px;
      }
    }
  }
  .viewContent {
    img {
      max-width: 100% !important;
      width: auto !important;
    }
  }
  .btm {
    border-top: 1px solid #373b3e;
    .btnBackToList {
      display: inline-block;
      background: var(--color-link);
      color: #fff;
      width: var(--size-280px);
      padding: var(--size-20px);
      margin-top: var(--size-50px);
    }
  }
`;

function EventView({ history, props }) {
  const dispatch = useDispatch();
  const param = useParams();

  const [isLoaded, setIsLoaded] = useState(false);

  const [page, setPage] = useState(0);
  const [items, setItems] = useState([]);

  const transDate = (e) => {
    Moment.locale("ko");
    const d = Moment(e).format("yyyy-MM-DD");
    return d;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));

    async function getData() {
      instance
        .get(`/api/front/view/${param.id}`)
        .then((res) => res.data)
        .then((data) => {
          console.log(data);
          setItems(data);
        })
        .finally(() => setIsLoaded(true));
    }
    setPage(page);
    getData();
  }, []);

  return (
    <>
      <Helmet>
        <title>이벤트</title>
      </Helmet>
      <div className="topBar"></div>
      <div className="sv">
        <Fade bottom>
          <Centered className=" text-center">
            <h1 className="font-45 color-primary mb-20">이벤트</h1>
          </Centered>
          <div className="container">
            <ViewData className="">
              <div className="row viewHeader mb-50">
                <div className="col-md-9">
                  <h1
                    className="font-30"
                    dangerouslySetInnerHTML={{ __html: items.title }}
                  />
                </div>
                <div className="col-md-3">
                  <div className="viewDate">
                    <span>등록일</span> {transDate(items.createdAt)}
                  </div>
                </div>
              </div>

              <div
                className="viewContent mb-50"
                dangerouslySetInnerHTML={{ __html: items.content }}
              />

              <div className="text-center btm mb-100">
                <a href="/board/event" className="btnBackToList font-24">
                  목록
                </a>
              </div>
            </ViewData>
          </div>
        </Fade>
      </div>
    </>
  );
}

export default EventView;
