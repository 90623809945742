import React from "react";
import ThumbListView from "./ThumbnailListView";
import Pagination from "../../components/Pagination";

function ThumbnailListing({ items, currentPage, totalPage, onChangePage }) {
  return (
    <>
      {items.map((board) => {
        return <ThumbListView key={board.id} board={board} />;
      })}
      <Pagination
        currentPage={currentPage}
        totalPage={totalPage}
        onChangePage={(i) => onChangePage(i)}
      />
    </>
  );
}

export default ThumbnailListing;
