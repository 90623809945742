import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

// import { Parallax } from "react-scroll-parallax";
// import Image from "../../components/Image";

import { Parallax } from "react-parallax";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";

import ResvRodal from "../../components/resvRodal";

const Circle = styled.div`
  width: 21rem;
  height: 21rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  border-radius: 100%;
  text-align: center;
  background: #fff;
  color: var(--color-link);
  font-size: var(--size-24px);
  @media (max-width: 767px) {
    width: 28rem;
    height: 28rem;
    font-size: var(--size-36px);
  }
`;

function Ear2({ history, props }) {
  const dispatch = useDispatch();
  // 마우스 위치 기반 자동 가로 스크롤
  const horizontalScrollRef = useRef(null);
  const mX = useRef(0);
  const mX2 = useRef(0);
  const posX = useRef(0);
  const mPadd = 60;
  const damp = 20;
  // 요기까지
  const [visibleResv, setVisibleResv] = useState(false);
  const [defaultTreatId, setDefaultTreatId] = useState(0);
  const [defaultTreatNm, setDefaultTreatNm] = useState(0);

  // const handleResvDefaultValues = (id, tnm) => {
  //   if (id > 0 && tnm) {
  //     setDefaultTreatId(id);
  //     setDefaultTreatNm(tnm);
  //     setVisibleResv(true);
  //     console.log(id);
  //     console.log(tnm);
  //   }
  // };

  const handleResvDefaultValues = () => {
    setVisibleResv(true);
  };

  // 예약완료
  const resvComplate = () => {
    setVisibleResv(false);
  };

  const resvClose = () => {
    setVisibleResv(!resvClose);
  };

  // scroll 버튼 처리
  const scrollDown = () => {
    const n = document.documentElement.clientHeight;
    window.scrollBy({ top: n, left: 0, behavior: "smooth" });
  };

  // 자동 스크롤 리스너
  const horizontalScroll = (e) => {
    if (horizontalScrollRef.current) {
      const container = horizontalScrollRef.current;
      const parent = container.parentElement;
      const galW = container.offsetWidth;
      const galSW = container.scrollWidth;
      const wDiff = galSW / galW - 1; // widths difference ratio
      const mmAA = galW - mPadd * 2; // the mousemove available area
      const mmAAr = galW / mmAA;

      if (parent) {
        mX.current =
          e.pageX - parent.getBoundingClientRect().left - container.offsetLeft;

        mX2.current = Math.min(Math.max(0, mX.current - mPadd), mmAA) * mmAAr;
      }

      posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"

      setInterval(function () {
        posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"
        container.scrollLeft = posX.current * wDiff;
      }, 40);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>어지럼 클리닉</title>
      </Helmet>
      {visibleResv ? (
        <ResvRodal
          visibleResv={visibleResv}
          resvClose={resvClose}
          resvComplate={resvComplate}
          defaultTreatId={defaultTreatId}
          defaultTreatNm={defaultTreatNm}
        />
      ) : (
        ""
      )}
      <div className="topBar"></div>
      <div className="sv">
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={"/img/ear/ear2.png"}
          bgImageAlt=""
          strength={-300}
        >
          <div className="container">
            <Slide up>
              <div className="parallaxExplain btm">
                <div className="top">
                  <h1 className="pgTitle">어지럼 클리닉</h1>
                  <p className="pgTitleExp22">
                    DMC하나이비인후과에서는 꼭 필요한 검사로 정확한 원인을 찾아
                    어지럼 맞춤치료를 제공합니다.
                  </p>
                  <p className="pgTitleExp22 mt-30">
                    <img src="/img/chk.svg" className="mr-20" alt="" />
                    <strong>전체 어지럼의 80%이상이 귀 원인</strong>
                  </p>
                  <p className="pgTitleExp22 mt-10">
                    <img src="/img/chk.svg" className="mr-20" alt="" />
                    <strong>대학병원 수준의 검사 장비를 이용하여</strong>
                  </p>
                  <p className="pgTitleExp22 mt-10">
                    <img src="/img/chk.svg" className="mr-20" alt="" />
                    <strong>전문인력이 환자 개개인에 맞춤형 진료를 제공</strong>
                  </p>
                </div>
                <div className="mid">
                  <Row noGutters>
                    <Col>
                      <button
                        type="button"
                        className="btnNoStyle"
                        // onClick={(e) =>
                        //   handleResvDefaultValues(8, "어지럼 검사")
                        // }
                        onClick={(e) => handleResvDefaultValues()}
                      >
                        <img src="/img/ico-chart.svg" alt="" />
                        <span>예약하기</span>
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Slide>
          </div>
          <div style={{ height: "100vh" }} />
          <button
            type="button"
            className="btnScrollDown"
            onClick={() => scrollDown()}
          >
            <img src="/img/scrollDown.svg" alt="" />
          </button>
        </Parallax>
        <div className="text-center blue-row">
          <Fade up>
            <h1 className="font-36">귀 질환의 대표적 어지럼</h1>
          </Fade>
          <div
            className="gridContainer grid5 xsAuto mt-50"
            ref={horizontalScrollRef}
            onMouseMove={(e) => horizontalScroll(e)}
          >
            <Circle className="gridCol">이석증 (양성 발작성 어지럼증)</Circle>
            <Circle className="gridCol">전정신경염</Circle>
            <Circle className="gridCol">메니에르병</Circle>
            <Circle className="gridCol">내이염</Circle>
            <Circle className="gridCol">청신경 종양</Circle>
          </div>
        </div>
        <div className="wrapper mt-80">
          <Row noGutters>
            <Slide up>
              <div className="col-md-4">
                <h1 className="bar-text font-24 mb-30">
                  전기 안진 측정기
                  <br />
                  Vestibular and
                  <br /> Oculomotor analysis
                  <br />
                  <br />
                  <span className="font-normal">
                    체위 변경에 따른 안진을 측정하여
                    <br />
                    어지럼의 원인을 측정합니다.
                  </span>
                </h1>
              </div>
              <div className="col-md-8">
                <img
                  src="/img/ear/ear2-1.png"
                  className="img-fluid mb-30"
                  alt=""
                />
              </div>
            </Slide>
          </Row>
          <Row noGutters className="mt-100">
            <Slide up>
              <div className="col-md-4">
                <h1 className="bar-text font-24 mb-30">
                  전기와우도 검사
                  <br />
                  EcochG
                  <br />
                  <br />
                  <span className="font-normal">
                    메니에르병과 내림프 수종
                    <br />
                    (Endolympatic hydrops)
                    <br />
                    문제를 진단합니다.
                  </span>
                </h1>
              </div>
              <div className="col-md-8">
                <img
                  src="/img/ear/ear2-2.png"
                  className="img-fluid mb-30"
                  alt=""
                />
              </div>
            </Slide>
          </Row>
          <Row noGutters className="mt-100">
            <Slide up>
              <div className="col-md-4">
                <h1 className="bar-text font-24 mb-30">
                  전정유발근전위검사기
                  <br />
                  VEMP (Vestibular Evoked
                  <br /> Myogenic Potential)
                  <br />
                  <br />
                  <span className="font-normal">
                    어지럼을 일으키는 병 중,
                    <br />
                    하전정 신경에 발생하는 문제를 진단
                  </span>
                </h1>
              </div>
              <div className="col-md-8">
                <img
                  src="/img/ear/ear2-3.png"
                  className="img-fluid mb-30"
                  alt=""
                />
              </div>
            </Slide>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Ear2;
