import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

// import { Parallax } from "react-scroll-parallax";
// import Image from "../../components/Image";

import { Parallax } from "react-parallax";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";

import ResvRodal from "../../components/resvRodal";

import "./nose1.css";

const TitleBullet = styled.h1`
  position: relative;
  font-size: var(--size-36px);
  margin-bottom: var(--size-50px);
  & > span {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: -2px;
      left: 0;
      width: var(--size-6px);
      height: var(--size-6px);
      display: block;
      border-radius: 100%;
      background: #0076be;
    }
  }
  @media (max-width: 720px) {
    font-size: var(--size-45px);
  }
`;

const Circle = styled.div`
  width: 21rem;
  height: 21rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  text-align: center;
  background: #fff;
  color: var(--color-link);
  font-size: var(--size-24px);
  @media (max-width: 767px) {
    width: 28rem;
    height: 28rem;
    font-size: var(--size-36px);
  }
`;

const CircleBtm = styled.p`
  font-size: var(--size-25px);
  margin-top: var(--size-50px);
  @media (max-width: 720px) {
    font-size: var(--size-40px);
  }
`;

const ListBottomContainer = styled.div`
  padding: var(--size-40px);
  color: var(--color-link);
  border: 3px solid var(--color-link);
  text-align: center;
  margin-left: 400px;
  margin-right: 50px;
  margin-bottom: var(--size-100px);
  @media (max-width: 720px) {
    margin-left: var(--size-20px);
    margin-right: var(--size-20px);
  }
`;

const WhiteBox = styled.div`
  border-radius: 5px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  padding: var(--size-60px);
`;

function Nose1({ history, props }) {
  const dispatch = useDispatch();
  // 마우스 위치 기반 자동 가로 스크롤
  const horizontalScrollRef = useRef(null);
  const mX = useRef(0);
  const mX2 = useRef(0);
  const posX = useRef(0);
  const mPadd = 60;
  const damp = 20;
  const horizontalScrollRef2 = useRef(null);
  const mX12 = useRef(0);
  const mX22 = useRef(0);
  const posX2 = useRef(0);
  const mPadd2 = 60;
  const damp2 = 20;
  // 요기까지
  const [visibleResv, setVisibleResv] = useState(false);
  const [defaultTreatId, setDefaultTreatId] = useState(0);
  const [defaultTreatNm, setDefaultTreatNm] = useState(0);

  // const handleResvDefaultValues = (id, tnm) => {
  //   if (id > 0 && tnm) {
  //     setDefaultTreatId(id);
  //     setDefaultTreatNm(tnm);
  //     setVisibleResv(true);
  //   }
  // };

  const handleResvDefaultValues = () => {
    setVisibleResv(true);
  };

  // 예약완료
  const resvComplate = () => {
    setVisibleResv(false);
  };

  const resvClose = () => {
    setVisibleResv(!resvClose);
  };

  // 자동 스크롤 리스너
  const horizontalScroll = (e) => {
    if (horizontalScrollRef.current) {
      const container = horizontalScrollRef.current;
      const parent = container.parentElement;
      const galW = container.offsetWidth;
      const galSW = container.scrollWidth;
      const wDiff = galSW / galW - 1; // widths difference ratio
      const mmAA = galW - mPadd * 2; // the mousemove available area
      const mmAAr = galW / mmAA;

      if (parent) {
        mX.current =
          e.pageX - parent.getBoundingClientRect().left - container.offsetLeft;

        mX2.current = Math.min(Math.max(0, mX.current - mPadd), mmAA) * mmAAr;
      }

      posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"

      setInterval(function () {
        posX.current += (mX2.current - posX.current) / damp; // zeno's paradox equation "catching delay"
        container.scrollLeft = posX.current * wDiff;
      }, 40);
    }
  };
  const horizontalScroll2 = (e) => {
    if (horizontalScrollRef2.current) {
      const container2 = horizontalScrollRef2.current;
      const parent2 = container2.parentElement;
      const galW2 = container2.offsetWidth;
      const galSW2 = container2.scrollWidth;
      const wDiff2 = galSW2 / galW2 - 1; // widths difference ratio
      const mmAA2 = galW2 - mPadd2 * 2; // the mousemove available area
      const mmAAr2 = galW2 / mmAA2;

      if (parent2) {
        mX12.current =
          e.pageX -
          parent2.getBoundingClientRect().left -
          container2.offsetLeft;

        mX22.current =
          Math.min(Math.max(0, mX12.current - mPadd2), mmAA2) * mmAAr2;
      }

      posX2.current += (mX22.current - posX2.current) / damp2; // zeno's paradox equation "catching delay"

      setInterval(function () {
        posX2.current += (mX22.current - posX2.current) / damp; // zeno's paradox equation "catching delay"
        container2.scrollLeft = posX2.current * wDiff2;
      }, 40);
    }
  };

  // scroll 버튼 처리
  const scrollDown = () => {
    const n = document.documentElement.clientHeight;
    window.scrollBy({ top: n, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>코막힘 수술 클리닉</title>
      </Helmet>
      {visibleResv ? (
        <ResvRodal
          visibleResv={visibleResv}
          resvClose={resvClose}
          resvComplate={resvComplate}
          defaultTreatId={defaultTreatId}
          defaultTreatNm={defaultTreatNm}
        />
      ) : (
        ""
      )}
      <div className="topBar"></div>
      <div className="sv">
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={"/img/nose1.png"}
          bgImageAlt=""
          strength={-300}
        >
          <div className="container">
            <Slide up>
              <div className="parallaxExplain">
                <div className="top">
                  <h1 className="pgTitle">코막힘 수술 클리닉</h1>
                  <p className="pgTitleExp">
                    당일입원, 당일 퇴원 ONE-STEP 수술 스시템
                    <br />
                    건강보험공단에 신고된 입원 병실
                    <br />
                    수술 후 코 거즈 최소화로 일상생활 회복을 최대한 빠르게
                  </p>
                </div>
                <div className="mid">
                  <Row noGutters>
                    <Col>
                      <button
                        type="button"
                        className="btnNoStyle"
                        // onClick={(e) =>
                        //   handleResvDefaultValues(14, "코막힘 수술 클리닉")
                        // }
                        onClick={(e) => handleResvDefaultValues()}
                      >
                        <img src="/img/ico-chart.svg" alt="" />
                        <span>예약하기</span>
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Slide>
          </div>
          <div style={{ height: "100vh" }} />
          <button
            type="button"
            className="btnScrollDown"
            onClick={() => scrollDown()}
          >
            <img src="/img/scrollDown.svg" alt="" />
          </button>
        </Parallax>
        <div className="blue-row text-center">
          <Slide right>
            <TitleBullet>
              <span>코</span>
              <span>막</span>
              <span>힘</span>은 다양한 원인을 가지고 있습니다.
            </TitleBullet>
          </Slide>
          <div className="container">
            <Slide right>
              <Col md={{ offset: 2, size: 8 }}>
                <div className="d-flex justify-content-around">
                  <Circle>비염</Circle>
                  <Circle>
                    비중격
                    <br />
                    만곡증
                  </Circle>
                  <Circle>
                    만성 부비동염
                    <br />
                    (축농증)및 비용종
                    <br />
                    (코 물혹)
                  </Circle>
                </div>
              </Col>
            </Slide>
            <Slide right>
              <CircleBtm>
                DMC하나이비인후과에서는 정확한 원인을 파악하여
                <br />
                맞춤 치료를 제공하며 꼭 필요한 경우만 수술 진행합니다.
              </CircleBtm>
            </Slide>
          </div>
        </div>
        <div
          className="gridContainer grid4 mt-130 mb-60"
          ref={horizontalScrollRef}
          onMouseMove={(e) => horizontalScroll(e)}
        >
          <Slide up>
            <div className="gridCol">
              <div className="pre">
                <h1>비염이란:</h1>
                <p>
                  비염은 비강을 덮고 있는 점막의 염증성 질환을 말합니다. 비염은
                  알레르기 비염 하나만 있는 것이 아니며 여러 종류의 비염이
                  있습니다.
                </p>
              </div>
            </div>
            <div className="gridCol">
              <div className="item">
                <h1>
                  <img src="/img/ico-plus.svg" alt="" /> 알레르기성 비염
                  <span>Allergic rhinitis</span>
                </h1>
                <ul className="list-unstyled">
                  <li>
                    계절성 알레르기비염<span>Seasonal allergic rhinitis</span>
                  </li>
                  <li>
                    통년성 알레르기비염<span>Perennial allergic rhinitis</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="gridCol">
              <div className="item">
                <h1>
                  <img src="/img/ico-plus.svg" alt="" /> 비알레르기성 비염
                  <span>Non allergic rhinitis</span>
                </h1>
                <ul className="list-unstyled">
                  <li>
                    호산구성 비염
                    <span>
                      Non allergic rhinitis with
                      <br />
                      eosinophilia synclrome; NARES
                    </span>
                  </li>
                  <li>
                    호르몬성 비염
                    <span className="inline">Hormonal rhinitis</span>
                  </li>
                  <li>
                    약물성 비염<span className="inline">Drug rhinitis</span>
                  </li>
                  <li>
                    자극성 비염<span className="inline">Irritant rhinitis</span>
                  </li>
                  <li>
                    혈관운동성 비염
                    <span className="inline">Vasomotor rhinitis</span>
                  </li>
                  <li>
                    음식물 비염<span className="inline">Food rhinitis</span>
                  </li>
                  <li>
                    위축성 비염<span className="inline">Atrophic</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="gridCol">
              <div className="item">
                <h1>
                  <img src="/img/ico-plus.svg" alt="" /> 감염성 비염
                  <span>&nbsp;</span>
                </h1>
                <ul className="list-unstyled">
                  <li>
                    급성 비염<span>Acute rhinitis</span>
                  </li>
                  <li>
                    만성 비염<span>Chronic rhinitis</span>
                  </li>
                </ul>
              </div>
            </div>
          </Slide>
        </div>
        <div className="clearfix"></div>
        <Slide right>
          <ListBottomContainer className="font-28">
            다양한 비염이 존재하고 있으며 모든 비염을 수술하는 것이 아니라,
            우선적으로 어떤 비염인지 정확하게 파악하여 수술로 치료가 가능한지
            판단 하는 것이 꼭 필요합니다.
          </ListBottomContainer>
        </Slide>
        <div
          className="gridContainer padding grid4 mb-100"
          ref={horizontalScrollRef2}
          onMouseMove={(e) => horizontalScroll2(e)}
        >
          <Rotate top left>
            <div className="gridCol sticy">
              <div className="pre">
                <h1>
                  또 다른
                  <br />
                  코막힘의 원인:
                </h1>
                <p>
                  각종 비염과 더불어 다음의 질환이 복합적으로 있을 수 있습니다.
                  복합적인 것을 놓치고 한가지만 치료하는 것이 치료 실패의
                  원인입니다. 종합적으로 판단하여 각각의 질환에 대한 맞춤치료가
                  중요합니다.
                </p>
              </div>
            </div>
            <div className="gridCol text-center">
              <img
                src="/img/nose/nose1-1.png"
                alt="비중격 만곡증"
                className="img-fluid"
              />
              <p className="font-24 color-primary">[비중격 만곡증]</p>
            </div>
            <div className="gridCol text-center">
              <img
                src="/img/nose/nose1-2.png"
                alt="비용종"
                className="img-fluid"
              />
              <p className="font-24 color-primary">[비용종]</p>
            </div>
            <div className="gridCol text-center">
              <img
                src="/img/nose/nose1-3.png"
                alt="양측 부비동염(축농증)"
                className="img-fluid"
              />
              <p className="font-24 color-primary">[양측 부비동염(축농증)]</p>
            </div>
          </Rotate>
        </div>
        <div className="blue-row mb-100 nose-proc">
          <Slide left>
            <div className="wrapper">
              <Row noGutters>
                <div className="col-md-4">
                  <h1 className="bar-text font-24">
                    진단과 검사는
                    <br />
                    어떻게 진행되나요?
                  </h1>
                </div>
                <div className="col-md-8">
                  <img
                    src="/img/nose/nose1-4.png"
                    className="img-fluid mr-20 mb-5"
                    alt=""
                  />
                  <img
                    src="/img/nose/nose1-5.png"
                    className="img-fluid mr-20 mb-5"
                    alt=""
                  />
                  <img
                    src="/img/nose/nose1-6.png"
                    className="img-fluid mb-5"
                    alt=""
                  />
                </div>
              </Row>
              <Row noGutters className="mt-80">
                <div className="col-md-4">
                  <h1 className="bar-text font-24">
                    치료 및 수술은
                    <br />
                    어떻게 진행되나요?
                  </h1>
                </div>
                <div className="col-md-8">
                  <h2 className="font-24 mb-40">
                    <strong>
                      DMC하나이비인후과에서는 꼭 필요한 경우에만 수술을
                      진행합니다.
                    </strong>
                  </h2>
                  <p className="font-24">
                    약물 치료 및 생리식염수를 이용한 비강 세척 등 비수술적
                    치료가 가능한 경우도 있으며 수술을 요할 경우
                    비중격만곡증교정술, 양측 내시경하 하비갑개 점막하
                    절제술(고주파이용가능), 내시경하 부비동염수술 (축농증수술)
                    대학병원에서 가능한 모든 코수술 시행할 수 있습니다.
                  </p>
                </div>
              </Row>
            </div>
          </Slide>
        </div>
        <div className="mb-100 nose-after">
          <Fade bottom>
            <div className="d-flex justify-content-around align-items-stretch mb-60">
              <div className="align-self-center">
                <img src="/img/nose/nose1-7.png" className="img-fluid" alt="" />
              </div>
              <div className="align-self-center">
                <img src="/img/nose/right-arr.svg" className="raXs" alt="" />
              </div>
              <div className="align-self-center">
                <WhiteBox>
                  <img
                    src="/img/nose/nose1-7-1.png"
                    className="img-fluid mb-40"
                    alt=""
                  />
                  <p className="font-24 color-primary text-center">
                    내시경한 <strong>하비갑개 점막하 절제술후 2주 째</strong>
                  </p>
                </WhiteBox>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="d-flex justify-content-around align-items-stretch mb-60">
              <div className="align-self-center">
                <img src="/img/nose/nose1-8.png" className="img-fluid" alt="" />
              </div>
              <div className="align-self-center">
                <img src="/img/nose/right-arr.svg" className="raXs" alt="" />
              </div>
              <div className="align-self-center">
                <WhiteBox>
                  <img
                    src="/img/nose/nose1-8-1.png"
                    className="img-fluid mb-40"
                    alt=""
                  />
                  <p className="font-24 color-primary text-center">
                    <strong>비중격만곡증 교정술 후 2주 째</strong>
                  </p>
                </WhiteBox>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="d-flex justify-content-around align-items-stretch mb-60">
              <div className="align-self-center">
                <img src="/img/nose/nose1-9.png" className="img-fluid" alt="" />
              </div>
              <div className="align-self-center">
                <img src="/img/nose/right-arr.svg" className="raXs" alt="" />
              </div>
              <div className="align-self-center">
                <WhiteBox>
                  <img
                    src="/img/nose/nose1-9-1.png"
                    className="img-fluid mb-40"
                    alt=""
                  />
                  <p className="font-24 color-primary text-center">
                    <strong>부비강 수술 및 비용종 제거술 후 2주 째</strong>
                  </p>
                </WhiteBox>
              </div>
            </div>
          </Fade>
        </div>
        <div className="blue-row gridContainer grid8 jc step">
          <Fade left cascade>
            <h1 className="font-45 color-primary">
              <strong>
                ONE STOP &<br />
                ONE DAY SYSTEM
              </strong>
            </h1>
            <div className="gridCol">
              <div className="align-self-top text-center">
                <img src="/img/nose/nose-ico-1.png" className="mb-30" alt="" />
                <p className="font-24">진료 및 상담</p>
              </div>
            </div>
            <div className="gridCol">
              <div className="align-self-top text-center">
                <img
                  src="/img/nose/right-arr-primary.svg"
                  className="raXs mt-30"
                  alt=""
                />
              </div>
            </div>
            <div className="gridCol">
              <div className="align-self-top text-center">
                <img src="/img/nose/nose-ico-2.png" className="mb-30" alt="" />
                <p className="font-24">진단 및 검사</p>
              </div>
            </div>
            <div className="gridCol">
              <div className="align-self-top text-center">
                <img
                  src="/img/nose/right-arr-primary.svg"
                  className="raXs mt-30"
                  alt=""
                />
              </div>
            </div>
            <div className="gridCol">
              <div className="align-self-top text-center">
                <img src="/img/nose/nose-ico-3.png" className="mb-30" alt="" />
                <p className="font-24">맞춤 수술</p>
              </div>
            </div>
            <div className="gridCol">
              <div className="align-self-top text-center">
                <img
                  src="/img/nose/right-arr-primary.svg"
                  className="raXs mt-30"
                  alt=""
                />
              </div>
            </div>
            <div className="gridCol">
              <div className="align-self-top text-center">
                <img src="/img/nose/nose-ico-4.png" className="mb-30" alt="" />
                <p className="font-24">통원 치료</p>
                <p className="font-24">
                  <strong>주 2회, 약 4주 통원 치료</strong>
                </p>
              </div>
            </div>
          </Fade>
        </div>
        {/* <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure">
          <Image src="/img/tica101h20010134.png" />
        </Parallax> */}
      </div>
    </>
  );
}

export default Nose1;
