import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

// import { Parallax } from "react-scroll-parallax";
// import Image from "../../components/Image";

import { Parallax, Background } from "react-parallax";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

import ResvRodal from "../../components/resvRodal";

const Tbl = styled.table`
  td {
    text-align: center;
    background: #fff;
    &:first-of-type {
      background: #f7fbfd;
    }
  }
`;

const SubDiv = styled.div`
  position: relative;
  .subDiv {
    position: relative;
    padding-left: 35px;
  }
`;

function Vaccination({ history, props }) {
  const dispatch = useDispatch();

  // scroll 버튼 처리
  const scrollDown = () => {
    const n = document.documentElement.clientHeight;
    window.scrollBy({ top: n, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>예방접종 & 수액클리닉</title>
      </Helmet>
      <div className="topBar"></div>
      <div className="sv">
        <Parallax blur={{ min: -15, max: 15 }} strength={500}>
          <Background className="custom-bg">
            <div
              style={{
                height: 600,
                width: "100vw",
                backgroundImage: "url('/img/vaccination/vaccination1.png')",
                backgroundSize: "cover",
                backgroundRepeat: "repeat-x",
              }}
            />
          </Background>
          <div style={{ height: "50vh" }}></div>
        </Parallax>
        <div className="container mt-50 mb-50">
          <div className="row">
            <div className="col-md-4">
              <Slide left>
                <h2 className="color-primary font-30 mb-60 xs-to-center">
                  성인 예방 접종
                  <br />
                  <span className="font-lighter">예방 접종 예약 가능</span>
                </h2>
              </Slide>
            </div>
            <div className="col-md-8">
              <SubDiv className="item text-left xs-mb-100">
                <Slide right>
                  <h1 class="font-28">
                    각 접종자 마다 휴게시간을 두고 소독 후 다음 접종자를
                    접종합니다.
                    <br />
                    (가족 시 동시 접종 가능)
                  </h1>
                  <ul className="list-unstyled font-24">
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      성인 독감 (65세 이상 무료 접종 및 유료 접종)
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      폐구균 (65세 이상 무료 접종 및 유료 접종)
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      A형간염
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      B형간염
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      대상포진
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      자궁경부암
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      파상풍
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      백일해
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      풍진
                    </li>
                  </ul>
                </Slide>
              </SubDiv>
            </div>
          </div>

          <div className="row mt-100">
            <div className="col-md-4">
              <Slide left>
                <h2 className="color-primary font-30 mb-60 xs-to-center">
                  소아 예방 접종
                  <br />
                  <span className="font-lighter">예방 접종 예약 가능</span>
                </h2>
              </Slide>
            </div>
            <div className="col-md-8">
              <SubDiv className="item text-left xs-mb-100">
                <Slide right>
                  <h1 class="font-28">
                    각 접종자 마다 휴게시간을 두고 소독 후 다음 접종자를
                    접종합니다.
                    <br />
                    (가족 시 동시 접종 가능)
                  </h1>
                  <ul className="list-unstyled font-24">
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      소아 기본 무료 접종
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      로타 바이러스
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      수두 2차
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      뇌수막염
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      자궁경부암(무료 및 프리미엄 유료 접종)
                    </li>
                  </ul>
                </Slide>
              </SubDiv>
            </div>
          </div>

          <div className="row mt-100">
            <div className="col-md-4">
              <Slide left>
                <h2 className="color-primary font-30 mb-60 xs-to-center">
                  수액 클리닉
                </h2>
              </Slide>
            </div>
            <div className="col-md-4">
              <SubDiv className="item text-left xs-mb-100">
                <Slide right>
                  <img
                    src="/img/vaccination/vaccination-1.jpg"
                    alt=""
                    className="img-fluid mb-20"
                  />
                  <ul
                    className="list-unstyled font-24 mb-20 mt-20"
                    style={{ marginTop: "0" }}
                  >
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      단독 1인실 운영
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      타환자와 접촉 없는 시스템
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />각
                      1인 사용 후 10분간의 소독 및 턴다운서비스(Turndown
                      service)
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      수액 환자 마다 제공되는 타월
                    </li>
                  </ul>
                </Slide>
              </SubDiv>
            </div>
            <div className="col-md-4">
              <SubDiv className="item text-left xs-mb-100">
                <Slide right>
                  <h1 class="font-28 mb-20">치료종류 (황산화치료)</h1>
                  <ul
                    className="list-unstyled font-24 mb-20"
                    style={{ marginTop: "20px" }}
                  >
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      마이어스 칵테일 : 비타민 C 10-20g, 비타민 B5,6,12, M
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      비타민 D 주사(근육주사)
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      아연, 마늘주사, 타나민주사, 히스파겐주사, 이명주사(ATP)
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      셀레늄 미네랄 주사 : 셀레늄, 아연, 구리 망간, 아미노산,
                      크롬
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      글루타치온(백옥주사), 리포익산(신데렐라 주사)
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      어지러움 수액
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      음성치료 수액
                    </li>
                    <li>
                      <img src="/img/chk-xs.svg" className="mr-15" alt="" />
                      이명/혈행 개선 수액
                    </li>
                  </ul>
                  <h1 className="font-28 mb-20 mt-40">
                    이외 담당의사와 상담 및 진료 이후 맞춤 수액을 제공합니다.
                  </h1>
                </Slide>
              </SubDiv>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Vaccination;
